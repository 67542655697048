import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VScrollYTransition } from 'vuetify/lib/components/transitions';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTreeview } from 'vuetify/lib/components/VTreeview';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticStyle:{"min-height":"328px"}},[_c(VCardTitle,[_vm._t("title",function(){return [_vm._v(" 部门属性 ")]})],2),_c(VCardText,{staticClass:"app-card-body ml-3"},[_c(VRow,[_c(VCol,{attrs:{"cols":"12","sm":"5"}},[_c(VTreeview,{attrs:{"active":_vm.active,"items":_vm.menuData,"open":_vm.open,"rounded":"","hoverable":"","activatable":"","color":"primary","open-on-click":"","transition":""},on:{"update:active":function($event){_vm.active=$event},"update:open":function($event){_vm.open=$event}},scopedSlots:_vm._u([{key:"prepend",fn:function(ref){
var item = ref.item;
return [(!item.children)?_c(VIcon,[_vm._v(" mdi-account-group-outline ")]):_vm._e()]}}])})],1),_c(VDivider,{attrs:{"vertical":_vm.$vuetify.breakpoint.name!=='xs'}}),_c(VCol,{staticClass:"ml-4",attrs:{"cols":"12","sm":"6"}},[_c(VScrollYTransition,{attrs:{"mode":"out-in"}},[(!_vm.selected)?_c('div',{staticClass:"title font-weight-light"},[_vm._v(" 请选择一个部门 ")]):_c(VCard,{key:_vm.selected.id,staticClass:"pt-6 mx-auto",attrs:{"flat":"","max-width":"1000"}},[_c(VCardText,{staticClass:"text-center"},[_c(VRow,{staticClass:"ml-2",attrs:{"cols":"12"}},[_c(VCol,{attrs:{"cols":"3"}},[_vm._v(" 部门名： ")]),_c(VCol,{staticClass:"text-left",attrs:{"cols":"9"}},[_vm._v(" "+_vm._s(_vm.selected.name?_vm.selected.name:'空')+" ")])],1),_c(VRow,{staticClass:"mt-2 ml-2",attrs:{"cols":"12"}},[_c(VCol,{attrs:{"cols":"3"}},[_vm._v(" 频道类型： ")]),_c(VCol,{staticClass:"text-left",attrs:{"cols":"9"}},[_vm._v(" "+_vm._s(_vm.selected.channelType?_vm.selected.channelType:'空')+" ")])],1),_c(VRow,{staticClass:"mt-2 ml-2",attrs:{"cols":"12"}},[_c(VCol,{staticClass:"mt-2",attrs:{"cols":"3"}},[_vm._v(" 部门类型： ")]),_c(VCol,{attrs:{"cols":"5"}},[_c(VTextField,{staticClass:"mb-6",attrs:{"outlined":"","dense":"","clearable":"","placeholder":"请输入部门类型","hide-details":"auto"},model:{value:(_vm.selected.type),callback:function ($$v) {_vm.$set(_vm.selected, "type", $$v)},expression:"selected.type"}})],1)],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{staticClass:"text-right",attrs:{"color":"success"},on:{"click":function($event){return _vm.editDepart(_vm.selected)}}},[_vm._v(" 应用 ")])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }