<template>
  <v-navigation-drawer
    :value="isUserDetailsDialog"
    temporary
    touchless
    :right="!$vuetify.rtl"
    :width="$vuetify.breakpoint.smAndUp ? 1000 : '100%'"
    app
    @input="(val) => $emit('update:is-user-details-dialog', val)"
  >
    <v-card class="dialog-mains">
      <div class="dialog-top-title d-flex align-center">
        <span>员工详情</span>
        <v-btn
          icon
          small
          @click="$emit('update:is-user-details-dialog',false)"
        >
          <v-icon size="22">
            mdi-close
          </v-icon>
        </v-btn>
      </div>
      <div class="main-boxs">
        <div class="main-title">基本信息</div>
        <div class="detail-info">
          <div class="detail-info-inline">
            <span class="info-label">员工姓名：</span>{{ currentItem.name}}
          </div>
          <div class="detail-info-inline">
            <span class="info-label">性别：</span>{{ currentItem.sex ==1 ? '女':'男'}}
          </div>
          <div class="detail-info-inline">
            <span class="info-label">手机号：</span>{{ currentItem.mobile}}
          </div>
          <div class="detail-info-inline">
            <span class="info-label">职位：</span>{{ currentItem.title}}
          </div>
          <div class="detail-info-inline">
            <span class="info-label">入职日期：</span>{{ currentItem.joinTime}}
          </div>
          <div class="detail-info-inline">
            <span class="info-label">创建时间：</span>{{ currentItem.createdAt}}
          </div>
          <div class="detail-info-inline">
            <span class="info-label">最近更新时间：</span>{{ currentItem.updatedAt}}
          </div>
        </div>
        <div class="main-title">权限信息 <span class="toConfigure" @click="handerTocofigure">配置</span></div>
        <div class="table-mains">
          <div class="table-box">
            <div class="table-tit">
              <div class="table-first padding-p5">
                <div class="table-block">系统名称</div>
              </div>
              <div class="table-second padding-p5">
                <div class="table-block">角色</div>
              </div>
              <div class="table-three padding-p5">
                <div class="table-block">管理范围</div>
              </div>
              <div class="table-three padding-p5">
                <div class="table-block">最新更新时间</div>
              </div>
            </div>
            <div class="table-des" v-for="(item,index) in scopeList" :key="index">
              <div class="table-first padding-p5">
                <div class="table-block">
                  {{ item.name }}
                </div>
              </div>
              <div class="table-second padding-p5">
                <div class="table-block">
                  {{ handerListRole(item.appRoleList,'name') }}
                </div>
              </div>
              <div class="table-three padding-p5">
                <div class="table-block">
                  {{ handerList(item.appUserDeptVoList,'name') }}
                </div>
              </div>
              <div class="table-three padding-p5">
                <div class="table-block">
                  {{ item.updatedAt }}
                </div>
              </div>
            </div>
            <div v-if="!scopeList || scopeList.length ==0" style="line-height: 40px;text-align: center;">暂无数据</div>
          </div>
        </div>
      </div>

      <v-divider></v-divider>
    </v-card>
  </v-navigation-drawer>
</template>

<script>

import {
  ref, onMounted,
} from '@vue/composition-api/dist/vue-composition-api'
import { getAppRoleDeptList } from '@/api/groupAuth'
import { toastInfo, modalFail } from '@core/utils/prompt'

export default {
  model: {
    prop: 'isUserDetailsDialog',
    event: 'update:is-user-details-dialog',
  },
  props: {
    isUserDetailsDialog: {
      type: Boolean,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const currentItem = ref(props.item)
    const scopeList = ref([])

    const startDetail = async () => {
      try {
        const parms = {
          userId: props.item.userId,
          has: true,
        }
        const { data: { status, message: messageText, data } } = await getAppRoleDeptList(parms)
        if (status === 200) {
          scopeList.value = data.appVoList
        } else {
          toastInfo(messageText)
        }
      } catch (err) {
        modalFail(err.response.data.message)
      }
    }
    const handerTocofigure = () => {
      emit('update:is-add-sidebar-active', false)
      emit('handerShowToCofigure', false)
    }
    const handerListRole = (row, key) => {
      let des = ''
      if (row && row.length > 0) {
        row.forEach(item => {
          // eslint-disable-next-line prefer-template
          if (item.has) {
            // eslint-disable-next-line prefer-template
            des = des + '、' + item[key]
          }
        })
        des = des.slice(1, des.length)
      }
      // eslint-disable-next-line newline-before-return
      return des
    }
    const handerList = (row, key) => {
      let des = ''
      if (row && row.length > 0) {
        row.forEach(item => {
          // eslint-disable-next-line prefer-template
          des = des + '、' + item[key]
        })
        des = des.slice(1, des.length)
      }
      // eslint-disable-next-line newline-before-return
      return des
    }

    onMounted(() => {
      startDetail()
    })

    return {
      currentItem,
      handerTocofigure,
      scopeList,
      handerListRole,
      handerList,
      startDetail,
    }
  },
}
</script>

<style scoped>
.dialog-mains{
  background:rgb(245,246,247) ;
}
.dialog-top-title{
  line-height: 60px;
  background: #fff;
  position: relative;
  padding-left: 30px;
  padding-right: 20px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 600;
  font-size: 20px;
  color: #000000;
  text-align: left;
  font-style: normal;
  justify-content: space-between;
}
.main-title{
  font-weight: 500;
  font-size: 16px;
  color: rgba(0,0,0,0.85);
  line-height: 24px;
  position: relative;
  padding-left: 10px;
  margin-bottom: 10px;
  .toConfigure{
    position: absolute;
    right: 5px;
    top: 4px;
    color: #9155FD;
    cursor: pointer;
  }
}
.main-title::before{
  content: "";
  width: 4px;
  height: 16px;
  position: absolute;
  left: 0px;
  top: 4px;
  background: #9155FD;
}
.table-box{
  .table-tit{
    background: #F7F9FB;
    display: flex;
  }
  .table-des{
    display: flex;
  }
  .table-first{
    flex: 0 0 160px;
    /* border: 1px solid #E7ECF0; */
    border-left:1px solid #E7ECF0;
    border-bottom: 1px solid #E7ECF0;
    display: flex;
    align-items: center;
    /* justify-content: center; */
  }
  .table-second{
    flex: 0 0 300px;
    border-left:1px solid #E7ECF0;
    border-bottom: 1px solid #E7ECF0;
    display: flex;
    align-items: center;
  }
  .table-three{
    flex: 0 0 230px;
    border-left:1px solid #E7ECF0;
    border-bottom: 1px solid #E7ECF0;
    display: flex;
    align-items: center;
  }
  .table-block{
    line-height: 32px;
    padding-left: 20px;
  }
  .checks-box{
    display: flex;
    margin-top: 4px;
    .v-input .v-label {
        top: 4px;
        padding-right: 5px;
        margin-left: -4px;
    }
    .v-input--selection-controls{
      margin-top: 2px;
      padding-top: 2px;
      height: 40px;
    }
  }
  .second-field{
    margin-top: -5px;
    cursor: pointer;
    font-size: 14px;
    color: #9155FD;
  }
}
.table-mains{
  border-top:1px solid #E7ECF0;
  border-right: 1px solid #E7ECF0;
}
.main-boxs{
  padding: 20px;
  margin: 20px;
  background: #fff;
}
.detail-info{
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
  padding-left: 10px;
}
.detail-info-inline{
  width: 260px;
  padding-right: 5px;
  line-height: 26px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #666;
  font-size: 14px;
  .info-label{
    color: #999;
  }
}
.padding-p5{
  padding-top: 5px;
  padding-bottom: 5px;
}
</style>
