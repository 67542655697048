<template>
  <v-dialog
    :value="isLoaderActive"
    hide-overlay
    persistent
    width="300"
  >
    <v-card
      color="primary"
      dark
    >
      <v-card-text class="pt-3">
        同步中
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        ></v-progress-linear>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  model: {
    prop: 'isLoaderActive',
    event: 'update:is-loader-active',
  },
  props: {
    isLoaderActive: {
      type: Boolean,
      required: true,
    },
  },
  setup() {
  },
}
</script>
