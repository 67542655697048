const mapEnum = {
  // 1. 业务状态
  businessStatus: [
    { text: '正在处理', value: 1 },
    { text: '已撤销', value: 2 },
    { text: '驳回', value: 3 },
    { text: '办结', value: 4 },
  ],
}

export default mapEnum
