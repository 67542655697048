<script>
import { computed, useSlots } from '@vue/composition-api'
import moment from 'moment'
import { isString, isBoolean } from '@core/utils'
import OverflowTooltip from './OverflowTooltip.vue'

export default {
  name: 'TablePro',
  components: { OverflowTooltip },
  props: {
    columns: {
      type: Array,
      default: () => [],
      require: true,
    },
    data: {
      type: Array,
      default: () => [],
      require: true,
    },
    itemsPerPage: {
      type: Number,
      default: 9999,
    },
  },
  setup(props) {
    const header = computed(() => {
      const data = { sortable: false }
      if (props.columns.length === 0) return []

      return props.columns.map(item => ({ ...item, ...data }))
    })

    // 获取表格映射数组组装成对象
    const mapEnum = Object.fromEntries(header.value.filter(item => item.enum).map(item => [item.value, item.enum]))

    function filterMap(key, val) {
      const arr = mapEnum[key] || []
      const data = arr.find(item => item.value === val)

      return (data && data.text) || ''
    }

    function format(time, formats = 'YYYY-MM-DD HH:mm:ss') {
      if (!time) return ''

      return moment(time).format(formats)
    }

    // 获取需要时间格式化的数据并组装成对象
    const formatHerderObj = Object.fromEntries(header.value.filter(item => item.format).map(item => [item.value, item.format]))
    function getSlotsMap() {
      const slots = useSlots()
      const slotsKeys = Object.keys(slots)

      // 传入的slots
      const oldSlotsMap = slotsKeys.map(item => ({ name: item, type: 'slot' }))

      // 表格头部数据获取的slots
      const headerSlotsMap = header.value.filter(item => item.enum || item.showOverflowTooltip).map(item => ({ name: item.value, type: 'header' }))

      return [...headerSlotsMap, ...oldSlotsMap]
    }
    const slotsMap = getSlotsMap()

    const tableData = computed(() => props.data.map(item => {
      const keys = Object.keys(formatHerderObj)
      if (keys.length === 0) return item
      keys.forEach(key => {
        if (isBoolean(formatHerderObj[key]) && formatHerderObj[key]) {
          // eslint-disable-next-line no-param-reassign
          item[key] = format(item[key])
        }
        if (isString(formatHerderObj[key])) {
          // eslint-disable-next-line no-param-reassign
          item[key] = format(item[key], formatHerderObj[key])
        }
      })

      return item
    }) || [])

    return {
      header,
      slotsMap,
      filterMap,
      format,
      tableData,
    }
  },
}
</script>

<template>
  <v-data-table
    ref="TablePro"
    :headers="header"
    :items="tableData"
    :items-per-page="itemsPerPage"
    hide-default-footer
    fixed-header
    item-key="id"
    loading-text="加载中，请稍等..."
    no-data-text="暂无数据"
    no-results-text="未找到相关数据"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template
      v-for="value in slotsMap"
      #[`item.${value.name}`]="data"
    >
      <template v-if="data.header.showOverflowTooltip">
        <overflow-tooltip
          v-if="data.item[value.name]"
          :key="value.name"
          :label="data.header.enum ? filterMap(value.name, data.item[value.name]) : data.item[value.name]"
        >
          <template #content>
            <slot
              :name="value.name"
              v-bind="data"
            >
              {{ data.header.enum ? filterMap(value.name, data.item[value.name]) : data.item[value.name] }}
            </slot>
          </template>
        </overflow-tooltip>
      </template>
      <template v-else>
        <slot
          :name="value.name"
          v-bind="data"
        >
          {{ data.header.enum ? filterMap(value.name, data.item[value.name]) : data.item[value.name] }}
        </slot>
      </template>
    </template>
  </v-data-table>
</template>

<style lang='scss' scoped>
</style>
