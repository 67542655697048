<template>
  <div>
    <v-card
      v-show="!isRemoved"
      v-bind="$attrs"
      v-on="$listeners"
    >
      <v-card-title>
        <slot name="title">
          本地文件
        </slot>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text class="app-card-body">
        <slot>
          <template>
            <v-data-table
              :headers="headers"
              :items="queueList"
              :items-per-page="5"
            >
              <!-- action -->
              <template #[`item.action`]="{item}">
                <v-chip
                  small
                  class="v-chip-light-bg info--text"
                  color="info"
                  @click="showDetails(item)"
                >
                  详情
                </v-chip>
              </template>
            </v-data-table>
          </template>
        </slot>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { ref, onUnmounted } from '@vue/composition-api'
import store from '@/store'
import { getIcon } from '@core/utils/icon'

export default {
  components: {
  },
  inheritAttrs: false,
  props: {
  },
  setup() {
    const WORK_STORE_MODULE_NAME = 'file-list'

    // Register module
    // if (!store.hasModule(WORK_STORE_MODULE_NAME)) store.registerModule(WORK_STORE_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(WORK_STORE_MODULE_NAME)) store.unregisterModule(WORK_STORE_MODULE_NAME)
    })
    const headers = [
      { text: 'ID', value: 'id' },
      { text: '名称', value: 'topicId' },
      { text: '消息标签', value: 'tag' },
      { text: '创建时间', value: 'createdAt' },
      { text: '操作', value: 'action', sortable: false },
    ]
    const itemId = ref(0)
    const itemValue = ref({ })

    // 导入通过相关字符串获取数据的函数
    const open = ref([1, 2])
    const isRemoved = ref(false)

    return {
      headers,
      open,
      isRemoved,
      itemId,
      itemValue,

      // Icons
      getIcon,
    }
  },
}
</script>

<style scoped>
.v-card__text{
  padding: 0;
}
</style>
