import axios from '@axios'
import config from '../../../../config'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchLists() {
      return new Promise((resolve, reject) => {
        axios
          .get(`${config.serviceUrl}/dingTalk/dept`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchStaff(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${config.serviceUrl}/dingTalk/users`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchRoles() {
      return new Promise((resolve, reject) => {
        axios
          .get(`${config.serviceUrl}/app/roles/all`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchDetailsById(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${config.serviceUrl}/dingTalk/userDetail?id=${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchRoleById(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${config.serviceUrl}/app/users/role/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateRoleById(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`${config.serviceUrl}/app/users/role/${data.userId}`, data.data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    changeActive(ctx, { userid }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${config.serviceUrl}/app/users/active/${userid}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    changeInactive(ctx, { userid }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${config.serviceUrl}/app/users/inactive/${userid}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    synchronize(ctx, role) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${config.serviceUrl}/dingTalk/sync${role}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    pushUser() {
      return new Promise((resolve, reject) => {
        axios
          .post(`${config.serviceUrl}/dingTalk/sendMessage`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
