import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
import { VTreeview } from 'vuetify/lib/components/VTreeview';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{attrs:{"id":"menu-list"}},[_c('menu-list-add-new',{attrs:{"menu-options":_vm.menuOptions,"flatted-menu":_vm.flattedMenu},on:{"refetch-data":_vm.fetchMenuList},model:{value:(_vm.isAddNewSidebarActive),callback:function ($$v) {_vm.isAddNewSidebarActive=$$v},expression:"isAddNewSidebarActive"}}),_c('menu-list-edit',{attrs:{"edit-item":_vm.editItemObj.editItem,"is-edit-sidebar-active":_vm.isEditSidebarActive,"menu-options":_vm.menuOptions,"flatted-menu":_vm.flattedMenu},on:{"update:isEditSidebarActive":function($event){_vm.isEditSidebarActive=$event},"update:is-edit-sidebar-active":function($event){_vm.isEditSidebarActive=$event},"refetch-data":_vm.fetchMenuList}}),_c(VCardTitle,[_vm._v(" "+_vm._s(_vm.curRouteTitle)+" "),_c(VSpacer),_c(VBtn,{staticClass:"me-3",attrs:{"color":"primary"},on:{"click":function($event){$event.stopPropagation();_vm.isAddNewSidebarActive = !_vm.isAddNewSidebarActive}}},[_c(VIcon,{staticClass:"me-1",attrs:{"size":"18"}},[_vm._v(" mdi-plus ")]),_c('span',[_vm._v("新增菜单")])],1)],1),_c(VDivider),(_vm.menuList.length === 0)?_c('div',{staticClass:"d-flex justify-center"},[_c('div',{staticClass:"my-10"},[_c(VProgressCircular,{attrs:{"indeterminate":"","color":"primary"}}),_vm._v(" 加载中，请稍等... ")],1)]):_vm._e(),_c(VTreeview,{attrs:{"items":_vm.menuList,"search":_vm.search,"open":_vm.open,"hoverable":""},on:{"update:open":function($event){_vm.open=$event}},scopedSlots:_vm._u([{key:"prepend",fn:function(ref){
var item = ref.item;
return [_c(VIcon,[_vm._v(" "+_vm._s(item.icon ? _vm.resolveIconName(item.icon) : 'mdi-circle-small')+" ")])]}},{key:"append",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center justify-center"},[_c('div',{on:{"click":function($event){return _vm.updateStatus(item)}}},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","small":""}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"size":"18"}},[_vm._v(" "+_vm._s(item.isHidden ? 'mdi-eye-off' : 'mdi-eye')+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.isHidden ? '显示' : '隐藏'))])])],1),_c('div',{on:{"click":function($event){return _vm.clickEdit(item)}}},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","small":""}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"size":"18"}},[_vm._v(" mdi-square-edit-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v("编辑")])])],1),_c('div',{on:{"click":function($event){return _vm.deletePrompt(item)}}},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","small":""}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"size":"18"}},[_vm._v(" mdi-close ")])],1)]}}],null,true)},[_c('span',[_vm._v("删除")])])],1)])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }