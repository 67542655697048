import { VBtn } from 'vuetify/lib/components/VBtn';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main-content"},[_c('div',{staticClass:"content-left"},[_c('div',{staticClass:"nav-menu"},_vm._l((_vm.navList),function(item,index){return _c('div',{key:index,staticClass:"navs",class:{'nav-actived':_vm.navActived == item.appId},on:{"click":function($event){return _vm.handerTab(item)}}},[_vm._v(" "+_vm._s(item.name)+" ")])}),0)]),_c('div',{staticClass:"content-right"},[_c('app-view',[_c('div',{staticClass:"top-btn"},[_c(VBtn,{attrs:{"color":"primary"},on:{"click":_vm.handerAdd}},[_vm._v(" 添加角色 ")])],1),_c('table-pro',{attrs:{"columns":_vm.tableColumns,"data":_vm.tableData,"loading":_vm.loading},scopedSlots:_vm._u([{key:"type",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.handerType(item)))])]}},{key:"remark",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c(VTooltip,{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"text--link edit-des",staticStyle:{"width":"200px","height":"39px","line-height":"39px","overflow":"hidden","text-overflow":"ellipsis","white-space":"nowrap"}},'span',attrs,false),on),[_vm._v(_vm._s(item.remark))])]}}],null,true)},[_c('div',[_c('div',[_vm._v(" "+_vm._s(item.remark)+" ")])])])],1)]}},{key:"operation",fn:function(ref){
var item = ref.item;
return [(item.type==0 )?_c('span',{staticClass:"app-link",on:{"click":function($event){return _vm.showDetails(item)}}},[_vm._v("查看")]):_vm._e(),(item.type==1 )?_c('span',{staticClass:"app-link",on:{"click":function($event){return _vm.handerEdit(item)}}},[_vm._v("编辑")]):_vm._e(),(item.type==1 )?_c('span',{staticClass:"app-link",on:{"click":function($event){return _vm.handerDelete(item)}}},[_vm._v("删除")]):_vm._e()]}}])})],1),(_vm.isAddSidebarActive)?_c('add-dialog',{attrs:{"is-add-sidebar-active":_vm.isAddSidebarActive,"curr-item":_vm.currItem,"hander-state":_vm.handerState,"app-id":_vm.navActived},on:{"update:isAddSidebarActive":function($event){_vm.isAddSidebarActive=$event},"update:is-add-sidebar-active":function($event){_vm.isAddSidebarActive=$event},"refetch-data":_vm.getroleLists}}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }