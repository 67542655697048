import axios from '@axios'
import config from '../../../../config'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    addFirst(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${config.serviceUrl}/app/department/save`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editFirst(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${config.serviceUrl}/app/department/update/zero`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addNext(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${config.serviceUrl}/app/user/save-authorize`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateFrame(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${config.serviceUrl}/app/department/update`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editMenu(ctx, { id, data }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`${config.serviceUrl}/thirdUser/${id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteDetails(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`${config.serviceUrl}/app/department/delete/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchRolesLists() {
      return new Promise((resolve, reject) => {
        axios
          .get(`${config.serviceUrl}/app/user/roles`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchRoleById(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${config.serviceUrl}/app/role/page`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchDingLists() {
      return new Promise((resolve, reject) => {
        axios
          .get(`${config.serviceUrl}/app/user/dept`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchFrameworkLists(ctx, level) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${config.serviceUrl}/app/department/level?level=${level}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getFrameDataById(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${config.serviceUrl}/app/department/list`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchDetailsByFrame(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${config.serviceUrl}/app/department/detail`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getFrameById(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${config.serviceUrl}/app/department/user?appUserId=${data.appUserId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getRolesById(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${config.serviceUrl}/app/user/role?appUserId=${data.appUserId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateStaff(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${config.serviceUrl}/app/user/update-authorize`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    syncEmployees() {
      return new Promise((resolve, reject) => {
        axios
          .post(`${config.serviceUrl}/dingTalk/syncUser`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    syncDepts() {
      return new Promise((resolve, reject) => {
        axios
          .post(`${config.serviceUrl}/dingTalk/syncDept`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
