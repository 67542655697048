<template>
  <v-card
    :flat="true"
    class="cardContDing"
  >
    <v-row
      style="padding-bottom: 40px"
    >
      <div class="d-flex" style="cursor:pointer;" @click="change()">
        <v-img
          class="dingImg2 d-inline-flex"
          :src="require('@/assets/images/pages/login/arrow.png')"
        />
        <span class="brand-textDing" style="color: #6577FA">返回密码登录</span>
      </div>
    </v-row>
    <hr class="my-2 hr-solid">
    <div class="mt-12 d-flex justify-center align-center">
      <div id="login_container" />
    </div>
  </v-card>
</template>

<script>
import axios from 'axios'
import store from '@/store'

// import router from '@/router'
import config from '../../../config'

export default {
  components: {
  },
  props: {
    actived: {
      type: Number,
      required: true,
    },
  },
  setup() {
    function change() {
      this.$emit('update:actived', 1)
    }

    return {
      change,
    }
  },
  data() {
    return {
      appid: 'dingyyy5od8co0xpkpjq',
      redirectUrl: '',
      apiUrl:
          '<用户信息接口,把redirectUrl回调获取的CODE给后端用于获取扫码用户信息>',
      dingCodeConfig: {
        id: 'login_container',
        style: 'border:none;background-color:#FFFFFF;',
        width: '260',
        height: '360',
      },
    }
  },
  computed: {
    getRedirectUrl() {
      let redirectUrl = 'https://silverdawn.xiaowutube.com/sso/login'
      if (this.$route.query.redirect) {
        if (this.$route.query.back) {
          redirectUrl += `?redirect=${this.$route.query.redirect}&back=${this.$route.query.back}`
        } else {
          redirectUrl += `?redirect=${this.$route.query.redirect}`
        }

        return encodeURIComponent(redirectUrl)
      }

      return encodeURIComponent(redirectUrl)
    },
    getAuthUrl() {
      return `https://oapi.dingtalk.com/connect/oauth2/sns_authorize?appid=${this.appid}&response_type=code&scope=snsapi_login&state=STATE&redirect_uri=${this.getRedirectUrl}`
    },
    getGoto() {
      return encodeURIComponent(this.getAuthUrl)
    },
    getDingCodeConfig() {
      return { ...this.dingCodeConfig, goto: this.getGoto }
    },
  },
  watch: {
    $route() {
      // to表示要跳转的路由，from表示从哪儿跳的路由   to.path
      this.$router.go(0)
    },
  },
  created() {
    this.initDingJs()

    // 有code校验code
    if (this.$route.query.code) {
      this.login(this.$route.query.code)
    }
  },
  mounted() {
    this.initDingLogin()
    this.addDingListener()
  },
  methods: {
    initDingJs() {
      // eslint-disable-next-line func-names,no-unused-expressions
      !(function (window, document) {
        function d(a) {
          let e
          const c = document.createElement('iframe')
          // eslint-disable-next-line no-shadow
          let d = `https://login.dingtalk.com/login/qrcode.htm?goto=${a.goto}`;
          // eslint-disable-next-line no-unused-expressions,,no-sequences
          (d += a.style ? `&style=${encodeURIComponent(a.style)}` : ''),
          (d += a.href ? `&href=${a.href}` : ''),
          (c.src = d),
          (c.frameBorder = '0'),
          (c.allowTransparency = 'true'),
          (c.scrolling = 'no'),
          (c.width = a.width ? `${a.width}px` : '400px'),
          (c.height = a.height ? `${a.height}px` : '400px'),
          (e = document.getElementById(a.id)),
          (e.innerHTML = ''),
          e.appendChild(c)
        }
        // eslint-disable-next-line no-param-reassign
        window.DDLogin = d
      }(window, document))
    },
    addDingListener() {
      const self = this

      const handleLoginTmpCode = loginTmpCode => {
        window.location.href = `${self.getAuthUrl}&loginTmpCode=${loginTmpCode}`
      }

      const handleMessage = event => {
        if (event.origin === 'https://login.dingtalk.com') {
          handleLoginTmpCode(event.data)
        }
      }

      if (typeof window.addEventListener !== 'undefined') {
        window.addEventListener('message', handleMessage, false)
      } else if (typeof window.attachEvent !== 'undefined') {
        window.attachEvent('onmessage', handleMessage)
      }
    },
    initDingLogin() {
      window.DDLogin(this.getDingCodeConfig)
    },
    login(code) {
      axios.post(`${config.serviceUrl}/sso/dingTalkLogin?code=${code}&redirect=${this.$route.query.redirect}`)
        .then(response => {
          if (response.data.code === 200) {
            localStorage.setItem('ssoToken', response.data.data.token)

            // this.$emit('update:actived', 2)
            // window.location.href = response.data.url
            // const newQuery = JSON.parse(JSON.stringify(this.$route.query)) // 深拷贝
            // delete newQuery.code
            // this.$router.replace({ query: newQuery })
            //
            // router.push({ name: 'sso-login', query: newQuery || null })
            window.location.href = response.data.data.url
          } else {
            store.commit('sso-login/getMsg', response.data.msg)
            this.$emit('update:actived', 2)
          }
        })
        .catch(error => {
          store.commit('sso-login/getMsg', error.data.data)
          this.$emit('update:actived', 2)
        })
    },
  },
}
</script>

<style scoped>
.cardContDing{
  margin-left: 20px;
  margin-top: 20px;
  font-family: Microsoft YaHei,sans-serif;
}
.brand-textDing{
  margin-left: 14px;
  margin-top: 2px;
  width: 200px;
  height: 20px;
  font-size: 20px;
  color: white;
  font-family: Microsoft YaHei,sans-serif;
  font-weight: 400;
}
.dingImg2{
  margin-left: 15px;
  margin-top: 6px;
  width: 23px;
  height: 21px;
}
.hr-solid {
  border: 0;
  border-top: 1px solid #d0d0d5;
}
</style>
