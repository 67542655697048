<template>
  <div>
    <Modal
      :id="itemId"
      :item="itemValue"
      :is-details-modal-active.sync="isDetailsModalActive"
    ></Modal>
    <v-card
      v-show="!isRemoved"
      v-bind="$attrs"
      v-on="$listeners"
    >
      <v-card-title>
        <slot name="title">
          短信列表
        </slot>
        <v-spacer></v-spacer>

        <date-picker
          v-model="date"
          popper-class="datePopper"
          value-class="dateValue"
          type="date"
          editable
          clearable
          class="invoice-list-search me-3"
        />

        <v-text-field
          v-model="searchQuery"
          single-line
          dense
          outlined
          hide-details
          placeholder="搜索手机号"
          class="invoice-list-search me-3 ml-2"
          style="width: 1px"
        ></v-text-field>

        <v-select
          v-model="statusFilter"
          :items="statusOptions"
          item-text="label"
          item-value="value"
          single-line
          outlined
          dense
          hide-details
          clearable
          placeholder="选择状态"
          class="invoice-list-status me-3"
          style="width: 1px"
        ></v-select>
      </v-card-title>
      <v-card-text class="app-card-body">
        <slot>
          <template>
            <v-data-table
              :headers="tableColumns"
              :items="queueList"
              :options.sync="options"
              :server-items-length="totalLists"
              :items-per-page="10"
              :loading="loading"
              no-data-text="暂无数据"
              no-results-text="未找到相关数据"
              loading-text="加载中，请稍等..."
            >
              <!-- content -->
              <template #[`item.content`]="{item}">
                <div class="content">
                  {{ item.content }}
                </div>
              </template>
              <!-- status -->
              <template #[`item.status`]="{item}">
                <div style="min-width: 45px">
                  <v-chip
                    small
                    class="v-chip-light-bg"
                    :color="resolveStatusVariant[item.status]"
                    :class="`${resolveStatusVariant[item.status]}--text`"
                  >
                    {{ resolveStatusText(item.status ) }}
                  </v-chip>
                </div>
              </template>
              <!-- action -->
              <template #[`item.action`]="{item}">
                <v-chip
                  small
                  class="v-chip-light-bg info--text"
                  color="info"
                  @click="showDetails(item)"
                >
                  详情
                </v-chip>
                <!--                <v-chip-->
                <!--                  small-->
                <!--                  class="v-chip-light-bg warning&#45;&#45;text"-->
                <!--                  color="warning"-->
                <!--                >-->
                <!--                  发送-->
                <!--                </v-chip>-->
              </template>
            </v-data-table>
          </template>
        </slot>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { ref, onUnmounted } from '@vue/composition-api'
import store from '@/store'
import { getIcon } from '@core/utils/icon'
import DatePicker from '@wangankeji/vue-datepicker'
import storeModule from './queueStoreModule'
import smsQueueList from './smsQueueList'
import Modal from './Detail.vue'
import '@wangankeji/vue-datepicker/dist/datepicker.css'

export default {
  components: {
    Modal,
    DatePicker,
  },
  inheritAttrs: false,
  props: {
  },
  setup() {
    const WORK_STORE_MODULE_NAME = 'sms-list'

    // Register module
    if (!store.hasModule(WORK_STORE_MODULE_NAME)) store.registerModule(WORK_STORE_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(WORK_STORE_MODULE_NAME)) store.unregisterModule(WORK_STORE_MODULE_NAME)
    })
    const isDetailsModalActive = ref(false)
    const itemId = ref(0)
    const itemValue = ref({ })

    const {
      queueList,
      tableColumns,
      fetchQueueList,
      searchQuery,
      options,
      loading,
      date,
      statusFilter,
      totalLists,
      resolveStatusText,
      resolveStatusVariant,
    } = smsQueueList()

    const showDetails = item => {
      itemId.value = item.id
      itemValue.value = item
      isDetailsModalActive.value = !isDetailsModalActive.value
    }

    const statusOptions = ref([
      { label: '待发送', value: 0 },
      { label: '发送成功', value: 1 },
      { label: '发送失败', value: 2 },
    ])

    // 导入通过相关字符串获取数据的函数
    const open = ref([1, 2])
    const isRemoved = ref(false)

    return {
      queueList,
      totalLists,
      fetchQueueList,
      tableColumns,
      open,
      isRemoved,
      showDetails,
      itemId,
      itemValue,
      isDetailsModalActive,
      options,
      loading,
      searchQuery,
      date,
      statusOptions,
      statusFilter,
      resolveStatusText,
      resolveStatusVariant,

      // Icons
      getIcon,
    }
  },
}
</script>

<style>
.datePopper{
  color: #ada8c3;
  background-color: #28243d;
}
.dateValue{
  color: #ada8c3;
  height: 40px;
  margin-top: -4px;
  font-size: 13px;
  margin-left: -23px;
  width: 150px;
}
.date-picker--container{
  background-color: #302b48;
  border: 1px solid #4b4764;
}
.date-picker{
  outline: transparent!important;
}
.date-picker input{
  color: #ada8c3;
  background-color: #302b48;
}
</style>
<style scoped>
.v-card__text{
  padding: 0;
}
.content{
  width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
