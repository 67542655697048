<template>
  <div>
    <AddNew
      :is-add-new-sidebar-active.sync="isAddNewSidebarActive"
      @refetch-data="fetchItems"
    ></AddNew>

    <edit
      :id="itemId"
      :is-edit-sidebar-active.sync="isEditSidebarActive"
      @refetch-data="fetchItems"
    ></edit>

    <auth-edit
      :id="itemId"
      :is-dialog-open.sync="isDialogOpen"
    ></auth-edit>

    <v-card>
      <v-card-title>
        {{ curRouteTitle }}
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          class="mx-4"
          @click="isAddNewSidebarActive = !isAddNewSidebarActive"
        >
          新增角色
        </v-btn>

        <v-btn
          color="primary"
          @click="updateAuthCache"
        >
          更新缓存
        </v-btn>
      </v-card-title>
      <!-- table -->
      <v-data-table
        :headers="tableColumns"
        :items="listTable"
        :options.sync="options"
        :server-items-length="listTableCount"
        :loading="loading"
        no-data-text="暂无数据"
        no-results-text="未找到相关数据"
        loading-text="加载中，请稍等..."
      >
        <!-- 模板id列slot -->
        <template #[`item.id`]="{item}">
          {{ item.id }}
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{item}">
          <v-btn
            text
            color="error"
            @click="clickAuthEditOpen(item.id)"
          >
            权限
          </v-btn>

          <v-menu
            bottom
            right
          >
            <template #activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                @click="clickEditOpen(item.id)"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiPencilOutline }}
                  </v-icon>
                  <span>编辑</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item @click="deleteItemPrompt(item)">
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                  <span>删除</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import {
  mdiSquareEditOutline,
  mdiDotsVertical,
  mdiPlus,
  mdiFileDocumentOutline,
  mdiDeleteOutline,
  mdiExportVariant,
  mdiAccountOutline, mdiPencilOutline,
} from '@mdi/js'
import store from '@/store'
import { onUnmounted, ref } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import router from '@/router'

import useList from './useList'
import roleStoreModule from './roleStoreModule'
import Edit from './Edit.vue'
import AuthEdit from './AuthEdit.vue'
import AddNew from './AddNew.vue'

export default {
  name: 'RoleList',
  components: {
    Edit,
    AuthEdit,
    AddNew,
  },
  setup() {
    const STORE_MODULE_NAME = 'role-list'

    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, roleStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const curRouteTitle = router.currentRoute.meta.pageTitle

    const {
      listTable,
      tableColumns,
      listTableCount,
      loading,
      options,

      fetchItems,
      updateAuthCache,
      deleteItemPrompt,
      resolveUserTotalIcon,
    } = useList()

    // 编辑节点弹出flag
    const isEditSidebarActive = ref(false)

    // 新增弹出flag
    const isAddNewSidebarActive = ref(false)

    // 编辑权限弹出flag
    const isDialogOpen = ref(false)

    // ID
    const itemId = ref(0)

    // 点击编辑按钮事件
    const clickEditOpen = id => {
      itemId.value = id
      isEditSidebarActive.value = !isEditSidebarActive.value
    }

    // 点击权限按钮事件
    const clickAuthEditOpen = id => {
      console.log(id)
      itemId.value = id
      isDialogOpen.value = !isDialogOpen.value
    }

    const typeOptions = [
      { title: '制作中心', value: '制作中心' },
      { title: '影视切条', value: '影视切条' },
      { title: 'FB', value: 'FB' },
    ]

    return {
      curRouteTitle,
      listTable,
      tableColumns,
      listTableCount,
      loading,
      options,
      typeOptions,
      itemId,
      isEditSidebarActive,
      isAddNewSidebarActive,
      isDialogOpen,
      avatarText,
      resolveUserTotalIcon,
      fetchItems,
      updateAuthCache,
      deleteItemPrompt,
      clickEditOpen,
      clickAuthEditOpen,

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiFileDocumentOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPlus,
        mdiExportVariant,
        mdiAccountOutline,
        mdiPencilOutline,
      },
    }
  },
}
</script>

<style scoped>

</style>
