<script>
import TablePro from '@/components/TablePro.vue'
import { ref, onMounted } from '@vue/composition-api'
import mapEnum from '@/views/workFlow/mapEnum'
import moment from 'moment/moment'
import { removeObjectEmpty, useRouter } from '@core/utils'
import { toastFail } from '@core/utils/prompt'
import StatusIcon from '@/views/workFlow/component/StatusIcon.vue'
import { getApplyList } from '../service'

export default {
  name: 'AllApprovals',
  components: { StatusIcon, TablePro },
  props: {
    formData: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, { emit }) {
    const tableData = ref([])
    const { router } = useRouter()
    const loading = ref(true) // 加载中
    const tableColumns = ref([
      { text: '审批名称', value: 'processDefinitionName' },
      { text: '发起时间', value: 'startTime' },
      { text: '当前审批人', value: 'currentName' },
      { text: '当前状态', value: 'businessStatus', enum: mapEnum.businessStatus },
      { text: '操作', value: 'operation' },
    ])

    function init() {
      const [startDate, endDate] = props.formData?.time || []
      const data = {
        ...props.formData,
        startDate: startDate ? `${startDate} 00:00:00` : '',
        endDate: endDate ? `${endDate} 23:59:59` : '',
        total: undefined,
      }
      loading.value = true
      getApplyList(removeObjectEmpty(data)).then(({ result, status }) => {
        if (status === 200) {
          loading.value = false
          tableData.value = (result.records || []).map(item => ({
            ...item,
            currentName: item?.users.name,
            businessStatus: Number(item.businessStatus),
            startTime: moment(item.startTime).format('YYYY-MM-DD HH:mm:ss'), // 时间戳转化
          }))

          // 更新总条数
          emit('totalChange', result.total)
        }
      }).catch(error => {
        loading.value = false
        toastFail(error.response.data.message)
      })
    }

    function jumpDetails(val) {
      sessionStorage.setItem('activeTab', 'all')
      router.push({
        path: '/WorkFlowDetails',
        query: {
          id: val.processInstanceId,
          taskId: val.taskId,
        },
      })
    }

    onMounted(() => {
      init()
    })

    return {
      tableData,
      tableColumns,
      init,
      jumpDetails,
    }
  },
}
</script>

<template>
  <table-pro
    :columns="tableColumns"
    :data="tableData"
  >
    <template #operation="{ item }">
      <span class="app-link" @click="jumpDetails(item)">详情</span>
      <span class="app-link">催办</span>
      <span class="app-link">撤回</span>
    </template>
  </table-pro>
</template>

<style lang='scss' scoped>

</style>
