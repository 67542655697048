<!-- eslint-disable array-callback-return -->
<!-- eslint-disable newline-before-return -->
<script>
import AppView from '@/components/AppView.vue'
import { onMounted, ref, reactive } from '@vue/composition-api'
import { modalEnquireTitle, toastInfo, modalFail, toastSuccess } from '@core/utils/prompt'
import TablePro from '@/components/TablePro.vue'
import { newGetAppList, getAppRoleList, deleteAppRole, existsAppRoleUser } from '@/api/groupAuth'
import addDialog from './addNew.vue'

export default {
  name: 'List',
  components: { TablePro, AppView, addDialog },
  setup() {
    const tableColumns = ref([
      { text: '角色名称', value: 'name' },
      { text: '角色描述', value: 'remark' },
      { text: '角色类型', value: 'type' },
      { text: '操作', value: 'operation', width: 200 },
    ])
    const tableData = ref([])
    const formData = reactive({
      processStyle: '',
      processName: '',
      formId: '', // 父节点id
    })
    const pageOptions = reactive({
      page: 1,
      size: 100,
      total: 0,
    })
    const loading = ref(false)
    const navList = ref([])
    const currItem = ref({})
    const handerState = ref(0) // 0 新增，1 编辑， 2 查看

    const appList = ref([])
    const formList = ref([])

    function handleSizeChange(val) {
      pageOptions.page = 1
      formData.size = val // 请求
    }
    function handleCurrentChange(val) {
      pageOptions.page = val // 请求
    }
    function appChange(val) {
      if (val !== formData.formId) {
        formData.processName = ''
      }
      const da = appList.value.find(item => item.id === val)
      if (da) formData.processStyle = da.name
      else formData.processStyle = ''
    }

    const isAddSidebarActive = ref(false)
    const navActived = ref() // navList.value[0].id

    const getroleLists = async () => {
      try {
        loading.value = true
        const parms = {
          appId: navActived.value,
        }
        const { data: { status, message: messageText, data } } = await getAppRoleList(parms)
        loading.value = false
        if (status === 200) {
          tableData.value = data
          pageOptions.total = data.length
        } else {
          toastInfo(messageText)
        }
      } catch (err) {
        modalFail(err.response.data.message)
      }
    }

    const startGetAppMenuList = async () => {
      try {
        loading.value = true
        const { data: { status, message: messageText, data } } = await newGetAppList({})
        if (status === 200) {
          loading.value = false
          navList.value = data
          if (data.length > 0) {
            navActived.value = data[0].appId
            getroleLists()
          }
        } else {
          toastInfo(messageText)
        }
      } catch (err) {
        modalFail(err.response.data.message)
      }
    }

    const handerAdd = () => {
      if (navActived.value) {
        isAddSidebarActive.value = true
        handerState.value = 0
        currItem.value = {}
      }
    }
    const handerEdit = item => {
      currItem.value = item
      handerState.value = 1
      isAddSidebarActive.value = true
    }
    const detelteRole = async item => {
      try {
        const { data: { status, message: messageText } } = await deleteAppRole({ roleId: item.id })
        if (status === 200) {
          toastSuccess('删除成功！')
          getroleLists()
        } else {
          toastInfo(messageText)
        }
      } catch (err) {
        modalFail(err.response.data.message)
      }
    }

    const handerDelete = async item => {
      currItem.value = item
      try {
        const { data: { status, message: messageText, data } } = await existsAppRoleUser({ roleId: item.id })
        if (status === 200) {
          if (data.length === 0) {
            modalEnquireTitle('当前没有员工配置该角色，可以删除，是否确认删除？').then(isConfirm => {
              if (isConfirm.value) {
                detelteRole(item)
              }
            })
          }
          if (data.length > 0) {
            let desName = ''
            desName = data.map((item, index) => {
              if (index < 5) {
                return item.name
              }
            }).join(',')
            toastInfo(`${desName} 等 ${data.length}名员工已配置该角色，无法删除`)
          }
        } else {
          toastInfo(messageText)
        }
      } catch (err) {
        modalFail(err.response.data.message)
      }
    }

    const showDetails = item => {
      currItem.value = item
      handerState.value = 2
      isAddSidebarActive.value = true
    }

    const handerTab = row => {
      navActived.value = row.appId
      getroleLists()
    }

    const handerType = row => {
      const typeList = {
        0: '系统预设角色',
        1: '自定义角色',
      }
      return typeList[row.type]
    }

    onMounted(() => {
      startGetAppMenuList()
    })

    return {
      pageOptions,
      formData,
      tableColumns,
      tableData,
      loading,
      appList,
      formList,
      handleSizeChange,
      handleCurrentChange,
      appChange,

      currItem,
      handerState,
      navList,
      navActived,
      isAddSidebarActive,
      handerAdd,
      handerEdit,
      handerDelete,
      showDetails,
      handerTab,
      startGetAppMenuList,
      getroleLists,
      handerType,
      detelteRole,
    }
  },
}
</script>

<template>
  <div class="main-content">
    <div class="content-left">
      <div class="nav-menu">
        <div class="navs" v-for="(item,index) in navList" :key="index" :class="{'nav-actived':navActived == item.appId}" @click="handerTab(item)">
          {{ item.name }}
        </div>
      </div>
    </div>
    <div class="content-right">
      <app-view>
        <div class="top-btn">
          <v-btn color="primary" @click="handerAdd">
            添加角色
          </v-btn>
        </div>
        <table-pro
          :columns="tableColumns"
          :data="tableData"
          :loading="loading"
        >
          <template #type="{ item }">
            <span>{{ handerType(item) }}</span>
          </template>
          <template #remark="{item}">
            <div class="d-flex align-center">
              <v-tooltip
                right
              >
                <template
                  #activator="{ on, attrs }"
                >
                  <span
                    v-bind="attrs"
                    class="text--link edit-des"
                    v-on="on"
                    style="width: 200px;height: 39px;line-height: 39px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;"
                  >{{ item.remark }}</span>
                </template>
                <div>
                  <div >
                    {{ item.remark }}
                  </div>
                </div>
              </v-tooltip>
            </div>
          </template>

          <template #operation="{ item }">
            <span v-if="item.type==0 " class="app-link" @click="showDetails(item)">查看</span>
            <span v-if="item.type==1 " class="app-link" @click="handerEdit(item)">编辑</span>
            <span v-if="item.type==1 " class="app-link" @click="handerDelete(item)">删除</span>
          </template>
        </table-pro>
      </app-view>
      <add-dialog
        v-if="isAddSidebarActive"
        :is-add-sidebar-active.sync="isAddSidebarActive"
        :curr-item="currItem"
        :hander-state="handerState"
        :app-id="navActived"
        @refetch-data="getroleLists"
      ></add-dialog>
    </div>
  </div>
</template>

<style lang='scss' scoped>
.main-content{
  display: flex;
}
.content-left{
  width: 150px;
  background: #F6F7F8;
  padding: 5px;
  margin-right: 20px;
}
.content-right{
  flex: 1;
}
.top-btn{
  margin-bottom: 10px;
}
.nav-menu{
  padding: 8px;
  .navs{
    height: 40px;
    line-height: 40px;
    border-radius: 4px;
    cursor: pointer;
    padding-left: 12px;
    font-weight: 400;
    font-size: 14px;
    color: rgba(0,0,0,0.6);
    position: relative;
  }
  .navs:hover{
    background: #F4EDFF;
    // border: 1px solid rgba(145,85,253,0.9);
  }
  .nav-actived{
    background: rgba(41,57,168,0.08);
    color: rgba(0,0,0,0.8);
  }
  .nav-actived::after{
    content: "";
    position: absolute;
    right: 0px;
    top: 0;
    height: 40px;
    width: 3px;
    background-color: #9155fd;
  }
}

</style>
<style lang='scss'>
.app-content-container {
    background: #fff;
    margin-top: 10px;
}
</style>
