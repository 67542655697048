import store from '@/store'
import { ref, watch } from '@vue/composition-api'
import { toastFail } from '@core/utils/prompt'

export default function messageQueueList() {
  const tableColumns = [
    { text: 'ID', value: 'id', sortable: false },
    { text: '名称', value: 'topicId', sortable: false },
    { text: '消息标签', value: 'tag', sortable: false },
    { text: '创建时间', value: 'createdAt', sortable: true },
    { text: '操作', value: 'action', sortable: false },
  ]
  const queueList = ref([])
  const totalLists = ref(0)
  const options = ref({
    sortBy: ['createdAt'],
    sortDesc: [true],
  })
  const searchQuery = ref('')
  const loading = ref(false)

  const fetchQueueList = () => {
    store.dispatch('message-list/fetchLists', {
      tag: searchQuery.value,
      pageSize: options.value.itemsPerPage,
      page: options.value.page,
      sortBy: options.value.sortBy[0],
      sortDesc: options.value.sortDesc[0],
    })
      .then(response => {
        const { records, total } = response.data
        totalLists.value = total
        queueList.value = records
        loading.value = false
      })
      .catch(error => {
        toastFail(error)
      })
  }

  watch(searchQuery, () => {
    options.value.page = 1
    loading.value = true
    fetchQueueList()
  })

  watch(options, () => {
    loading.value = true
    fetchQueueList()
  })

  return {
    fetchQueueList,
    totalLists,
    queueList,
    searchQuery,
    tableColumns,
    options,
    loading,
  }
}
