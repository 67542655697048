<template>
  <div>
    <v-card
      v-bind="$attrs"
      v-on="$listeners"
    >
      <v-card-title>
        <slot name="title">
          角色管理
        </slot>
        <v-spacer></v-spacer>
        <v-btn
          value="left"
          color="success"
          outlined
          @click="editDetails()"
        >
          新增
        </v-btn>
      </v-card-title>
      <v-card-text class="app-card-body">
        <slot>
          <template>
            <v-card flat>
              <v-tabs
                v-model="activeId"
                vertical
              >
                <v-tab
                  v-for="i in sysData"
                  :key="i.appId"
                  :href="`#${i.appId}`"
                >
                  {{ i.name }}
                </v-tab>

                <v-tab-item
                  v-for="i in sysData"
                  :key="i.id"
                  :value="i.appId"
                >
                  <v-card flat>
                    <v-card-text class="containerRig">
                      <v-data-table
                        ref="refListTable"
                        :headers="tableColumns"
                        :items="queueList"
                        :options.sync="options"
                        :server-items-length="totalLists"
                        :items-per-page="10"
                        :loading="loading"
                        no-data-text="暂无数据"
                        no-results-text="未找到相关数据"
                        loading-text="加载中，请稍等..."
                      >
                        <!-- action -->
                        <template #[`item.actions`]="{ item }">
                          <v-btn
                            class="mr-1"
                            text
                            small
                            color="primary"
                            @click="editDetails(item)"
                          >
                            编辑
                          </v-btn>
                          <v-btn
                            text
                            small
                            color="primary"
                            @click="deleteDetails(item)"
                          >
                            删除
                          </v-btn>
                        </template>
                      </v-data-table>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
              </v-tabs>
            </v-card>
          </template>
        </slot>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { ref, onUnmounted, watch } from '@vue/composition-api'
import store from '@/store'
import { useRouter } from '@core/utils'
import { modalEnquireTitle, modalFail, toastSuccess } from '@core/utils/prompt'
import storeModule from './queueStoreModule'
import roleManagementQueueList from './roleManagementQueueList'

export default {
  components: {
  },
  inheritAttrs: false,
  props: {
  },
  setup() {
    const WORK_STORE_MODULE_NAME = 'roleManage-list'

    // Register module
    if (!store.hasModule(WORK_STORE_MODULE_NAME)) store.registerModule(WORK_STORE_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(WORK_STORE_MODULE_NAME)) store.unregisterModule(WORK_STORE_MODULE_NAME)
    })
    const { router } = useRouter()
    const itemValue = ref({})
    const itemId = ref(0)
    const sysData = ref([])
    const active = []
    const open = []

    const {
      refListTable,
      fetchStaff,
      queueList,
      activeId,
      tableColumns,
      options,
      totalLists,
      loading,
    } = roleManagementQueueList()

    const fetchSysLists = () => {
      store.dispatch('roleManage-list/fetchSysLists')
        .then(response => {
          sysData.value = response.data.data
        })
    }

    fetchSysLists()

    const editDetails = item => {
      router.push({ name: 'roleManagement-edit', query: { id: item?.id, name: item?.name, appId: activeId.value } || '' })
    }

    const deleteDetails = item => {
      modalEnquireTitle('角色删除后无法恢复，确定要删除吗？').then(isConfirm => {
        if (isConfirm.value) {
          store.dispatch('roleManage-list/deleteDetails', item.id)
            .then(response => {
              console.log(response.data)
              if (response.data.status === 200) {
                toastSuccess('删除成功！')
                fetchStaff()
              }
            }).catch(error => {
              modalFail(error.response.data.message)
            })
        }
      })
    }

    watch(() => activeId.value, () => {
      if (options.value.page) {
        options.value.page = 1
        loading.value = true
        fetchStaff()
      }
    })

    return {
      fetchStaff,
      refListTable,
      queueList,
      tableColumns,
      activeId,
      options,
      totalLists,
      loading,
      active,
      editDetails,
      deleteDetails,
      itemId,
      itemValue,
      sysData,
      open,
    }
  },
}
</script>

<style scoped>
.containerRig{
  padding: 0 50px 0 110px;
}
</style>
