import axios from '@axios'
import config from '../../../../config'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchLists(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${config.serviceUrl}/system/users/pages`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addManage(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${config.serviceUrl}/system/users/register`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editAdmin(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${config.serviceUrl}/system/users/update`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchSysLists() {
      return new Promise((resolve, reject) => {
        axios
          .get(`${config.serviceUrl}/app/user/roles`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchFrameworkLists(ctx, level) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${config.serviceUrl}/app/department/level?level=${level}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchMainLists(ctx, parentId) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${config.serviceUrl}/app/user/corp?parentId=${parentId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
