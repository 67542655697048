<template>
  <v-card id="menu-list">
    <menu-list-add-new
      v-model="isAddNewSidebarActive"
      :menu-options="menuOptions"
      :flatted-menu="flattedMenu"
      @refetch-data="fetchMenuList"
    ></menu-list-add-new>

    <menu-list-edit
      :edit-item="editItemObj.editItem"
      :is-edit-sidebar-active.sync="isEditSidebarActive"
      :menu-options="menuOptions"
      :flatted-menu="flattedMenu"
      @refetch-data="fetchMenuList"
    ></menu-list-edit>
    <v-card-title>
      {{ curRouteTitle }}
      <v-spacer></v-spacer>
      <!-- create new item -->
      <v-btn
        color="primary"
        class="me-3"
        @click.stop="isAddNewSidebarActive = !isAddNewSidebarActive"
      >
        <v-icon
          size="18"
          class="me-1"
        >
          mdi-plus
        </v-icon>
        <span>新增菜单</span>
      </v-btn>
    </v-card-title>

    <v-divider></v-divider>
    <div
      v-if="menuList.length === 0"
      class="d-flex justify-center"
    >
      <div class="my-10">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
        加载中，请稍等...
      </div>
    </div>
    <!-- treeview -->
    <v-treeview
      :items="menuList"
      :search="search"
      :open.sync="open"
      hoverable
    >
      <template #prepend="{ item }">
        <v-icon>
          {{ item.icon ? resolveIconName(item.icon) : 'mdi-circle-small' }}
        </v-icon>
      </template>

      <template #append="{ item }">
        <!-- actions -->
        <div class="d-flex align-center justify-center">
          <!-- view -->
          <div @click="updateStatus(item)">
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  small
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon size="18">
                    {{ item.isHidden ? 'mdi-eye-off' : 'mdi-eye' }}
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ item.isHidden ? '显示' : '隐藏' }}</span>
            </v-tooltip>
          </div>

          <div @click="clickEdit(item)">
            <!-- edit  -->
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  small
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon size="18">
                    mdi-square-edit-outline
                  </v-icon>
                </v-btn>
              </template>
              <span>编辑</span>
            </v-tooltip>
          </div>

          <div @click="deletePrompt(item)">
            <!-- delete  -->
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  small
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon size="18">
                    mdi-close
                  </v-icon>
                </v-btn>
              </template>
              <span>删除</span>
            </v-tooltip>
          </div>
        </div>
      </template>
    </v-treeview>
  </v-card>
</template>

<script>
import { ref, onUnmounted, reactive } from '@vue/composition-api'
import store from '@/store'

// icon相关函数
import { resolveIconName } from '@core/utils/icon'
import router from '@/router'
import useMenuList from './useMenuList'
import storeModule from './menuStoreModule'
import MenuListAddNew from './AddNew.vue'
import MenuListEdit from './Edit.vue'

export default {
  name: 'MenuList',
  components: {
    MenuListAddNew,
    MenuListEdit,
  },
  setup() {
    const WORK_STORE_MODULE_NAME = 'menu-list'

    // Register module
    if (!store.hasModule(WORK_STORE_MODULE_NAME)) store.registerModule(WORK_STORE_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(WORK_STORE_MODULE_NAME)) store.unregisterModule(WORK_STORE_MODULE_NAME)
    })
    const {
      menuList,
      menuOptions,
      flattedMenu,
      fetchMenuList,
      updateStatus,
      deletePrompt,
    } = useMenuList()
    fetchMenuList()

    const curRouteTitle = router.currentRoute.meta.pageTitle
    const isAddNewSidebarActive = ref(false)
    const isEditSidebarActive = ref(false)

    const editItemObj = reactive({
      editItem: {},
    })

    // DONE:可以不需要发ajax请求获取详情数据（传递已有的数据）
    // 注意不能直接赋Object给详情数据，因为Object是引用传递，
    // 对应变量保存的地址中的内容变化可被监听到，但地址都变了就没有办法监听数据变化了。
    // 但可以用Object.assign方法改变变量对应地址中的内容(或对象中套对象)。
    // 点击编辑按钮事件
    const clickEdit = item => {
      editItemObj.editItem = item
      isEditSidebarActive.value = !isEditSidebarActive.value
    }
    const open = ref([1, 2])
    const search = ref(null)

    const consoleTest = (...mes) => {
      console.log(mes)
    }

    return {
      menuList,
      menuOptions,
      flattedMenu,
      open,
      search,
      curRouteTitle,
      isAddNewSidebarActive,
      isEditSidebarActive,
      editItemObj,
      fetchMenuList,
      updateStatus,
      deletePrompt,
      clickEdit,
      consoleTest,

      // icons
      resolveIconName,
    }
  },
}
</script>

<style scoped>

</style>
