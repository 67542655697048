import axios from '@axios'
import config from '../../../../config'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchLists(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${config.serviceUrl}/thirdUser/getThirdUser`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addStaff(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${config.serviceUrl}/thirdUser/addThirdUser`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editStaff(ctx, { id, data }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`${config.serviceUrl}/thirdUser/${id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getPayData(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${config.serviceUrl}/thirdUser/${data.id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchTreeLists() {
      return new Promise((resolve, reject) => {
        axios
          .get(`${config.serviceUrl}/dingTalk/deptTree`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
