import { ref, watch } from '@vue/composition-api'

import store from '@/store'

export default function permissionConfigureQueueList() {
  const tableColumns = [
    { value: 'name', text: '角色', sortable: false },
    { value: 'actions', text: '操作', sortable: false },
  ]
  const totalLists = ref(0)
  const userName = ref('')
  const userPhone = ref('')
  const options = ref({
    sortBy: ['createdAt'],
    sortDesc: [true],
  })
  const loading = ref(false)
  const menuList = ref([])
  const menuOptions = ref([])
  const flattedMenu = ref([])

  function genMenuOptions() {
    menuOptions.value = [{
      name: '顶级菜单',
      id: 0,
    }]
    flattedMenu.value = [{
      name: '顶级菜单',
      id: 0,
    }]
    menuList.value.forEach(item => {
      const children = []
      if (item.children) {
        item.children.forEach(childItem => {
          // 去除二层以下的所有子节点的children
          const child = {
            id: childItem.id,
            name: childItem.name,
          }
          children.push(child)
          flattedMenu.value.push(child)
        })
      }
      flattedMenu.value.push({
        id: item.id,
        name: item.name,
      })
      menuOptions.value.push({
        children,
        id: item.id,
        name: item.name,
      })
    })
  }
  const fetchMenuList = id => {
    store
      .dispatch('permission-list/getMenuDataById', id)
      .then(response => {
        const { data } = response.data
        menuList.value = data
        genMenuOptions()
      })
      .catch(error => {
        console.log(error)
      })
  }

  return {
    fetchMenuList,
    tableColumns,
    totalLists,
    userName,
    userPhone,
    menuList,
    menuOptions,
    flattedMenu,
    options,
    loading,
  }
}
