import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
import { VTreeview } from 'vuetify/lib/components/VTreeview';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VCardTitle,[_c('span',{staticClass:"headline"},[_vm._v("编辑员工详情")]),_c(VSpacer),_c(VBtn,{staticClass:"mt-n1",attrs:{"fab":"","dark":"","small":"","color":"primary","to":{name:'businessFrame-list'}}},[_c(VIcon,[_vm._v("mdi-arrow-u-left-top")])],1)],1),_c(VCardText,[_c(VContainer,[_c('div',[_c('h3',[_vm._v("选择系统:")]),_c(VRow,_vm._l((_vm.rolesData),function(n){return _c('div',{key:n.id,staticClass:"pl-10 mt-1"},[_c(VCheckbox,{attrs:{"label":n.name,"value":n.appId},model:{value:(_vm.choseSys),callback:function ($$v) {_vm.choseSys=$$v},expression:"choseSys"}})],1)}),0)],1),_c('div',[_c('h3',[_vm._v("账号角色")]),_vm._l((_vm.rolesData),function(i){return _c('div',{key:i.id,staticClass:"mt-1"},[_c('div',[_c('h3',[_c(VIcon,{attrs:{"x-large":"","color":"primary"}},[_vm._v(" mdi-circle-small ")]),_vm._v(_vm._s(i.name)+": ")],1),_c(VRow,_vm._l((i.appRoles),function(n){return _c('div',{key:n.id,staticClass:"pl-10 mt-1"},[_c(VCheckbox,{attrs:{"label":n.name,"value":n.id},model:{value:(_vm.selectedRole),callback:function ($$v) {_vm.selectedRole=$$v},expression:"selectedRole"}})],1)}),0)],1),_c(VDivider,{staticClass:"mt-4"})],1)})],2),_c('div',[_c('h3',[_vm._v("业务架构归属")]),_c('div',{staticClass:"mt-1"},[_c(VTreeview,{attrs:{"items":_vm.frameWork},scopedSlots:_vm._u([{key:"label",fn:function(ref){
var item = ref.item;
return [(item.level!==0)?_c(VCheckbox,{staticClass:"ml-3",attrs:{"label":item.name,"true-value":1,"false-value":0},on:{"click":function($event){return _vm.cancelCheck(item)}},model:{value:(item.architecture),callback:function ($$v) {_vm.$set(item, "architecture", $$v)},expression:"item.architecture"}}):_vm._e(),(item.level===0)?_c('span',[_vm._v(_vm._s(item.name))]):_vm._e()]}},{key:"append",fn:function(ref){
var item = ref.item;
return [_c(VRow,[(item.level!==0)?_c(VCheckbox,{attrs:{"true-value":1,"false-value":0,"disabled":item.architecture===0,"label":"设为负责人"},on:{"click":function($event){return _vm.ifChange(item)}},model:{value:(item.leader),callback:function ($$v) {_vm.$set(item, "leader", $$v)},expression:"item.leader"}}):_vm._e()],1)]}}])})],1)]),_c(VSwitch,{staticClass:"mb-3",attrs:{"color":"primary","true-value":1,"false-value":0,"hide-details":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" 是否独立分配频道 "),_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"ml-2",attrs:{"icon":"","small":""}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"color":"grey lighten-1"}},[_vm._v(" mdi-help-circle ")])],1)]}}])},[_c('span',[_vm._v("该账号可在业务架构规则之外单独配置可见频道")])])]},proxy:true}]),model:{value:(_vm.staffRole.independentAllocation),callback:function ($$v) {_vm.$set(_vm.staffRole, "independentAllocation", $$v)},expression:"staffRole.independentAllocation"}})],1)],1),_c(VCardActions,[_c(VSpacer),_c('div',{staticClass:"d-flex mt-4 mr-4"},[_c(VBtn,{staticClass:"me-3",attrs:{"color":"primary"},on:{"click":function($event){return _vm.editPartTime()}}},[_vm._v(" 提交 ")]),_c(VBtn,{staticClass:"mx-2",attrs:{"type":"reset","outlined":"","to":{name:'businessFrame-list'}}},[_vm._v(" 取消 ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }