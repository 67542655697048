import { ref, watch } from '@vue/composition-api'

import store from '@/store'
import { modalFail } from '@core/utils/prompt'

export default function adminQueueList() {
  const tableColumns = [
    { value: 'id', text: '序号', sortable: false },
    { value: 'username', text: '账号名', sortable: false },
    { value: 'appName', text: '系统', sortable: false },
    { value: 'appDepartmentName', text: '业务架构', sortable: false },
    { value: 'dingtalkDepartmentName', text: '钉钉主体', sortable: false },
    { value: 'actions', text: '操作', sortable: false },
  ]
  const totalLists = ref(0)
  const userName = ref('')
  const userPhone = ref('')
  const queueList = ref([])
  const options = ref({
    sortBy: ['createdAt'],
    sortDesc: [true],
  })
  const loading = ref(false)

  const fetchStaff = () => {
    store
      .dispatch('admin-list/fetchLists', {
        pageSize: options.value.itemsPerPage,
        page: options.value.page,
        // sortBy: options.value.sortBy[0],
        // sortDesc: options.value.sortDesc[0],
      })
      .then(response => {
        const { data, total } = response.data
        queueList.value = data
        totalLists.value = total
        loading.value = false
      })
      .catch(error => {
        modalFail(error.response.data.message)
      })
  }

  watch(options, () => {
    loading.value = true
    fetchStaff()
  })

  watch([userName, userPhone], () => {
    options.value.page = 1
    loading.value = true
    fetchStaff()
  })

  return {
    fetchStaff,
    tableColumns,
    totalLists,
    userName,
    userPhone,
    queueList,
    options,
    loading,
  }
}
