<template>
  <v-navigation-drawer
    :value="isAddSidebarActive"
    temporary
    touchless
    :right="!$vuetify.rtl"
    :width="$vuetify.breakpoint.smAndUp ? 450 : '100%'"
    app
    @input="(val) => $emit('update:is-add-sidebar-active', val)"
  >
    <v-card>
      <v-card-title>
        <span class="headline">兼职员工信息</span>
      </v-card-title>
      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
          @submit.prevent="addPartTime"
        >
          <v-text-field
            v-model="submitData.user.name"
            outlined
            dense
            clearable
            label="员工姓名"
            placeholder="请输入姓名"
            hide-details="auto"
            class="mb-6"
            :rules="[required]"
          ></v-text-field>

          <v-radio-group
            v-model="submitData.user.deptIdList"
            label="选择部门"
            row
            hide-details
          >
            <v-radio
              label="配音"
              :value="11"
            ></v-radio>
            <v-radio
              label="文案"
              :value="12"
            ></v-radio>
            <v-radio
              label="后期"
              :value="13"
            ></v-radio>
          </v-radio-group>

          <!--          <v-menu-->
          <!--            bottom-->
          <!--            max-height="500"-->
          <!--          >-->
          <!--            <template #activator="{ on, attrs }">-->
          <!--              <v-text-field-->
          <!--                :value="active[0]?active[0].name:''"-->
          <!--                readonly-->
          <!--                outlined-->
          <!--                dense-->
          <!--                clearable-->
          <!--                label="所属部门"-->
          <!--                placeholder="请选择所属部门"-->
          <!--                hide-details="auto"-->
          <!--                class="mb-6"-->
          <!--                v-bind="attrs"-->
          <!--                v-on="on"-->
          <!--              ></v-text-field>-->
          <!--            </template>-->

          <!--            <v-sheet>-->
          <!--              <v-treeview-->
          <!--                :items="menuData"-->
          <!--                :active.sync="active"-->
          <!--                :open.sync="open"-->
          <!--                return-object-->
          <!--                hoverable-->
          <!--                activatable-->
          <!--              ></v-treeview>-->
          <!--            </v-sheet>-->
          <!--          </v-menu>-->

          <v-text-field
            v-model="submitData.user.email"
            outlined
            dense
            clearable
            label="邮箱"
            placeholder="请输入邮箱"
            hide-details="auto"
            class="mb-6 mt-6"
          ></v-text-field>

          <v-text-field
            v-model="submitData.user.mobile"
            outlined
            dense
            clearable
            label="手机号"
            placeholder="请输入手机号"
            hide-details="auto"
            class="mb-6"
            :rules="[required]"
          ></v-text-field>

          <v-menu
            v-model="menu1"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template #activator="{ on, attrs }">
              <v-text-field
                v-model="computedDateFormatted"
                label="入职日期"
                prepend-icon="mdi-calendar"
                v-bind="attrs"
                :rules="[required]"
                v-on="on"
              ></v-text-field>
            </template>

            <v-date-picker
              v-model="date"
              value-format="YYYY-MM-DD"
              no-title
              color="primary"
              @input="menu1 = false"
            ></v-date-picker>
          </v-menu>

          <v-radio-group
            v-model="submitData.user.active"
            label="是否禁用"
            row
            hide-details
          >
            <v-radio
              label="激活"
              :value="1"
            ></v-radio>
            <v-radio
              label="禁用"
              :value="0"
            ></v-radio>
          </v-radio-group>

          <v-divider style="margin: 20px 0"></v-divider>

          <v-select
            v-model="submitData.userPay.payType"
            :items="payTypeItems"
            label="支付类型"
            placeholder="请选择支付类型"
            dense
            hide-details
            class="mb-6"
          ></v-select>

          <v-text-field
            v-model="submitData.userPay.alipayNumber"
            outlined
            dense
            clearable
            label="支付账号"
            placeholder="请输入支付账号"
            hide-details="auto"
            class="mb-6"
          ></v-text-field>

          <v-text-field
            v-model="submitData.userPay.idCard"
            outlined
            dense
            clearable
            label="身份证"
            placeholder="请输入身份证"
            hide-details="auto"
            class="mb-6"
          ></v-text-field>

          <v-text-field
            v-model="submitData.userPay.contactMobile"
            outlined
            dense
            clearable
            label="联系方式"
            placeholder="请输入手机号"
            hide-details="auto"
            class="mb-6"
          ></v-text-field>
        </v-form>
        <div class="d-flex">
          <v-btn
            color="primary"
            class="me-3"
            @click="addPartTime()"
          >
            提交
          </v-btn>
          <v-btn
            class="mx-2"
            type="reset"
            outlined
            @click="resetData"
          >
            取消
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-navigation-drawer>
</template>

<script>

import store from '@/store'
import {
  onUnmounted, ref, watch, computed,
} from '@vue/composition-api/dist/vue-composition-api'
import { emailValidator, required } from '@core/utils/validation'
import { modalFail, toastSuccess } from '@core/utils/prompt'
import storeModule from './queueStoreModule'

export default {
  model: {
    prop: 'isAddSidebarActive',
    event: 'update:is-add-sidebar-active',
  },
  props: {
    isAddSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const WORK_STORE_MODULE_NAME = 'parttime-list'

    // Register module
    if (!store.hasModule(WORK_STORE_MODULE_NAME)) store.registerModule(WORK_STORE_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(WORK_STORE_MODULE_NAME)) store.unregisterModule(WORK_STORE_MODULE_NAME)
    })

    const date = ref(new Date().toISOString().substr(0, 10))
    const payTypeItems = [
      { text: '支付宝', value: 'alipay' },
    ]
    const menu1 = ref(false)
    const menuData = ref([])
    const active = ref([])
    const open = ref([])

    const formatDate = dates => {
      if (!dates) return null
      const [year, month, day] = dates.split('-')

      return `${month}/${day}/${year}`
    }

    const computedDateFormatted = computed(() => formatDate(date.value))

    const form = ref(null)
    const valid = ref(false)
    const blankData = {
      user: {
        name: '',
        deptIdList: 11,
        email: '',
        mobile: '',
        joinTime: '2022-7-20',
        active: 1,
      },
      userPay: {
        payType: '',
        alipayNumber: '',
        contactMobile: '',
        idCard: '',
      },
    }

    const submitData = ref(JSON.parse(JSON.stringify(blankData)))
    const getTreeData = () => {
      store.dispatch('parttime-list/fetchTreeLists')
        .then(response => {
          menuData.value = response.data.data
        })
    }
    getTreeData()

    const validate = () => {
      form.value.validate()
    }
    const resetForm = () => {
      form.value.reset()
    }

    const resetData = () => {
      submitData.value = JSON.parse(JSON.stringify(blankData))
      resetForm()
      emit('update:is-add-sidebar-active', false)
    }

    const addPartTime = () => {
      if (valid.value) {
        store.dispatch('parttime-list/addStaff', submitData.value).then(() => {
          emit('refetch-data')
          emit('update:is-add-sidebar-active', false)
          toastSuccess('成功新增兼职员工')
        }).catch(error => {
          modalFail(error.response.data.message)
        })
      } else {
        validate()
      }
    }

    watch(date, () => {
      submitData.value.user.joinTime = date.value
    })

    watch(active, () => {
      if (active.value.length) {
        // eslint-disable-next-line prefer-destructuring
        submitData.value.user.deptIdList = JSON.stringify({ deptIdList: active.value[0].deptId })
      }
    })

    return {
      menuData,
      date,
      computedDateFormatted,
      menu1,
      formatDate,
      valid,
      validate,
      addPartTime,
      submitData,
      resetData,
      form,
      active,
      open,
      payTypeItems,

      // validation
      required,
      emailValidator,
    }
  },
}
</script>

<style scoped>

</style>
