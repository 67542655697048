import { ref, watch } from '@vue/composition-api'

import store from '@/store'

export default function businessFrameQueueList() {
  const tableColumns = [
    { value: 'name', text: '角色', sortable: false },
    { value: 'actions', text: '操作', sortable: false },
  ]
  const totalLists = ref(0)
  const userName = ref('')
  const userPhone = ref('')
  const options = ref({
    sortBy: ['createdAt'],
    sortDesc: [true],
  })
  const loading = ref(false)
  const queueList = ref([])
  const aFrameWorkFilter = ref(null)
  const AFrameworkOptions = ref([])

  // 遍历数据，将item的员工appUserDepartments放入children
  const Fn = data => {
    // 使用forEach遍历
    data.forEach(item => {
      if (item.children) {
        // 调用递归函数
        Fn(item.children)
        // eslint-disable-next-line no-param-reassign
      } else item.children = []
      // eslint-disable-next-line no-param-reassign
      item.children = item.appUserDepartments ? item.children.concat(item.appUserDepartments) : item.children
    })

    return data
  }

  const fetchSysLists = () => {
    store.dispatch('business-list/fetchFrameworkLists', 1)
      .then(response => {
        AFrameworkOptions.value = response.data.data
        aFrameWorkFilter.value = AFrameworkOptions.value[0].id
      })
  }
  fetchSysLists()

  const fetchMenuList = () => {
    if (aFrameWorkFilter.value) {
      store
        .dispatch('business-list/getFrameDataById', { id: aFrameWorkFilter.value })
        .then(response => {
          const { data } = response.data
          queueList.value = Fn(data)
        })
        .catch(error => {
          console.log(error)
        })
    }
  }

  return {
    fetchMenuList,
    fetchSysLists,
    tableColumns,
    queueList,
    totalLists,
    userName,
    userPhone,
    options,
    loading,
    aFrameWorkFilter,
    AFrameworkOptions,
  }
}
