import axios from '@axios'
import config from '../../config'

const prefix = config.serviceUrl

// 新增
export const channelCreate = params => axios.post(`${prefix}/channel/create`, params)

// 编辑
export const channelConfig = params => axios.post(`${prefix}/channel/config`, params)

// 批量设置
export const channelBatchConfig = params => axios.post(`${prefix}/channel/batchConfig`, params)

// 导入获利状态
export const channelImportProfit = params => axios.post(`${prefix}/channel/importProfit`, params.form)

export const channelExport = params => axios.post(`${prefix}/channel/export`, params, { responseType: 'blob' })

// eslint-disable-next-line comma-dangle
export const fieldList = params => axios.get(`${prefix}/youtube/field/list`, params, { responseType: 'blob', })

export const exportExls = params => axios.get(`${config.serviceUrl}/download/${params.taskId}`, {
  responseType: 'blob',
})

// 新增 获取应用列表
export const newGetAppList = params => axios.get(`${prefix}/dingTalk/role/getAppList`, params)

// 新增 获取应用角色列表
export const getAppRoleList = params => axios.get(`${prefix}/dingTalk/role/getAppRoleList?appId=${params.appId}`)

// 新增 删除应用角色
export const deleteAppRole = params => axios.get(`${prefix}/dingTalk/role/deleteAppRole?roleId=${params.roleId}`)

// 新增 保存-更新角色
export const saveOrUpdateRole = params => axios.post(`${prefix}/dingTalk/role/saveOrUpdateRole`, params)

// 新增 获取应用菜单列表
export const getAppMenuList = params => axios.get(`${prefix}/dingTalk/role/getAppMenuList`, { params })

// 新增 是否存在应用角色用户
export const existsAppRoleUser = params => axios.get(`${prefix}/dingTalk/user/existsAppRoleUser`, { params })

// 员工管理  获取部门列表
export const deptList = params => axios.get(`${prefix}/dingTalk/dept/deptList`, { params })

// 员工管理 获取员工列表
export const userList = params => axios.post(`${prefix}/dingTalk/user/userList`, params)

// 员工管理 同步用户与部门列表
export const syncUserAndDept = params => axios.post(`${prefix}/dingTalk/user/syncUserAndDept`, params)

// 员工管理  获取用户App角色与管理范围列表
export const getAppRoleDeptList = params => axios.get(`${prefix}/dingTalk/user/getAppRoleDeptList`, { params })

// 员工管理  更新用户App角色与管理范围
export const updateAppRoleDept = params => axios.post(`${prefix}/dingTalk/user/updateAppRoleDept`, params)
