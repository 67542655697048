<template>
  <div>
    <user-details
      :item="itemValue"
      :is-show-user-details-modal-active.sync="isShowUserDetailsModalActive"
    />
    <loader-dialog
      :is-loader-active.sync="isLoaderActive"
    />
    <v-row style="white-space:nowrap;">
      <div class="col-md-3">
        <vxe-table
          :data="menuData"
          :scroll-x="{gt: 10000}"
          :show-header="false"
          :tree-config="{transform: true, accordion: true, iconOpen: '', iconClose: '', rowField: 'deptId'}"
          show-overflow
          :empty-text="'暂无数据'"
          :loading="treeLoading"
        >
          <vxe-column tree-node>
            <template #default="{ row }">
              <span>
                <!--                <template>-->
                <!--                  <v-icon :icon="row.corpId?'FolderMinusIcon':'GitPullRequestIcon'" />-->
                <!--                </template>-->
                <a
                  class="ml-1"
                  @click="change(row)"
                >{{ row.name }}</a>
              </span>
            </template>
          </vxe-column>
        </vxe-table>
      </div>
      <div class="col-md-9">
        <v-card>
          <v-card-title>
            <v-row>
              <v-col
                cols="12"
                md="3"
              >
                <h3 class="mt-1 ml-2">
                  <v-icon large>
                    mdi-account-circle-outline
                  </v-icon>
                  部门人员
                </h3>
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <v-text-field
                  v-model="userName"
                  class="mt-1"
                  placeholder="员工名"
                  single-line
                  dense
                  outlined
                  hide-details
                />
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <v-text-field
                  v-model="userPhone"
                  class="mt-1"
                  placeholder="手机号"
                  single-line
                  dense
                  outlined
                  hide-details
                />
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <v-btn-toggle
                  class="mt-1 ml-n2"
                >
                  <v-btn
                    value="left"
                    color="success"
                    outlined
                    small
                    style="height: 40px !important;"
                    @click="synchronize('Dept')"
                  >
                    同步部门
                  </v-btn>
                  <v-btn
                    value="center"
                    color="success"
                    outlined
                    small
                    style="height: 40px !important;"
                    @click="synchronize('User')"
                  >
                    同步用户
                  </v-btn>
                  <v-btn
                    value="right"
                    color="error"
                    outlined
                    small
                    style="height: 40px !important;"
                    @click="push()"
                  >
                    推送
                  </v-btn>
                </v-btn-toggle>
              </v-col>
            </v-row>
          </v-card-title>

          <v-data-table
            ref="refListTable"
            :headers="tableColumns"
            :items="queueList"
            :options.sync="options"
            :server-items-length="totalLists"
            :items-per-page="10"
            :loading="loading"
            no-data-text="暂无数据"
            no-results-text="未找到相关数据"
            loading-text="加载中，请稍等..."
          >
            <!-- action -->
            <template #[`item.actions`]="{ item }">
              <v-btn
                text
                small
                color="primary"
                @click="showDetails(item)"
              >
                查看
              </v-btn>
            </template>

            <!-- name -->
            <template #[`item.name`]="{item}">
              <div class="d-flex align-center">
                <v-avatar
                  :color="item.avatar ? '' : 'primary'"
                  :class="item.avatar ? '' : 'v-avatar-light-bg primary--text'"
                  size="32"
                >
                  <v-img
                    v-if="item.avatar"
                    :src="item.avatar"
                  ></v-img>
                  <span v-else>{{ item.name?item.name.slice(1,3).toUpperCase():'' }}</span>
                </v-avatar>
                <div class="d-flex flex-column ms-3">
                  <span class="d-block font-weight-semibold text--primary text-truncate">{{ item.name }}</span>
                  <small>{{ item.jobNumber }}</small>
                </div>
              </div>
            </template>

            <!-- employeeStatus -->
            <template #[`item.employeeStatus`]="{ item }">
              <v-chip
                small
                :color="resolveStatusVariant(item.employeeStatus)"
                :class="`${resolveStatusVariant(item.employeeStatus)}--text`"
                class="v-chip-light-bg"
              >
                {{ resolveStatusText(item.employeeStatus) }}
              </v-chip>
            </template>
          </v-data-table>
        </v-card>
      </div>
    </v-row>
  </div>
</template>

<script>
import VXETable from 'vxe-table'
import 'vxe-table/lib/style.css'
import Vue from 'vue'
import {
  ref, onUnmounted,
} from '@vue/composition-api'
import store from '@/store'
import { modalFail, toastFail, modalSuccess } from '@core/utils/prompt'
import userList from './userList'
import storeModule from './storeModule'

import UserDetails from './userDetails.vue'
import LoaderDialog from './Loader.vue'

Vue.use(VXETable)

export default {
  components: {
    UserDetails,
    LoaderDialog,
  },

  setup() {
    const APP_STORE_MODULE_NAME = 'app-user'

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
    })
    const isShowUserDetailsModalActive = ref(false)
    const isLoaderActive = ref(false)
    const itemValue = ref({})
    const treeLoading = ref(false)

    const {
      refListTable,
      fetchStaff,
      queueList,
      userName,
      userPhone,
      tableColumns,
      options,
      totalLists,
      deptId,
      corpId,
      loading,

      // UI
      resolveStatusVariant,
      resolveStatusText,
    } = userList()

    // // 查询
    // function searchQ() {
    //   console.log(this)
    //   refListTable.value.refresh()
    // }
    function change(item) {
      deptId.value = item.deptId
      corpId.value = item.corpId
      if (item.parentId === '0') {
        // eslint-disable-next-line no-param-reassign
        deptId.value = '1'
      }
      options.value.page = 1
    }

    // 打开查看模态框
    function showDetails(item) {
      itemValue.value = item
      isShowUserDetailsModalActive.value = !isShowUserDetailsModalActive.value
    }

    const synchronize = role => {
      isLoaderActive.value = true
      store.dispatch('app-user/synchronize', role)
        .then(response => {
          isLoaderActive.value = false
          if (response.data.status === 200) {
            modalSuccess('同步成功')
            this.getTreeData()
          } else toastFail('同步失败')
        }).catch(response => {
          isLoaderActive.value = false
          modalFail(response.data.message)
        })
    }
    const push = () => {
      isLoaderActive.value = true
      store.dispatch('app-user/pushUser')
        .then(response => {
          isLoaderActive.value = false
          if (response.data.status === 200) {
            modalSuccess('推送成功')
          } else toastFail('推送失败')
        }).catch(response => {
          isLoaderActive.value = false
          modalFail(response.data.message)
        })
    }

    return {
      isShowUserDetailsModalActive,
      isLoaderActive,
      fetchStaff,
      refListTable,
      queueList,
      tableColumns,
      userName,
      userPhone,
      options,
      totalLists,
      deptId,
      corpId,
      change,
      showDetails,
      itemValue,
      synchronize,
      push,
      loading,
      treeLoading,

      // searchQ,

      resolveStatusVariant,
      resolveStatusText,

    }
  },
  data() {
    return {
      menuData: [],
      fixed: true,
      accountStatusOptions: [
        { text: '未启用', value: 'inactive' },
        { text: '启用', value: 'active' },
        { text: '禁用', value: 'disable' },
      ],
      sendStatusOptions: [
        { text: '是', value: '1' },
        { text: '否', value: '0' },
      ],
    }
  },
  mounted() {
    this.treeLoading = true
    this.getTreeData()
  },

  methods: {
    getTreeData() {
      store.dispatch('app-user/fetchLists')
        .then(response => {
          this.menuData = response.data.data
          this.treeLoading = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'vxe-table/styles/variable.scss';
</style>

<style lang="scss">
@import 'vxe-table/styles/variable.scss';

.hidden-about {
  /*强制文本在一行内显示*/
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
a{
  color: #736C7C;
}
.v-application a{
  color: #736C7C;
}
.vxe-body--row .row--current.row--hover a{
  color: black !important;
}
.vxe-body--row .row--current{
  background-color: #685fd4 !important;
}
.vxe-table--render-default .vxe-body--row.row--current.row--hover {
  background-color: #685fd4;
}

.vxe-table--render-default .vxe-body--row.row--current {
  background-color: #342A54 !important;
}

$vxe-font-color: #736C7C;
$vxe-primary-color: #28243D;
$vxe-table-border-color: transparent;
$vxe-table-font-color: $vxe-font-color;
$vxe-table-body-background-color: transparent;
$vxe-table-row-hover-background-color: #343d55;
$vxe-table-header-background-color: #161d31;
$vxe-table-header-font-color: $vxe-font-color;

@import 'vxe-table/styles/table.scss';
@import 'vxe-table/styles/header.scss';
//@import 'vxe-table/styles/body.scss';
</style>
