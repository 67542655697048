<template>
  <v-dialog
    :value="isDetailsModalActive"
    width="550px"
    @input="(val) => $emit('update:is-details-modal-active', val)"
  >
    <v-card>
      <v-card-title>
        消息队列详情
      </v-card-title>

      <v-form style="width: 500px">
        <v-row
          cols="12"
          class="ml-2"
        >
          <v-col cols="3">
            ID：
          </v-col>
          <v-col cols="9">
            {{ itemDetails.id?itemDetails.id:'' }}
          </v-col>
        </v-row>
        <v-row
          cols="12"
          class="mt-2 ml-2"
        >
          <v-col cols="3">
            消息编号：
          </v-col>
          <v-col
            cols="9"
          >
            {{ itemDetails.messageId?itemDetails.messageId:'空' }}
          </v-col>
        </v-row>
        <v-row
          cols="12"
          class="mt-2 ml-2"
        >
          <v-col cols="3">
            分类编号：
          </v-col>
          <v-col
            cols="9"
          >
            {{ itemDetails.groupId?itemDetails.groupId:'空' }}
          </v-col>
        </v-row>
        <v-row
          cols="12"
          class="mt-2 ml-2"
        >
          <v-col cols="3">
            标签：
          </v-col>
          <v-col cols="9">
            {{ itemDetails.tag?itemDetails.tag:'空' }}
          </v-col>
        </v-row>
        <v-row
          cols="12"
          class="mt-2 ml-2"
        >
          <v-col cols="3">
            topicId：
          </v-col>
          <v-col cols="9">
            {{ itemDetails.topicId?itemDetails.topicId:'空' }}
          </v-col>
        </v-row>
        <v-row
          cols="12"
          class="mt-2 ml-2"
        >
          <v-col cols="3">
            创建时间：
          </v-col>
          <v-col cols="9">
            {{ itemDetails.createdAt }}
          </v-col>
        </v-row>
        <v-row
          cols="12"
          class="mt-2 ml-2"
        >
          <v-col cols="3">
            更新时间：
          </v-col>
          <v-col cols="9">
            {{ itemDetails.updatedAt?itemDetails.updatedAt:'空' }}
          </v-col>
        </v-row>
        <v-row
          cols="12"
          class="mt-2 ml-2 mb-3"
        >
          <v-col
            cols="3"
            class="mt-3"
          >
            内容：
          </v-col>
          <v-col cols="9">
            <template>
              <v-expansion-panels>
                <v-expansion-panel>
                  <v-expansion-panel-header disable-icon-rotate>
                    消息内容
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-row
                      v-for="(item,key) in titleList"
                      :key="key"
                      cols="12"
                    >
                      <v-col cols="4">
                        {{ item }}
                      </v-col>
                      <v-col cols="8">
                        {{ contentList[item] }}
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </template>
          </v-col>
        </v-row>
      </v-form>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          @click="$emit('update:is-details-modal-active',false)"
        >
          关闭
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import store from '@/store'
import storeModule from '@/views/application/message/queueStoreModule'
import { onUnmounted, ref, watch } from '@vue/composition-api/dist/vue-composition-api'

export default {
  model: {
    prop: 'isDetailsModalActive',
    event: 'update:is-details-modal-active',
  },
  props: {
    isDetailsModalActive: {
      type: Boolean,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const WORK_STORE_MODULE_NAME = 'message-list'

    // Register module
    if (!store.hasModule(WORK_STORE_MODULE_NAME)) store.registerModule(WORK_STORE_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(WORK_STORE_MODULE_NAME)) store.unregisterModule(WORK_STORE_MODULE_NAME)
    })

    const itemDetails = ref({})
    const contentList = ref({})
    const titleList = ref([])

    const showDetails = () => {
      itemDetails.value = props.item
      contentList.value = JSON.parse(props.item.content)
      titleList.value = Object.keys(contentList.value)

      // store.dispatch('message-list/showDetails', { id: props.id })
      //   .then(response => {
      //     const { data } = response
      //     itemDetails.value = data
      //     console.log(data)
      //   })
      //   .catch(error => {
      //     console.log(error)
      //   })
    }
    watch(
      () => props.isDetailsModalActive,
      () => {
        if (props.isDetailsModalActive === true) {
          showDetails()
        }
      },
    )

    return {
      itemDetails,
      contentList,
      titleList,
      showDetails,
    }
  },
}
</script>

<style scoped>

</style>
