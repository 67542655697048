<template>
  <v-dialog
    :value="isDetailsModalActive"
    width="550px"
    @input="(val) => $emit('update:is-details-modal-active', val)"
  >
    <v-card>
      <v-card-title>
        消息队列详情
      </v-card-title>

      <v-form style="width: 500px">
        <v-row
          cols="12"
          class="ml-2"
        >
          <v-col cols="3">
            ID：
          </v-col>
          <v-col cols="9">
            {{ itemDetails.id?itemDetails.id:'' }}
          </v-col>
        </v-row>
        <v-row
          cols="12"
          class="mt-2 ml-2"
        >
          <v-col cols="3">
            消息编号：
          </v-col>
          <v-col
            cols="9"
          >
            {{ itemDetails.messageId?itemDetails.messageId:'空' }}
          </v-col>
        </v-row>
        <v-row
          cols="12"
          class="mt-2 ml-2"
        >
          <v-col cols="3">
            手机号：
          </v-col>
          <v-col
            cols="9"
          >
            {{ itemDetails.objectId?itemDetails.objectId:'空' }}
          </v-col>
        </v-row>
        <v-row
          cols="12"
          class="mt-2 ml-2"
        >
          <v-col cols="3">
            类型：
          </v-col>
          <v-col cols="9">
            {{ itemDetails.type?itemDetails.type:'空' }}
          </v-col>
        </v-row>
        <v-row
          cols="12"
          class="mt-2 ml-2"
        >
          <v-col cols="3">
            状态：
          </v-col>
          <v-col cols="9">
            {{ resolveStatusText(itemDetails.status?itemDetails.status:'空') }}
          </v-col>
        </v-row>
        <v-row
          cols="12"
          class="mt-2 ml-2"
        >
          <v-col cols="3">
            返回内容：
          </v-col>
          <v-col cols="9">
            {{ itemDetails.errMsg?itemDetails.errMsg:'空' }}
          </v-col>
        </v-row>
        <v-row
          cols="12"
          class="mt-2 ml-2"
        >
          <v-col cols="3">
            发送时间：
          </v-col>
          <v-col cols="9">
            {{ itemDetails.sendDate?itemDetails.sendDate:'空' }}
          </v-col>
        </v-row>
        <v-row
          cols="12"
          class="mt-2 ml-2"
        >
          <v-col cols="3">
            接收时间：
          </v-col>
          <v-col cols="9">
            {{ itemDetails.receiveDate?itemDetails.receiveDate:'空' }}
          </v-col>
        </v-row>
        <v-row
          cols="12"
          class="mt-2 ml-2"
        >
          <v-col cols="3">
            创建时间：
          </v-col>
          <v-col cols="9">
            {{ itemDetails.createdAt }}
          </v-col>
        </v-row>
        <v-row
          cols="12"
          class="mt-2 ml-2"
        >
          <v-col cols="3">
            更新时间：
          </v-col>
          <v-col cols="9">
            {{ itemDetails.updatedAt?itemDetails.updatedAt:'空' }}
          </v-col>
        </v-row>
      </v-form>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          @click="$emit('update:is-details-modal-active',false)"
        >
          关闭
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import store from '@/store'
import storeModule from '@/views/application/message/queueStoreModule'
import { onUnmounted, ref, watch } from '@vue/composition-api/dist/vue-composition-api'

export default {
  model: {
    prop: 'isDetailsModalActive',
    event: 'update:is-details-modal-active',
  },
  props: {
    isDetailsModalActive: {
      type: Boolean,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const WORK_STORE_MODULE_NAME = 'sms-list'

    // Register module
    if (!store.hasModule(WORK_STORE_MODULE_NAME)) store.registerModule(WORK_STORE_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(WORK_STORE_MODULE_NAME)) store.unregisterModule(WORK_STORE_MODULE_NAME)
    })

    const itemDetails = ref({})

    const resolveStatusText = status => {
      if (status === 0) return '待发送'
      if (status === 1) return '发送成功'
      if (status === 2) return '发送失败'

      return '默认'
    }

    const showDetails = () => {
      itemDetails.value = props.item
    }
    watch(
      () => props.isDetailsModalActive,
      () => {
        if (props.isDetailsModalActive === true) {
          showDetails()
        }
      },
    )

    return {
      itemDetails,
      showDetails,
      resolveStatusText,
    }
  },
}
</script>

<style scoped>

</style>
