<template>
  <v-card style="min-height: 328px">
    <v-card-title>
      <slot name="title">
        部门属性
      </slot>
    </v-card-title>
    <v-card-text class="app-card-body ml-3">
      <v-row>
        <v-col
          cols="12"
          sm="5"
        >
          <v-treeview
            :active.sync="active"
            :items="menuData"
            :open.sync="open"
            rounded
            hoverable
            activatable
            color="primary"
            open-on-click
            transition
          >
            <template #prepend="{ item }">
              <v-icon v-if="!item.children">
                mdi-account-group-outline
              </v-icon>
            </template>
          </v-treeview>
        </v-col>

        <v-divider :vertical="$vuetify.breakpoint.name!=='xs'"></v-divider>

        <v-col
          cols="12"
          sm="6"
          class="ml-4"
        >
          <v-scroll-y-transition mode="out-in">
            <div
              v-if="!selected"
              class="title font-weight-light"
            >
              请选择一个部门
            </div>
            <v-card
              v-else
              :key="selected.id"
              class="pt-6 mx-auto"
              flat
              max-width="1000"
            >
              <v-card-text class="text-center">
                <v-row
                  cols="12"
                  class="ml-2"
                >
                  <v-col cols="3">
                    部门名：
                  </v-col>
                  <v-col
                    cols="9"
                    class="text-left"
                  >
                    {{ selected.name?selected.name:'空' }}
                  </v-col>
                </v-row>
                <v-row
                  cols="12"
                  class="mt-2 ml-2"
                >
                  <v-col cols="3">
                    频道类型：
                  </v-col>
                  <v-col
                    cols="9"
                    class="text-left"
                  >
                    {{ selected.channelType?selected.channelType:'空' }}
                  </v-col>
                </v-row>
                <v-row
                  cols="12"
                  class="mt-2 ml-2"
                >
                  <v-col
                    cols="3"
                    class="mt-2"
                  >
                    部门类型：
                  </v-col>
                  <v-col
                    cols="5"
                  >
                    <v-text-field
                      v-model="selected.type"
                      outlined
                      dense
                      clearable
                      placeholder="请输入部门类型"
                      hide-details="auto"
                      class="mb-6"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  class="text-right"
                  color="success"
                  @click="editDepart(selected)"
                >
                  应用
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-scroll-y-transition>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import {
  ref, onUnmounted, computed, watch,
} from '@vue/composition-api'
import store from '@/store'
import { toastFail, toastSuccess } from '@core/utils/prompt'
import storeModule from './queueStoreModule'

export default {
  setup() {
    const WORK_STORE_MODULE_NAME = 'department-list'

    // Register module
    if (!store.hasModule(WORK_STORE_MODULE_NAME)) store.registerModule(WORK_STORE_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(WORK_STORE_MODULE_NAME)) store.unregisterModule(WORK_STORE_MODULE_NAME)
    })
    const active = ref([])
    const open = ref([])
    const items = ref([])
    const menuData = ref([])

    const selected = computed(() => {
      // if (!active.value.length) return undefined

      const id = active.value[0]

      return menuData.value.find(item => item.id === id)
    })

    const getData = () => {
      store.dispatch('department-list/fetchLists')
        .then(response => {
          menuData.value = response.data.data
        })
    }
    getData()

    const editDepart = item => {
      store.dispatch('department-list/editDepart', item)
        .then(response => {
          if (response.data.status === 200) {
            toastSuccess('应用成功!')
          }
        }).catch(error => {
          toastFail(error.response.data.message)
        })
    }

    watch(active, () => {
    })

    return {
      active,
      open,
      items,
      menuData,
      selected,
      getData,
      editDepart,
    }
  },
}
</script>

<style scoped>
.v-card__text{
  padding: 0;
}
</style>
