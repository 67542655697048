import store from '@/store'
import { ref, watch } from '@vue/composition-api'
import { toastFail } from '@core/utils/prompt'

export default function dingdingQueueList() {
  const tableColumns = [
    { text: '钉钉号', value: 'objectId', sortable: false },
    {
      text: '用户名', value: 'name', sortable: false, width: '80px',
    },
    { text: '发送时间', value: 'sendDate', sortable: false },
    { text: '接收时间', value: 'receiveDate', sortable: false },
    { text: '短信内容', value: 'content', sortable: false },
    { text: '发送状态', value: 'status', sortable: false },
    { text: '返回内容', value: 'errMsg', sortable: false },
    { text: '操作', value: 'action', sortable: false },
  ]

  const queueList = ref([])
  const totalLists = ref(0)
  const statusFilter = ref(null)
  const date = ref('')
  const options = ref({
    sortBy: ['createdAt'],
    sortDesc: [true],
  })
  const searchQuery = ref('')
  const loading = ref(false)

  const fetchQueueList = () => {
    store.dispatch('dingding-list/fetchLists', {
      type: 'dingding',
      name: searchQuery.value,
      status: statusFilter.value,
      pageSize: options.value.itemsPerPage,
      page: options.value.page,
      time: date.value,
    })
      .then(response => {
        const { data, total } = response.data
        totalLists.value = total
        queueList.value = data
        loading.value = false
      })
      .catch(error => {
        toastFail(error)
      })
  }

  watch(options, () => {
    loading.value = true
    fetchQueueList()
  })

  watch([statusFilter, searchQuery, date], () => {
    options.value.page = 1
    loading.value = true
    fetchQueueList()
  })

  const resolveStatusText = status => {
    if (status === 0) return '待发送'
    if (status === 1) return '发送成功'
    if (status === 2) return '发送失败'

    return '默认'
  }

  const resolveStatusVariant = {
    0: 'primary',
    1: 'success',
    2: 'error',
  }

  return {
    fetchQueueList,
    totalLists,
    queueList,
    searchQuery,
    tableColumns,
    options,
    date,
    loading,
    statusFilter,
    resolveStatusText,
    resolveStatusVariant,
  }
}
