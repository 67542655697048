import store from '@/store'
import { ref, watch } from '@vue/composition-api'
import { toastFail } from '@core/utils/prompt'

export default function messageQueueList() {
  const tableColumns = [
    { text: 'ID', value: 'id', sortable: false },
    { text: '名称', value: 'topicId', sortable: false },
    { text: '消息标签', value: 'tag', sortable: false },
    { text: '创建时间', value: 'createdAt', sortable: true },
    { text: '操作', value: 'action', sortable: false },
  ]
  const queueList = ref([])
  const totalLists = ref(0)
  const options = ref({
    sortBy: ['createdAt'],
    sortDesc: [true],
  })
  const searchQuery = ref('')

  const fetchQueueList = () => {
    store.dispatch('department-list/fetchLists', {
      tag: searchQuery.value,
      pageSize: options.value.itemsPerPage,
      page: options.value.page,
      sortBy: options.value.sortBy[0],
      sortDesc: options.value.sortDesc[0],
    })
      .then(response => {
        const { data, total } = response.data
        totalLists.value = total
        queueList.value = data
      })
      .catch(error => {
        toastFail(error)
      })
  }

  watch([options, searchQuery], () => {
    fetchQueueList()
  })

  return {
    fetchQueueList,
    totalLists,
    queueList,
    searchQuery,
    tableColumns,
    options,
  }
}
