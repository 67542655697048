<template>
  <v-navigation-drawer
    :value="isEditSidebarActive"
    temporary
    touchless
    :right="!$vuetify.rtl"
    :width="$vuetify.breakpoint.smAndUp ? 450 : '100%'"
    app
    @input="(val) => $emit('update:is-edit-sidebar-active', val)"
  >
    <v-card :flat="true">
      <div class="drawer-header d-flex align-center mb-4">
        <span class="font-weight-semibold text-base text--primary">编辑顶级架构</span>
        <v-spacer></v-spacer>
        <v-btn
          icon
          small
          @click="$emit('update:is-edit-sidebar-active',false)"
        >
          <v-icon size="22">
            mdi-close
          </v-icon>
        </v-btn>
      </div>
      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
          @submit.prevent="editFirst"
        >
          <v-text-field
            v-model="submitData.name"
            outlined
            dense
            clearable
            label="架构名称"
            placeholder="请输入架构名称"
            hide-details="auto"
            class="mb-3"
            :rules="[required]"
          ></v-text-field>
        </v-form>
        <div class="mt-10 d-flex">
          <v-btn
            color="primary"
            class="me-3"
            @click="editFirst()"
          >
            提交
          </v-btn>
          <v-btn
            class="mx-2"
            type="reset"
            outlined
            @click="resetData"
          >
            取消
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-navigation-drawer>
</template>

<script>

import store from '@/store'
import {
  onUnmounted, ref, watch,
} from '@vue/composition-api/dist/vue-composition-api'
import { emailValidator, required } from '@core/utils/validation'
import { modalFail, toastSuccess } from '@core/utils/prompt'
import storeModule from './queueStoreModule'

export default {
  model: {
    prop: 'isEditSidebarActive',
    event: 'update:is-edit-sidebar-active',
  },
  props: {
    isEditSidebarActive: {
      type: Boolean,
      required: true,
    },
    frameValue: {
      type: Object,
      required: true,
    },
    aFrameWorkFilter: {
      type: String,
      required: false,
    },
  },
  setup(props, { emit }) {
    const WORK_STORE_MODULE_NAME = 'business-list'

    // Register module
    if (!store.hasModule(WORK_STORE_MODULE_NAME)) store.registerModule(WORK_STORE_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(WORK_STORE_MODULE_NAME)) store.unregisterModule(WORK_STORE_MODULE_NAME)
    })

    const form = ref(null)
    const valid = ref(false)
    const blankData = {
      id: '',
      name: '',
    }

    const submitData = ref(JSON.parse(JSON.stringify(blankData)))

    const validate = () => {
      form.value.validate()
    }
    const resetForm = () => {
      form.value.reset()
    }

    const resetData = () => {
      submitData.value = JSON.parse(JSON.stringify(blankData))
      resetForm()
      emit('update:is-edit-sidebar-active', false)
    }

    const editFirst = () => {
      if (valid.value) {
        store.dispatch('business-list/editFirst', submitData.value).then(() => {
          emit('refetch-data', props.aFrameWorkFilter)
          emit('refetch-level-data')
          emit('update:is-edit-sidebar-active', false)
          toastSuccess('成功编辑顶级架构')
        }).catch(error => {
          modalFail(error.response.data.message)
        })
      } else {
        validate()
      }
    }

    watch(
      () => props.isEditSidebarActive,
      () => {
        if (props.isEditSidebarActive === true) {
          if (props.frameValue.id) {
            // 传递父架构id
            submitData.value.id = props.frameValue.id
            submitData.value.name = props.frameValue.name
          }
        }
      },
    )

    return {
      valid,
      validate,
      editFirst,
      submitData,
      resetData,
      form,

      // validation
      required,
      emailValidator,
    }
  },
}
</script>

<style scoped>

</style>
