<template>
  <v-navigation-drawer
    :value="isAddSidebarActive"
    temporary
    touchless
    :right="!$vuetify.rtl"
    :width="$vuetify.breakpoint.smAndUp ? 450 : '100%'"
    app
    @input="(val) => $emit('update:is-add-sidebar-active', val)"
  >
    <v-card>
      <v-card-title>
        <span class="headline">新增管理员账号</span>
      </v-card-title>
      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
          @submit.prevent="addPartTime"
        >
          <v-select
            v-model="submitData.appId"
            :items="sysData"
            label="选择系统"
            placeholder="请选择系统"
            item-text="name"
            item-value="appId"
            dense
            outlined
            hide-details
            class="mb-6"
            :rules="[required]"
          ></v-select>

          <v-select
            v-model="submitData.appDepartmentId"
            :items="frameworkData"
            label="选择一级业务架构"
            placeholder="请选择一级业务架构"
            item-text="name"
            item-value="id"
            dense
            outlined
            hide-details
            class="mb-6"
            :rules="[required]"
          ></v-select>

          <v-select
            v-model="submitData.dingtalkDepartmentId"
            :items="subjectData"
            label="选择所属主体"
            placeholder="请选择公司主体"
            item-text="name"
            item-value="id"
            dense
            outlined
            hide-details
            class="mb-6"
            :rules="[required]"
          ></v-select>

          <v-text-field
            v-model="submitData.username"
            outlined
            dense
            clearable
            label="用户名"
            placeholder="请输入用户名"
            hide-details="auto"
            class="mb-6 mt-6"
            :rules="[required]"
          >
            <template #prepend>
              <v-tooltip
                bottom
              >
                <template #activator="{ on }">
                  <v-icon v-on="on">
                    mdi-help-circle-outline
                  </v-icon>
                </template>
                用于登录账号中心
              </v-tooltip>
            </template>
          </v-text-field>

          <v-text-field
            v-model="submitData.password"
            outlined
            dense
            readonly
            label="默认初始密码"
            :type="isPasswordVisible ? 'text' : 'password'"
            :append-icon="isPasswordVisible ? 'mdi-eye-off-outline':'mdi-eye-outline'"
            hide-details="auto"
            class="mb-6"
            :rules="[required]"
            @click:append="isPasswordVisible = !isPasswordVisible"
          ></v-text-field>
        </v-form>
        <div class="d-flex">
          <v-btn
            color="primary"
            class="me-3"
            @click="addManage()"
          >
            提交
          </v-btn>
          <v-btn
            class="mx-2"
            type="reset"
            outlined
            @click="resetData"
          >
            取消
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-navigation-drawer>
</template>

<script>

import store from '@/store'
import {
  onUnmounted, ref, watch, computed,
} from '@vue/composition-api/dist/vue-composition-api'
import { emailValidator, required } from '@core/utils/validation'
import { modalFail, toastSuccess } from '@core/utils/prompt'
import storeModule from './queueStoreModule'

export default {
  model: {
    prop: 'isAddSidebarActive',
    event: 'update:is-add-sidebar-active',
  },
  props: {
    isAddSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const WORK_STORE_MODULE_NAME = 'admin-list'

    // Register module
    if (!store.hasModule(WORK_STORE_MODULE_NAME)) store.registerModule(WORK_STORE_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(WORK_STORE_MODULE_NAME)) store.unregisterModule(WORK_STORE_MODULE_NAME)
    })

    const isPasswordVisible = ref(true)
    const sysData = ref([])
    const frameworkData = ref([])
    const subjectData = ref([])
    const form = ref(null)
    const valid = ref(false)
    const blankData = {
      userName: '',
      password: '123456', // 初始密码为123456
      appId: '',
      appDepartmentId: null,
      dingtalkDepartmentId: null,
    }

    const submitData = ref(JSON.parse(JSON.stringify(blankData)))
    const getTreeData = () => {
      // 获取所有子系统
      store.dispatch('admin-list/fetchSysLists')
        .then(response => {
          sysData.value = response.data.data
        })

      // 获取所有一级（参数为1）架构
      store.dispatch('admin-list/fetchFrameworkLists', 1)
        .then(response => {
          frameworkData.value = response.data.data
        })

      // 获取所有顶级（参数为0）主体
      store.dispatch('admin-list/fetchMainLists', 0)
        .then(response => {
          subjectData.value = response.data.data
        })
    }

    const validate = () => {
      form.value.validate()
    }
    const resetForm = () => {
      form.value.reset()
    }

    const resetData = () => {
      submitData.value = JSON.parse(JSON.stringify(blankData))
      resetForm()
      emit('update:is-add-sidebar-active', false)
    }

    const addManage = () => {
      if (valid.value) {
        store.dispatch('admin-list/addManage', submitData.value).then(() => {
          emit('refetch-data')
          emit('update:is-add-sidebar-active', false)
          toastSuccess('成功新增管理员账号')
        }).catch(error => {
          modalFail(error.response.data.message)
        })
      } else {
        validate()
      }
    }

    watch(() => props.isAddSidebarActive,
      () => {
        if (props.isAddSidebarActive === true) {
          getTreeData()
        }
      })

    return {
      sysData,
      frameworkData,
      subjectData,
      valid,
      validate,
      addManage,
      submitData,
      resetData,
      form,

      // validation
      required,
      emailValidator,

      isPasswordVisible,
    }
  },
}
</script>

<style scoped>

</style>
