<script>
import {
  onMounted, ref, reactive,
} from '@vue/composition-api'
import { VPopover } from 'v-tooltip'

export default {
  name: 'OverflowTooltip',
  components: { VPopover },
  props: {
    label: {
      type: String,
      default: '',
      required: true,
    },
    link: {
      type: String,
      default: '',
    },
    target: {
      type: String,
      default: '_blank',
    },
    isLink: Boolean,
    color: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const linkRef = ref()
    const isPopover = ref(false)
    function openLink() {
      if (!props.isLink || !props.link) return false
      window.open(props.link, props.target)

      return true
    }
    const ellipsis = reactive({
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      width: '100%',
      height: '100%',
      lineHeight: 'inherit',
    })

    const style = ref({
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      width: '10px',
      height: '1px',
    })

    onMounted(() => {
      // 获取父节点极其参数
      const time = setTimeout(() => {
        const parentElement = linkRef.value?.parentNode
        const { width, height } = parentElement.getBoundingClientRect()
        ellipsis.width = `${width - 32}px`
        ellipsis.height = `${height - 4}px`
        ellipsis.lineHeight = `${height}px`
        if (props.color) {
          ellipsis.color = props.color
        }
        style.value = ellipsis
        const dicStyle = {
          height: '1px',
          lineHeight: 'inherit',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          backgroundColor: 'transparent',
          color: 'transparent',
          display: 'inline-block',
        }

        // 创建计算节点
        const element = document.createElement('div')
        const keys = Object.keys(dicStyle)
        keys.forEach(key => {
          element.style[key] = dicStyle[key]
        })
        element.innerText = props.label || props.link
        document.body.appendChild(element)

        // 子节点宽度如果大于父节点宽度，则显示弹出层
        if (element.scrollWidth > (width - 28)) {
          isPopover.value = true
        }

        // 移除计算节点
        document.body.removeChild(element)

        clearTimeout(time)
      }, 200)
    })

    return {
      openLink,
      linkRef,
      style,
      isPopover,
    }
  },
}
</script>
<template>
  <div
    v-if="!isPopover"
    ref="linkRef"
    :class="{ 'app-link': isLink }"
    :style="style"
    @click="openLink"
  >
    <slot name="label">
      {{ label || link }}
    </slot>
  </div>
  <v-popover
    v-else
    :html="false"
    placement="bottom"
    popover-class="popover-content"
    trigger="hover"
  >
    <div
      :class="{ 'app-link': isLink }"
      :style="style"
      @click.stop="openLink"
    >
      {{ label || link }}
    </div>
    <template #popover>
      <slot name="content">
        <span :style="{ color: color }">
          {{ label || link }}
        </span>
      </slot>
    </template>
  </v-popover>
</template>

<style lang='scss' scoped>
.app-link {
  color: #2381FF !important;
  cursor: pointer;
  display: inline-block;
}
</style>
