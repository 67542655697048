import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTreeview } from 'vuetify/lib/components/VTreeview';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('add-dialog',{attrs:{"aframe-work-filter":_vm.aFrameWorkFilter,"frame-id":_vm.frameId,"is-add-sidebar-active":_vm.isAddSidebarActive},on:{"update:isAddSidebarActive":function($event){_vm.isAddSidebarActive=$event},"update:is-add-sidebar-active":function($event){_vm.isAddSidebarActive=$event},"refetch-data":_vm.fetchMenuList,"refetch-level-data":_vm.fetchSysLists}}),_c('edit-dialog',{attrs:{"aframe-work-filter":_vm.aFrameWorkFilter,"frame-value":_vm.frameValue,"is-edit-sidebar-active":_vm.isEditSidebarActive},on:{"update:isEditSidebarActive":function($event){_vm.isEditSidebarActive=$event},"update:is-edit-sidebar-active":function($event){_vm.isEditSidebarActive=$event},"refetch-data":_vm.fetchMenuList,"refetch-level-data":_vm.fetchSysLists}}),_c(VCard,_vm._g(_vm._b({},'v-card',_vm.$attrs,false),_vm.$listeners),[_c(VCardTitle,[_vm._t("title",function(){return [_vm._v(" 业务架构配置 ")]}),_c(VSpacer),_c(VSelect,{staticClass:"invoice-list-status me-3",staticStyle:{"width":"1px"},attrs:{"items":_vm.AFrameworkOptions,"item-text":"name","item-value":"id","single-line":"","outlined":"","dense":"","hide-details":"","placeholder":"选择一级架构"},model:{value:(_vm.aFrameWorkFilter),callback:function ($$v) {_vm.aFrameWorkFilter=$$v},expression:"aFrameWorkFilter"}}),_c(VBtn,{attrs:{"color":"primary","loading":_vm.btnLoading},on:{"click":_vm.syncEmployees}},[_vm._v(" 钉钉同步 ")])],2),_c(VCardText,[_c(VTreeview,{attrs:{"hoverable":"","open-all":"","items":_vm.queueList},scopedSlots:_vm._u([{key:"label",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.name?item.name:item.appUser.nickname))]),(!!(!item.name&&item.appUser.jobName))?_c('span',[_vm._v("-"+_vm._s(item.appUser.jobName))]):_vm._e()]}},{key:"append",fn:function(ref){
var item = ref.item;
return [_c(VRow,[(_vm.ifShowAdd(item))?_c(VBtn,{staticClass:"mr-2",attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.addNextMenu(item)}}},[_vm._v(" 新增下级 ")]):_vm._e(),(_vm.ifShowEdit(item))?_c(VBtn,{staticClass:"mr-2",attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.editMenu(item)}}},[_vm._v(" 编辑 ")]):_vm._e(),(item.level>0&&item.level<=5)?_c(VBtn,{staticClass:"mr-2",attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.deleteMenu(item)}}},[_vm._v(" 删除 ")]):_vm._e()],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }