import axios from '@axios'
import config from '../../../../config/index'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchItems(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${config.serviceUrl}/system/role/pages`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchItemDetails(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${config.serviceUrl}/system/role/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteItem(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`${config.serviceUrl}/system/role/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editItem(ctx, itemDetails) {
      return new Promise((resolve, reject) => {
        axios
          .put(`${config.serviceUrl}/system/role/${itemDetails.id}`, {
            name: itemDetails.item.name,
            id: itemDetails.id,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateCache() {
      return new Promise((resolve, reject) => {
        axios
          .post(`${config.serviceUrl}/system/role/cache`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchMenuTree() {
      return new Promise((resolve, reject) => {
        axios
          .get(`${config.serviceUrl}/system/menus/menuTree`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchMenuTreeAuth(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${config.serviceUrl}/system/role/${id}/permission`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editMenuTreeAuth(ctx, itemDetails) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${config.serviceUrl}/system/role/${itemDetails.id}/permission`, itemDetails.menuTreeChoseData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addItem(ctx, itemDetails) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${config.serviceUrl}/system/role`, itemDetails)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
