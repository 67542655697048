<template>
  <div class="pagination">
    <div class="px8 fs15">
      总共
      <span>{{ total }}</span>
      条
    </div>
    <div style="width: 110px">
      <v-select
        v-model="Size"
        :items="pageSizes"
        dense
        hide-details
        outlined
        @change="selectChange"
      >
        <template #selection="{ item }">
          <span>{{ item }}条/页</span>
        </template>
      </v-select>
    </div>
    <div style="height: 40px">
      <v-pagination
        v-if="showPagination"
        v-model="currentPage"
        dense
        :length="pageLength"
        total-visible="8"
      />
    </div>
    <div
      v-if="showJump"
      class="pagination_jump"
    >
      <span style="flex-shrink: 0; padding-right: 8px">前往</span>
      <v-text-field
        v-model="pageInput"
        hide-details
        dense
        outlined
        placeholder="跳转页数"
        @keyup="pageInput=pageInput.replace(/^(0+)|[^\d]+/g,'')"
        @keydown.enter="pageJump"
      />
      <span style="padding-left: 5px">页</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Pagination',
  props: {
    // 当前页
    pageIndex: {
      type: Number,
      default: 1,
    },

    // 每页显示条数
    pageSize: {
      type: Number,
      default: 10,
    },

    // 总条数
    total: {
      type: Number,
      default: 0,
    },

    // 分页下拉显示
    pageSizes: {
      type: Array,
      default: () => [10, 20, 30],
    },
    showJump: {
      type: [Boolean, String],
      default: true,
    },
  },
  data() {
    return {
      pageInput: 1,
      showPagination: false,
      time: null,
    }
  },
  computed: {
    // 分页比例
    Size: {
      get() {
        return this.pageSize
      },
      set(val) {
        this.$emit('size-change', val)
      },
    },

    // 长度
    pageLength() {
      return (Math.ceil(this.total / this.pageSize)) || 1
    },

    // 页
    currentPage: {
      get() {
        return this.pageIndex
      },
      set(val) {
        this.$emit('current-change', val)
      },
    },
  },
  watch: {
    pageIndex: {
      handler(val) {
        this.pageInput = val
      },
      deep: true,
    },
    total: {
      handler() {
        this.showPagination = false
        this.$nextTick(() => {
          const time = setTimeout(() => {
            this.showPagination = true
            clearTimeout(time)
          }, 800)
        })
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.showPagination = false
    this.$nextTick(() => {
      const time = setTimeout(() => {
        this.showPagination = true
        clearTimeout(time)
      }, 800)
    })
  },
  methods: {
    selectChange() {
      this.showPagination = false
      this.$nextTick(() => {
        const time = setTimeout(() => {
          this.showPagination = true
          clearTimeout(time)
        }, 800)
      })
    },
    pageJump() {
      const numLength = Number(this.pageInput)
      if (numLength > this.pageLength) {
        this.pageInput = this.pageLength
        this.$emit('current-change', this.pageLength)

        return
      }

      this.$emit('current-change', numLength)
    },
  },
}
</script>

<style lang='scss' scoped>
.pagination {
  padding: 5px;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  &_jump {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 150px;
  }
}

.v-input {
  ::v-deep .v-input__control {
    .v-input__slot {
      height: 40px;
      margin: 0;
    }
    .v-text-field__details {
      display: none;
    }
  }
}
</style>
