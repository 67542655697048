<template>
  <v-dialog
    :value="isDetailsModalActive"
    width="550px"
    @input="(val) => $emit('update:is-details-modal-active', val)"
  >
    <v-card>
      <v-card-title>
        兼职员工详情
      </v-card-title>

      <v-form style="width: 500px">
        <v-row
          cols="12"
          class="ml-2"
        >
          <v-col cols="3">
            ID：
          </v-col>
          <v-col cols="9">
            {{ itemDetails.id?itemDetails.id:'' }}
          </v-col>
        </v-row>
        <v-row
          cols="12"
          class="mt-2 ml-2"
        >
          <v-col cols="3">
            姓名：
          </v-col>
          <v-col
            cols="9"
          >
            {{ itemDetails.name?itemDetails.name:'空' }}
          </v-col>
        </v-row>
        <v-row
          cols="12"
          class="mt-2 ml-2"
        >
          <v-col cols="3">
            职位：
          </v-col>
          <v-col
            cols="9"
          >
            {{ resolveDeptText(itemDetails.deptIdList) }}
          </v-col>
        </v-row>
        <v-row
          cols="12"
          class="mt-2 ml-2"
        >
          <v-col cols="3">
            入职时间：
          </v-col>
          <v-col
            cols="9"
          >
            {{ itemDetails.joinTime?itemDetails.joinTime:'空' }}
          </v-col>
        </v-row>
        <v-row
          cols="12"
          class="mt-2 ml-2"
        >
          <v-col cols="3">
            手机号：
          </v-col>
          <v-col
            cols="9"
          >
            {{ itemDetails.mobile?itemDetails.mobile:'空' }}
          </v-col>
        </v-row>
        <v-row
          cols="12"
          class="mt-2 ml-2"
        >
          <v-col cols="3">
            邮箱：
          </v-col>
          <v-col cols="9">
            {{ itemDetails.email?itemDetails.email:'空' }}
          </v-col>
        </v-row>
        <v-row
          cols="12"
          class="mt-2 ml-2"
        >
          <v-col cols="3">
            状态：
          </v-col>
          <v-col cols="9">
            <v-chip
              small
              :color="resolveStatusVariant(item.active)"
              :class="`${resolveStatusVariant(item.active)}--text`"
              class="v-chip-light-bg"
            >
              {{ resolveStatusText(itemDetails.active?itemDetails.active:'空') }}
            </v-chip>
          </v-col>
        </v-row>
        <v-row
          cols="12"
          class="mt-2 ml-2"
        >
          <v-col cols="3">
            创建时间：
          </v-col>
          <v-col cols="9">
            {{ itemDetails.createdAt?itemDetails.createdAt:'空' }}
          </v-col>
        </v-row>
        <v-row
          cols="12"
          class="mt-2 ml-2"
        >
          <v-col cols="3">
            更新时间：
          </v-col>
          <v-col cols="9">
            {{ itemDetails.updatedAt?itemDetails.updatedAt:'空' }}
          </v-col>
        </v-row>
      </v-form>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          @click="$emit('update:is-details-modal-active',false)"
        >
          关闭
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import store from '@/store'
import storeModule from '@/views/application/message/queueStoreModule'
import { onUnmounted, ref, watch } from '@vue/composition-api/dist/vue-composition-api'
import parttimeQueueList from './parttimeQueueList'

export default {
  model: {
    prop: 'isDetailsModalActive',
    event: 'update:is-details-modal-active',
  },
  props: {
    isDetailsModalActive: {
      type: Boolean,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const WORK_STORE_MODULE_NAME = 'message-list'

    // Register module
    if (!store.hasModule(WORK_STORE_MODULE_NAME)) store.registerModule(WORK_STORE_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(WORK_STORE_MODULE_NAME)) store.unregisterModule(WORK_STORE_MODULE_NAME)
    })

    const itemDetails = ref({})

    const {
      resolveStatusVariant,
      resolveStatusText,
      resolveDeptText,
    } = parttimeQueueList()

    const showDetails = () => {
      itemDetails.value = props.item
    }
    watch(
      () => props.isDetailsModalActive,
      () => {
        if (props.isDetailsModalActive === true) {
          showDetails()
        }
      },
    )

    return {
      itemDetails,
      showDetails,
      resolveStatusVariant,
      resolveStatusText,
      resolveDeptText,
    }
  },
}
</script>

<style scoped>

</style>
