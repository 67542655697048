<template>
  <div>
    <add-dialog
      :menu-options="menuOptions"
      :sys-options="sysData"
      :flatted-menu="flattedMenu"
      :app-id="appId"
      :is-add-sidebar-active.sync="isAddSidebarActive"
      @refetch-data="fetchMenuList"
    ></add-dialog>
    <edit-dialog
      :menu-options="menuOptions"
      :sys-options="sysData"
      :flatted-menu="flattedMenu"
      :active-id="activeId"
      :item="itemValue"
      :is-edit-dialog-active.sync="isEditDialogActive"
      @refetch-data="fetchMenuList"
    ></edit-dialog>
    <v-card
      v-bind="$attrs"
      v-on="$listeners"
    >
      <template>
        <v-card
          no-body
        >
          <v-tabs
            v-model="activeId"
            color="deep-purple accent-4"
          >
            <v-tab
              v-for="i in sysData"
              :key="i.appId"
              :href="`#${i.appId}`"
            >
              {{ i.name }}
            </v-tab>
          </v-tabs>
          <v-tabs-items
            v-model="activeId"
          >
            <v-tab-item
              v-for="n in sysData"
              :key="n.appId"
              :value="n.appId"
            >
              <v-container style="width: 100% !important; max-width: 100%;margin-left: 10px; margin-right: 10px;">
                <v-row class="pt-3 pr-3 pb-4">
                  <v-btn
                    value="left"
                    color="primary"
                    outlined
                    @click="add(n.appId)"
                  >
                    新增
                  </v-btn>
                  <v-spacer></v-spacer>
                </v-row>
                {{ n.name }}
                <v-treeview
                  :items="menuList"
                  hoverable
                >
                  <template #prepend="{ item }">
                    <v-icon>
                      {{ item.icon ? resolveIconName(item.icon) : 'mdi-circle-small' }}
                    </v-icon>
                  </template>

                  <template #append="{ item }">
                    <!-- actions -->
                    <div class="d-flex align-center justify-center">
                      <!-- view -->
                      <div @click="updateStatus(item.id)">
                        <v-tooltip bottom>
                          <template #activator="{ on, attrs }">
                            <v-btn
                              icon
                              small
                              v-bind="attrs"
                              v-on="on"
                            >
                              <v-icon size="18">
                                {{ item.isHidden ? 'mdi-eye-off' : 'mdi-eye' }}
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>{{ item.isHidden ? '显示' : '隐藏' }}</span>
                        </v-tooltip>
                      </div>

                      <div @click="editMenu(item)">
                        <!-- edit  -->
                        <v-tooltip bottom>
                          <template #activator="{ on, attrs }">
                            <v-btn
                              icon
                              small
                              v-bind="attrs"
                              v-on="on"
                            >
                              <v-icon size="18">
                                mdi-square-edit-outline
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>编辑</span>
                        </v-tooltip>
                      </div>

                      <div @click="deleteMenu(item)">
                        <!-- delete  -->
                        <v-tooltip bottom>
                          <template #activator="{ on, attrs }">
                            <v-btn
                              icon
                              small
                              v-bind="attrs"
                              v-on="on"
                            >
                              <v-icon size="18">
                                mdi-close
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>删除</span>
                        </v-tooltip>
                      </div>
                    </div>
                  </template>
                </v-treeview>
              </v-container>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </template>
    </v-card>
  </div>
</template>

<script>
import { ref, onUnmounted, watch } from '@vue/composition-api'
import store from '@/store'
import { resolveIconName } from '@core/utils/icon'
import Swal from 'sweetalert2'
import { modalFail } from '@core/utils/prompt'
import storeModule from './queueStoreModule'
import addDialog from './AddNew.vue'
import editDialog from './Edit.vue'
import permissionConfigureQueueList from './permissionConfigureQueueList'

export default {
  components: {
    addDialog,
    editDialog,
  },
  inheritAttrs: false,
  props: {
  },
  setup() {
    const WORK_STORE_MODULE_NAME = 'permission-list'

    // Register module
    if (!store.hasModule(WORK_STORE_MODULE_NAME)) store.registerModule(WORK_STORE_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(WORK_STORE_MODULE_NAME)) store.unregisterModule(WORK_STORE_MODULE_NAME)
    })
    const itemValue = ref({})
    const isDetailsModalActive = ref(false)
    const isAddSidebarActive = ref(false)
    const isEditDialogActive = ref(false)
    const itemId = ref(0)
    const activeId = ref('')
    const appId = ref('')
    const sysData = ref([])

    const {
      refListTable,
      fetchMenuList,
      menuList,
      menuOptions,
      flattedMenu,
      tableColumns,
      options,
      totalLists,
      deptId,
      loading,

      // UI
      resolveStatusVariant,
      resolveStatusText,
      resolveDeptText,
    } = permissionConfigureQueueList()

    const add = id => {
      appId.value = id
      isAddSidebarActive.value = !isAddSidebarActive.value
    }

    const editMenu = item => {
      itemValue.value = item
      isEditDialogActive.value = !isEditDialogActive.value
    }

    const deleteMenu = item => {
      Swal.fire({
        title: `确定删除 ${item.name} 菜单？`,
        text: '删除后该菜单将被丢进宇宙空间再也找回不来了！',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: '是的，我意已决!',
        cancelButtonText: '不，让我再想想',
      }).then(result => {
        if (result.isConfirmed) {
          store
            .dispatch('permission-list/deleteMenu', item.id)
            .then(() => {
              Swal.fire(
                '成功打开宇宙空间通道!',
                '该菜单已被成功丢进宇宙空间删除.',
                'success',
              )
              fetchMenuList(activeId.value)
            })
            .catch(error => {
              modalFail(error.response.data.message)
              if (error.response.status === 404) {
                console.log(error)
              }
            })
        }
      })
    }

    const updateStatus = id => {
      store.dispatch('permission-list/hideMenu', { id })
        .then(response => {
          console.log(response.data.status)
        })
    }

    const fetchSysLists = () => {
      store.dispatch('permission-list/fetchSysLists')
        .then(response => {
          sysData.value = response.data.data
        })
    }

    fetchSysLists()

    watch(() => activeId.value,
      () => {
        fetchMenuList(activeId.value)
        console.log(activeId.value)
      })

    return {
      fetchMenuList,
      refListTable,
      menuList,
      menuOptions,
      flattedMenu,
      tableColumns,
      options,
      totalLists,
      deptId,
      loading,
      activeId,
      sysData,
      editMenu,
      deleteMenu,
      updateStatus,
      add,
      fetchSysLists,
      appId,
      itemId,
      itemValue,
      isDetailsModalActive,
      isAddSidebarActive,
      isEditDialogActive,

      // searchQ,

      resolveStatusVariant,
      resolveStatusText,
      resolveDeptText,
      resolveIconName,
    }
  },
}
</script>

<style scoped>
.containerRig{
  padding: 0 50px 0 110px;
}
</style>
