<script>
export default {
  name: 'TabsNav',
  props: {
    tabItemArr: {
      type: Array,
      default: () => ([]),
    },
    activeName: {
      type: String,
      default: '',
    },
    onTabClick: {
      type: Function,
    },
  },
  setup(props) {
    function changeActiveName(tabItem) {
      // 自己点自己就不让执行了
      if (tabItem.name === props.activeName) {
        return
      }

      // 如果包含禁用项disabled属性（即处于禁用状态），也不让执行（搭配.isForbiddenItem类名）
      if (tabItem.disabled) {
        return
      }
      this.onTabClick(tabItem)
    }

    return {
      changeActiveName,
    }
  },
}
</script>

<template>
  <div class="tab-nav-item-box">
    <div
      v-for="(tabItem, index) in tabItemArr"
      :key="index"
      :class="[
        'tab-nav-item',
        tabItem.name === activeName ? 'highLight' : '',
        tabItem.disabled ? 'isForbiddenItem' : '',
      ]"
      @click="changeActiveName(tabItem)"
    >
      {{ tabItem.label }}
    </div>
  </div>
</template>

<style lang='scss' scoped>
.tab-nav-item-box {
  width: 100%;
  border-bottom: thin solid rgba(94, 86, 105, 0.14);

  .tab-nav-item {
    display: inline-block;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    font-weight: 500;
    padding: 0 12px;
    cursor: pointer;

    // 非禁用时
    &:not(.isForbiddenItem):hover {
      color: #9155fd;
    }
  }

  // 高亮项样式
  .highLight {
    color: #9155fd;
    border-bottom: 2px solid #9155fd;
  }

  // 禁用样式
  .isForbiddenItem {
    cursor: not-allowed;
    color: #aaa;
  }
}
</style>
