<!-- eslint-disable vue/valid-v-for -->
<template>
  <v-navigation-drawer
    :value="isAddSidebarActive"
    temporary
    touchless
    :right="!$vuetify.rtl"
    :width="$vuetify.breakpoint.smAndUp ? 1000 : '100%'"
    app
    @input="(val) => $emit('update:is-add-sidebar-active', val)"
  >
    <v-card class="dialog-mains">
      <div class="dialog-top-title d-flex align-center">
        <span>{{ topTitle }}</span>
        <v-btn
          icon
          small
          @click="$emit('update:isAddSidebarActive',false)"
        >
          <v-icon size="22">
            mdi-close
          </v-icon>
        </v-btn>
      </div>

      <v-card-text>
        <div class="main-boxs">
          <div class="add-title">基础信息</div>
          <v-form
            ref="form"
            v-model="valid"
            @submit.prevent="addRoleSubmit"
          >
            <v-row>
              <v-col>
                <v-text-field
                  v-model="submitData.name"
                  outlined
                  dense
                  clearable
                  label="角色名称"
                  placeholder="请输入角色姓名"
                  hide-details="auto"
                  class="mb-6"
                  :disabled="handerState ==2"
                  :rules="[required,namehValidator]"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  v-model="submitData.remark"
                  outlined
                  dense
                  clearable
                  label="角色描述"
                  placeholder="请输入角色描述"
                  hide-details="auto"
                  class="mb-6"
                  :disabled="handerState ==2"
                  :rules="[required,remarkValidator]"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>

          <div class="add-title">权限设置</div>

          <div class="power-mains">
            <div class="power-box">
              <div class="power-tit">
                <div class="power-first">
                  <div class="power-block">一级菜单</div>
                </div>
                <div class="power-second">
                  <div class="power-block">二级菜单</div>
                </div>
                <div class="power-three">
                  <div class="power-block">按钮操作</div>
                </div>
              </div>
              <div class="power-des" v-for="(item,index) in powerList" :key="index">
                <div class="power-first">
                  <div class="power-block">
                    <div class="checks-box">
                      <v-checkbox
                        v-model="item.has"
                        :label="item.name"
                        :disabled="handerState ==2"
                        @change="firstChange(item)"
                      ></v-checkbox>
                    </div>
                  </div>
                </div>
                <div class="power-secondthree" >
                  <div class="power-secondthree-box" v-for="(ite,inde) in item.childrenList" :key="'ite'+inde">
                    <div class="power-second">
                      <div class="power-block power-block-border">
                        <div class="checks-box">
                          <v-checkbox
                            v-model="ite.has"
                            :label="ite.name"
                            @change="secondChange(ite)"
                            :disabled="!item.has || handerState ==2"
                          ></v-checkbox>
                          <span v-show=" ite.has && ite.childrenList && checkAllFieldIs(ite)" class="second-field" @click="secondOperate(ite,index,inde)">设置字段权限</span>
                        </div>
                      </div>
                    </div>
                    <div class="power-three">
                      <div class="power-block power-block-border" >
                        <div class="checks-box" v-if="checkAllIs(ite)">
                          <v-checkbox
                            v-model="ite.all"
                            :disabled="!item.has || !ite.has || handerState ==2"
                            @change="changeAllcheck(ite.all,ite)"
                            :label="`全选`"
                          ></v-checkbox>
                          <v-checkbox
                            v-for="(its,ind) in ite.childrenList" :key="inde+ind"
                            v-model="its.has"
                            v-show="its.type ==='component'"
                            :disabled="!item.has || !ite.has || handerState ==2"
                            :label="its.name"
                            @change="changeCheckAll(its)"
                          ></v-checkbox>
                        </div>
                        <div v-else class="checks-box power-block-border" style="opacity: 0;">
                          <v-checkbox
                            :label="`空格`"
                          ></v-checkbox>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-card-text>
    </v-card>
    <div class="flex-right bottom-btn">
      <v-btn
        class="btn-item mx-2"
        outlined
        @click="resetData"
      >
        取消
      </v-btn>
      <v-btn
        v-show="handerState !==2"
        color="primary"
        class="me-3"
        @click="addRoleSubmit()"
      >
        确定
      </v-btn>
    </div>

    <v-dialog
      v-if="dialogIs"
      v-model="dialogIs"
      width="620"
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          设置字段权限（{{ fieldTitle }}）
        </v-card-title>

        <div class="dialog-filedbox">
          <div class="power-box">
            <div class="power-tit">
              <div class="power-first">
                <div class="power-block">全选</div>
              </div>
              <div class="power-four">
                <div class="power-block">
                  <div class="checks-box">
                    <v-checkbox
                      v-model="filedDialogLook"
                      :label="`查看`"
                      :disabled="handerState ==2"
                      @change="changefiledDialogLook(filedDialogLook,'查看')"
                    ></v-checkbox>
                    <v-checkbox
                      v-model="filedDialogEdit"
                      :label="`编辑`"
                      :disabled="handerState ==2"
                      @change="changefiledDialogLook(filedDialogEdit,'编辑')"
                    ></v-checkbox>
                    <v-checkbox
                      v-model="filedDialogExport"
                      :label="`导出`"
                      :disabled="handerState ==2"
                      @change="changefiledDialogLook(filedDialogExport,'导出')"
                    ></v-checkbox>
                  </div>
                </div>
              </div>
            </div>
            <div class="power-des" v-for="(item,index) in operateField" :key="index" v-show="item.type !='component'">
              <div class="power-first">
                <div class="power-block">
                  {{ item.name }}
                </div>
              </div>
              <div class="power-four">
                <div class="power-block">
                  <div class="checks-box">
                    <v-checkbox
                      v-for="(its,ind) in item.childrenList"
                      :key='ind'
                      v-show="its.type =='oper'"
                      v-model="its.has"
                      :disabled="handerState ==2"
                      :label="its.name"
                    ></v-checkbox>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            outlined
            @click="dialogIs = false"
          >
            取消
          </v-btn>
          <v-btn
            v-show="handerState !==2"
            color="primary"
            dark
            class="mr-3"
            @click="operateFieldDialog"
          >
            确定
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-navigation-drawer>
</template>

<script>

import {
  ref, watch, onMounted,
} from '@vue/composition-api/dist/vue-composition-api'
import { emailValidator, required } from '@core/utils/validation'
import { toastSuccess, toastInfo, modalFail } from '@core/utils/prompt'
import { getAppMenuList, saveOrUpdateRole } from '@/api/groupAuth'
import { getAllChildren } from '@/utils/handerData'

export default {
  model: {
    prop: 'isAddSidebarActive',
    event: 'update:is-add-sidebar-active',
  },
  props: {
    isAddSidebarActive: {
      type: Boolean,
      required: true,
    },
    currItem: {
      type: Object,
      default: () => ({}),
    },
    handerState: {
      type: Number,
      required: true,
    },
    appId: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const menuData = ref([])
    const active = ref([])
    const open = ref([])
    const topTitle = ref('')
    const powerList = ref([])
    const fieldTitle = ref('')
    const operateFieldIndex = ref(
      {
        index: '',
        inde: '',
      },
    )
    const operateField = ref([])
    const filedDialogLook = ref(false)
    const filedDialogEdit = ref(false)
    const filedDialogExport = ref(false)

    const dialogIs = ref(false)

    const form = ref(null)
    const valid = ref(false)

    const submitData = ref({
      name: null,
      remark: null,
    })

    const validate = () => {
      form.value.validate()
    }
    const resetForm = () => {
      form.value.reset()
    }

    const resetData = () => {
      resetForm()
      emit('update:is-add-sidebar-active', false)
    }
    const changeAllcheck = (value, row) => {
      row.childrenList.forEach(item => {
        item.has = value
        if (item.type === 'component') {
          item.has = value
        }
      })
    }

    const firstChange = row => {
      if (!row.has && row.childrenList && row.childrenList.length > 0) {
        row.childrenList.forEach(item => {
          item.all = null
          item.has = null
        })
      }
    }
    const secondChange = row => {
      if (!row.has) {
        row.all = null
        changeAllcheck(false, row)
      }
    }
    const secondOperate = (row, index, inde) => {
      fieldTitle.value = row.name
      operateField.value = JSON.parse(JSON.stringify(row.childrenList))
      dialogIs.value = true
      operateFieldIndex.value.index = index
      operateFieldIndex.value.inde = inde
    }
    const operateFieldDialog = () => {
      powerList.value[operateFieldIndex.value.index].childrenList[operateFieldIndex.value.inde].childrenList = JSON.parse(JSON.stringify(operateField.value))
      dialogIs.value = false
    }
    const changefiledDialogLook = (val, value) => {
      operateField.value.forEach(item => {
        if (item.type !== 'component' && item.childrenList) {
          item.childrenList.forEach(ite => {
            if (ite.name.indexOf(value) > -1) {
              ite.has = val
            }
          })
        }
      })
    }

    const changeCheckAll = row => {
      row.all = true
      row.childrenList.forEach(item => {
        if (item.type === 'component' && !item.has) {
          row.all = false
        }
      })
    }
    const checkAllFieldIs = row => {
      let AllIs = false
      if (row.childrenList) {
        row.childrenList.forEach(item => {
          if (item.type === 'field') {
            AllIs = true
          }
        })
      }
      // eslint-disable-next-line newline-before-return
      return AllIs
    }
    const checkAllIs = row => {
      let AllIs = false
      if (row.childrenList) {
        row.childrenList.forEach(item => {
          if (item.type === 'component') {
            AllIs = true
          }
        })
      }
      // eslint-disable-next-line newline-before-return
      return AllIs
    }

    const getAppMenuLists = async () => {
      try {
        const parms = {
          appId: props.appId,
          roleId: props.currItem.roleId ? props.currItem.roleId : '',
        }
        if (props.handerState !== 0) {
          submitData.value.name = props.currItem.name
          submitData.value.remark = props.currItem.remark
          parms.roleId = props.currItem.id
        }

        const { data: { status, message: messageText, data } } = await getAppMenuList(parms)
        if (status === 200) {
          console.log('datas ::', data)
          powerList.value = data
        } else {
          toastInfo(messageText)
        }
      } catch (err) {
        modalFail(err.response.data.message)
      }
    }

    const addOrUpdateRole = async () => {
      const newList = getAllChildren(powerList.value)
      const newlists = newList.filter(item => item.has)
      const appRoleMenuVoList = []
      newlists.forEach(item => {
        const items = {
          appId: props.appId,
          menuId: 0,
        }
        if (props.handerState !== 0) {
          items.roleId = props.currItem.id
        }
        if (item.has) {
          items.menuId = item.id
          appRoleMenuVoList.push(items)
        }
      })
      try {
        const parms = {
          appRoleVo: {
            appId: props.appId,
            name: submitData.value.name,
            remark: submitData.value.remark,
          },
          appRoleMenuVoList,
        }
        console.log('::', powerList.value)
        if (props.handerState !== 0) {
          parms.appRoleVo.id = props.currItem.id
        }

        const { data: { status, message: messageText } } = await saveOrUpdateRole(parms)
        if (status === 200) {
          if (topTitle.value === '添加角色') {
            toastSuccess('成功添加新角色')
          } else {
            toastSuccess('更新角色成功')
          }
          emit('update:is-add-sidebar-active', false)
          emit('refetch-data', false)
          resetForm()
        } else {
          toastInfo(messageText)
        }
      } catch (err) {
        modalFail(err.response.data.message)
      }
    }

    const addRoleSubmit = () => {
      if (valid.value) {
        addOrUpdateRole()
      } else {
        validate()
      }
    }

    const namehValidator = value => {
      const regex = /^[A-Za-z0-9\u4e00-\u9fa5]+$/
      // eslint-disable-next-line newline-before-return
      return (value && regex.test(value) && value.length <= 20 ? true : '长度至多20字符，仅限输入汉字、字母和数字')
    }

    const remarkValidator = value => (value && value.length <= 100 ? true : '长度至多100字符')

    watch(dialogIs, value => {
      // submitData.value.user.joinTime = date.value
      if (!value) {
        filedDialogLook.value = false
        filedDialogEdit.value = false
        filedDialogExport.value = false
      }
    })

    watch(active, () => {
      if (active.value.length) {
        // eslint-disable-next-line prefer-destructuring
        // submitData.value.user.deptIdList = JSON.stringify({ deptIdList: active.value[0].deptId })
      }
    })

    onMounted(() => {
      getAppMenuLists()
      if (props.handerState === 2) {
        topTitle.value = '查看角色'
      }
      if (props.handerState === 1) {
        topTitle.value = '编辑角色'
      }
      if (props.handerState === 0) {
        topTitle.value = '添加角色'
      }
    })

    return {
      menuData,

      valid,
      validate,
      addRoleSubmit,
      submitData,
      resetData,
      form,
      active,
      open,

      // validation
      required,
      emailValidator,
      topTitle,
      powerList,
      firstChange,
      secondChange,
      secondOperate,
      dialogIs,
      operateField,
      operateFieldDialog,
      fieldTitle,
      operateFieldIndex,
      filedDialogLook,
      filedDialogEdit,
      filedDialogExport,
      changefiledDialogLook,
      changeAllcheck,
      changeCheckAll,
      checkAllFieldIs,
      checkAllIs,
      getAppMenuLists,
      addOrUpdateRole,
      getAllChildren,
      namehValidator,
      remarkValidator,
    }
  },
}
</script>

<style scoped>
.dialog-mains{
  background:rgb(245,246,247) ;
}
.dialog-top-title{
  line-height: 60px;
  background: #fff;
  position: relative;
  padding-left: 30px;
  padding-right: 20px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 600;
  font-size: 20px;
  color: #000000;
  text-align: left;
  font-style: normal;
  justify-content: space-between;
}
.main-boxs{
  padding: 20px;
  margin: 20px 0 0 0;
  background: #fff;
  min-height: 800px;
}
.add-title{
  font-weight: 500;
  font-size: 16px;
  color: rgba(0,0,0,0.85);
  line-height: 24px;
  position: relative;
  padding-left: 10px;
  margin-bottom: 10px;
}
.add-title::before{
  content: "";
  width: 4px;
  height: 16px;
  position: absolute;
  left: 0px;
  top: 4px;
  background: #9155FD;
}
.power-box{
  .power-tit{
    background: #F7F9FB;
    display: flex;
  }
  .power-des{
    display: flex;
  }
  .power-first{
    flex: 0 0 160px;
    /* border: 1px solid #E7ECF0; */
    border-left:1px solid #E7ECF0;
    border-bottom: 1px solid #E7ECF0;
    display: flex;
    align-items: center;
    /* justify-content: center; */
  }
  .power-secondthree{
    flex: 0 0 760px;
    border-left:1px solid #E7ECF0;
    border-bottom: 1px solid #E7ECF0;
    .power-second{
      border: 0px;
    }
    .power-three{
      border: 0px;
      border-left: 1px solid #E7ECF0;
    }
  }
  .power-secondthree-box{
    flex: 0 0 760px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #E7ECF0;
    border-right: 1px solid #E7ECF0;
  }
  .power-secondthree-box:last-child{
    border-bottom: 0px;
  }
  .power-second{
    flex: 0 0 320px;
    border-left:1px solid #E7ECF0;
    border-bottom: 1px solid #E7ECF0;
  }
  .power-three{
    flex: 0 0 440px;
    border-left:1px solid #E7ECF0;
    border-bottom: 1px solid #E7ECF0;
  }
  .power-four{
    flex: 0 0 440px;
    border-left:1px solid #E7ECF0;
    border-bottom: 1px solid #E7ECF0;
  }
  .power-block{
    line-height: 44px;
    padding-left: 20px;
  }
  .power-block-border{
    /* border-bottom: 1px solid #E7ECF0;
    margin-bottom: -1px; */
  }
  .v-messages{
    min-height: 8px;
  }
  .checks-box{
    display: flex;
    margin-top: 4px;
    .v-input .v-label {
        top: 4px;
        padding-right: 5px;
        margin-left: -4px;
    }
    .v-input--selection-controls{
      margin-top: 2px;
      padding-top: 2px;
      /* height: 40px; */
      line-height: 40px;
    }
  }
  .second-field{
    margin-top: -5px;
    cursor: pointer;
    font-size: 14px;
    color: #9155FD;
  }
}
.power-mains{
  border-top:1px solid #E7ECF0;
  border-right: 1px solid #E7ECF0;
}
.dialog-filedbox{
  margin-left: 10px;
  margin-right: 10px;
  border-top:1px solid #E7ECF0;
  border-right: 1px solid #E7ECF0;
}
.flex-right{
  text-align: right;
  flex-direction: row-reverse;
}
.bottom-btn{
  background: #fff;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-right: 20px;
}

.power-box{
    & .checks-box {
        .v-input--selection-controls {
            margin-top: 2px;
            padding-top: 2px;
            /* height: 40px; */
            line-height: 40px;
            min-width: 100px;
        }
    }
}
.power-box{
    .checks-box {
        display: flex;
        margin-top: 4px;
        flex-wrap: wrap;
    }
}

</style>
<style>
.checks-box{
  .v-input__slot{
    margin-bottom: 0px;
  }
}
</style>
