import { ref, watch } from '@vue/composition-api'

import store from '@/store'
import { modalFail } from '@core/utils/prompt'

export default function userList() {
  const tableColumns = [
    { value: 'name', text: '员工名', sortable: false },
    { value: 'mobile', text: '手机号', sortable: false },
    { value: 'title', text: '职位', sortable: false },
    { value: 'employeeStatus', text: '状态', sortable: false },
    { value: 'actions', text: '操作', sortable: false },
  ]
  const totalLists = ref(0)
  const userName = ref('')
  const userPhone = ref('')
  const deptId = ref('')
  const corpId = ref('')
  const queueList = ref([])
  const options = ref({
    sortBy: ['createdAt'],
    sortDesc: [true],
  })
  const loading = ref(false)

  const fetchStaff = () => {
    store
      .dispatch('app-user/fetchStaff', {
        name: userName.value,
        mobile: userPhone.value,
        deptId: deptId.value,
        corpId: corpId.value,
        pageSize: options.value.itemsPerPage,
        page: options.value.page,
        sortBy: options.value.sortBy[0],
        sortDesc: options.value.sortDesc[0],
      })
      .then(response => {
        const { data, total } = response.data
        queueList.value = data
        totalLists.value = total
        loading.value = false
      })
      .catch(error => {
        modalFail(error.response.data.message)
      })
  }

  watch(options, () => {
    loading.value = true
    fetchStaff()
  })

  watch([deptId, corpId, userName, userPhone], () => {
    options.value.page = 1
    loading.value = true
    fetchStaff()
  })

  const resolveStatusVariant = employeeStatus => {
    if (employeeStatus === 2) return 'primary'
    if (employeeStatus === 3) return 'success'
    if (employeeStatus === 5) return 'warning'

    return 'primary'
  }

  const resolveStatusText = employeeStatus => {
    if (employeeStatus === 2) return '试用期'
    if (employeeStatus === 3) return '正式'
    if (employeeStatus === 5) return '待离职'

    return '未录入'
  }

  const resolveSexText = sex => {
    if (sex === 0) return '男'
    if (sex === 1) return '女'

    return '未录入'
  }

  return {
    fetchStaff,
    tableColumns,
    totalLists,
    userName,
    userPhone,
    deptId,
    corpId,
    queueList,
    options,
    loading,

    resolveStatusVariant,
    resolveStatusText,
    resolveSexText,
  }
}
