<template>
  <v-card>
    <v-card-title>
      <span class="headline">{{ query.type==='add'?'新增':'编辑' }}业务架构</span>
      <v-spacer></v-spacer>
      <v-btn
        class="mt-n1"
        fab
        dark
        small
        color="primary"
        :to="{name:'businessFrame-list'}"
      >
        <v-icon>mdi-arrow-u-left-top</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text
      class="pl-10 pr-10"
    >
      <div style="width: 50%">
        <v-form
          ref="form"
          v-model="valid"
          @submit.prevent="editPartTime"
        >
          <v-text-field
            v-model="submitData.name"
            outlined
            dense
            clearable
            label="组织名称"
            placeholder="请输入组织名称"
            hide-details="auto"
            class="mb-6"
            :rules="[required]"
          ></v-text-field>
          <v-text-field
            v-model="submitData.parentName"
            outlined
            dense
            disabled
            label="上级架构"
            hide-details="auto"
            class="mb-6"
            :rules="[required]"
          ></v-text-field>
          <v-text-field
            v-model="submitData.jobName"
            outlined
            dense
            clearable
            label="负责岗位名"
            hide-details="auto"
            class="mb-6"
            :rules="[required]"
          ></v-text-field>
          <span>关联钉钉员工</span>
          <v-container
            class="overflow-auto rounded-lg"
            style="height: 300px;border: 1px solid #595472"
            :style="`${$vuetify.theme.isDark === true?'background-color: #312D4B;border: 1px solid #595472':'background-color: #F4F5FA;border: 1px solid #DCDADE'}`"
          >
            <vxe-table
              :v-if="dingData.length > 0 && show"
              :data="dingData"
              :row-config="{keyField: 'key'}"
              :scroll-x="{gt: 10000}"
              :show-header="false"
              :tree-config="{transform: true, accordion: true, iconOpen: '', iconClose: '', rowField: 'deptId', parentField: 'parentId'}"
              show-overflow
              :empty-text="'暂无数据'"
              :checkbox-config="{labelField: 'name', highlight: true, checkRowKeys: selectOptions}"
              @checkbox-change="selectChangeEvent"
            >
              <vxe-column
                type="checkbox"
                tree-node
              >
              </vxe-column>
            </vxe-table>
          </v-container>
          <v-autocomplete
            v-model="submitData.dingtalkUserId"
            :items="userOptions"
            label="负责人"
            placeholder="请选择负责人"
            item-text="name"
            item-value="userId"
            :no-data-text="'暂无可选择的员工，请先关联钉钉组织！'"
            dense
            outlined
            hide-details
            class="mt-6 mb-6"
          ></v-autocomplete>
        </v-form>
      </div>
      <div v-if="query.type==='add'">
        <h3>基础角色：此处为所有账号设置初始角色，管理角色可稍后为部分员工账号单独配置</h3>
        <div
          v-for="i in rolesData"
          :key="i.id"
          class="mt-1"
        >
          <div>
            <h3>
              <v-icon
                x-large
                color="primary"
              >
                mdi-circle-small
              </v-icon>{{ i.name }}:
            </h3>
            <v-row>
              <div
                v-for="n in i.appRoles"
                :key="n.id"
                class="pl-10 mt-1"
              >
                <v-checkbox
                  v-model="n.selected"
                  :label="n.name"
                  @click="showTips"
                ></v-checkbox>
              </div>
            </v-row>
          </div>
          <v-divider class="mt-4"></v-divider>
        </div>
      </div>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <div class="d-flex mt-4 mr-4">
        <v-btn
          color="primary"
          class="me-3"
          @click="editPartTime()"
        >
          提交
        </v-btn>
        <v-btn
          class="mx-2"
          type="reset"
          outlined
          :to="{name:'businessFrame-list'}"
        >
          取消
        </v-btn>
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
import VXETable from 'vxe-table'
import 'vxe-table/lib/style.css'
import store from '@/store'
import {
  onUnmounted, ref, watch,
  computed,
} from '@vue/composition-api/dist/vue-composition-api'
import { emailValidator, required } from '@core/utils/validation'
import { modalFail, toastSuccess } from '@core/utils/prompt'
import rootThemeClasses from '@core/utils/vuetify'
import { useRouter } from '@core/utils'
import Vue from 'vue'
import storeModule from './queueStoreModule'

Vue.use(VXETable)

export default {
  props: {
  },
  setup(props, { emit }) {
    const WORK_STORE_MODULE_NAME = 'business-list'

    // Register module
    if (!store.hasModule(WORK_STORE_MODULE_NAME)) store.registerModule(WORK_STORE_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(WORK_STORE_MODULE_NAME)) store.unregisterModule(WORK_STORE_MODULE_NAME)
    })

    const { route } = useRouter()
    const { router } = useRouter()
    const { query } = route.value
    const form = ref(null)
    const valid = ref(false)
    const propsData = computed(() => JSON.parse(query.item))
    const active = ref([])
    const open = ref([])
    const dingData = ref([])
    const selectOptions = ref([])
    const userOptions = ref([])
    const rolesData = ref([])
    const show = ref(false)
    const blankData = {
      id: '',
      name: '',
      parentName: '',
      jobName: '',
      level: '',
      dingtalkUserId: '',
      users: [],
      appIdMap: [],
    }

    const submitData = ref(JSON.parse(JSON.stringify(blankData)))

    const validate = () => {
      form.value.validate()
    }

    const resetData = () => {
      emit('update:is-edit-dialog-active', false)
    }

    const editPartTime = () => {
      if (query.type === 'add') {
        submitData.value.id = propsData.value.id
        submitData.value.level = propsData.value.level
        const obj = {}
        rolesData.value.forEach(i => {
          obj[i.appId] = []
          i.appRoles.forEach(a => {
            if (a.selected === true) {
              obj[i.appId].push(a.id)
            }
          })
        })
        submitData.value.appIdMap = obj
        submitData.value.users = userOptions.value
        console.log(submitData.value)
        if (valid.value) {
          store.dispatch('business-list/addNext', submitData.value).then(response => {
            if (response.data.status === 200) {
              toastSuccess('成功新增架构')
              router.push({ name: 'businessFrame-list' })
            }
          }).catch(error => {
            modalFail(error.response.data.message)
          })
        } else {
          validate()
        }
      } else {
        submitData.value.users = userOptions.value
        delete submitData.value.level
        delete submitData.value.appIdMap
        delete submitData.value.parentName
        console.log(submitData.value)
        if (valid.value) {
          store.dispatch('business-list/updateFrame', submitData.value).then(response => {
            if (response.data.status === 200) {
              toastSuccess('成功编辑架构')
              router.push({ name: 'businessFrame-list' })
            }
          }).catch(error => {
            modalFail(error.response.data.message)
          })
        } else {
          validate()
        }
      }
    }

    const fetchDingLists = () => {
      store.dispatch('business-list/fetchDingLists')
        .then(response => {
          dingData.value = response.data.data
        })
      store.dispatch('business-list/fetchRolesLists')
        .then(response => {
          rolesData.value = response.data.data
        })
    }
    fetchDingLists()

    const selectChangeEvent = select => {
      userOptions.value = []
      select.records.forEach(i => {
        if (!i.corpId) {
          userOptions.value.push(i)
        }
      })
    }

    const showTips = () => {
      if (userOptions.value.length === 0) {
        modalFail('您还未关联钉钉员工，设置的初始角色无效，请先选择钉钉组织员工！')
      }
    }

    const showDetails = () => {
      show.value = false
      if (query.type === 'add') {
        submitData.value.parentName = propsData.value.name
        show.value = true
      } else {
        submitData.value.parentName = query.name
        store.dispatch('business-list/fetchDetailsByFrame', { id: query.id })
          .then(response => {
            submitData.value.id = response.data.data.id
            submitData.value.name = response.data.data.name
            submitData.value.parentName = response.data.data.parentName
            submitData.value.jobName = response.data.data.jobName
            submitData.value.dingtalkUserId = response.data.data.dingtalkUserId
            selectOptions.value = response.data.data.keys
            show.value = true
          })
      }
    }

    showDetails()

    watch(() => dingData.value,
      () => {
        selectOptions.value.forEach(i => {
          userOptions.value = userOptions.value.concat(dingData.value.find(n => n.key === i && !n.corpId))
        })
      })

    return {
      propsData,
      valid,
      validate,
      editPartTime,
      submitData,
      resetData,
      dingData,
      rolesData,
      selectOptions,
      userOptions,
      form,
      active,
      open,
      query,
      showTips,
      selectChangeEvent,
      showDetails,
      rootThemeClasses,
      show,

      // validation
      required,
      emailValidator,
    }
  },
}
</script>

<style lang="scss" scoped>
@import 'vxe-table/styles/variable.scss';
</style>

<style lang="scss">
@import 'vxe-table/styles/variable.scss';

.vxe-table--render-default .vxe-body--row.row--checked{
  background-color: #99C2DF !important;
}

.vxe-table--render-default .is--checked.vxe-cell--checkbox, .vxe-table--render-default .is--indeterminate.vxe-cell--checkbox{
 color: #736C7C !important;
}

$vxe-font-color: #736C7C;
$vxe-primary-color: #736C7C;
$vxe-table-border-color: transparent;
$vxe-table-font-color: $vxe-font-color;
$vxe-table-body-background-color: transparent;
$vxe-table-row-hover-background-color: #343d55;
$vxe-table-header-background-color: #161d31;
$vxe-table-header-font-color: $vxe-font-color;

@import 'vxe-table/styles/table.scss';
@import 'vxe-table/styles/header.scss';
</style>
