<script>
import { modalFail, toastFail, modalSuccess, toastInfo } from '@core/utils/prompt'
import AppView from '@/components/AppView.vue'
import { onMounted, ref, reactive } from '@vue/composition-api'
import { workFlowLogin } from '@/views/workFlow/service'
import { getAppList } from '@/views/processManagement/service'
import { deptList, userList, syncUserAndDept } from '@/api/groupAuth'
import Pagination from '@/components/Pagination.vue'
import TablePro from '@/components/TablePro.vue'
import toConfigure from './toConfigure.vue'
import userDetailsDialog from './userDetailsDialog.vue'
import LoaderDialog from './Loader.vue'

export default {
  name: 'List',
  components: { TablePro, Pagination, AppView, toConfigure, userDetailsDialog, LoaderDialog },
  setup() {
    const treeLoading = ref(false)
    const leftItems = ref([])
    const isLoaderActive = ref(false)
    const defaultExpandKeys = ref([])

    const tableColumns = ref([
      { text: '员工名', value: 'name' },
      { text: '手机号', value: 'mobile' },
      { text: '职位', value: 'title' },
      { text: '入职日期', value: 'joinTime' },
      { text: '系统及角色', value: 'role', width: 170 }, // 新增
      { text: '管理范围', value: 'config', width: 170 }, // 新增
      { text: '操作', value: 'operation', width: 100 },
    ])
    const tableData = ref([])
    const corpIdItem = ref({})
    const formData = reactive({
      name: '',
      mobile: '',
    })
    const pageOptions = reactive({
      page: 1,
      pageSize: 10,
      total: 0,
    })
    const loading = ref(false)

    const init = async () => {
      loading.value = true
      const parms = {
        ...pageOptions,
        total: undefined,
        ...formData,
        corpId: corpIdItem.value.corpId,
        deptId: corpIdItem.value.deptId,
      }
      try {
        const { data: { status, message: messageText, data } } = await userList(parms)
        if (status === 200) {
          tableData.value = data.userVoList
          pageOptions.total = data.total
          loading.value = false
        } else {
          toastInfo(messageText)
        }
      } catch (err) {
        modalFail(err.response.data.message)
      }
    }
    const appList = ref([])
    const formList = ref([])
    function getListApp() {
      getAppList().then(res => {
        if (res.status === 200) {
          appList.value = (res.data || []).map(item => {
            const List = (item.items || []).map(value => ({ ...value, text: `${item.name}-${value.formName}`, value: value.formName }))
            formList.value.push(...List)

            return {
              ...item,
              text: item.name,
              value: item.id,
            }
          })
        }
      })
    }
    function handleSizeChange(val) {
      pageOptions.page = 1
      pageOptions.pageSize = val
      init()
    }
    function handleCurrentChange(val) {
      pageOptions.page = val
      init()
    }
    getListApp()

    onMounted(() => {
      workFlowLogin().then(({ data }) => {
        localStorage.setItem('flowToken', data.accessToken)
        const time = setTimeout(() => {
          init()
          getListApp()
          clearTimeout(time)
        }, 1000)
      })
      // eslint-disable-next-line no-use-before-define
      getDept()
    })

    // 查询
    function search() {
      pageOptions.page = 1
      init()
    }

    // 重置
    function reset() {
      formData.name = ''
      formData.mobile = ''
      pageOptions.page = 1
      init()
    }

    const itemValue = ref({})
    const isAddSidebarActive = ref(false)
    const isUserDetailsDialog = ref(false)

    const showToCofigure = item => {
      itemValue.value = item
      isAddSidebarActive.value = true
    }

    const showUserDetails = item => {
      itemValue.value = item
      isUserDetailsDialog.value = true
    }
    const handerShowToCofigure = value => {
      isUserDetailsDialog.value = value
      showToCofigure(itemValue.value)
    }

    const updateAddConfigure = () => {
      init()
    }

    const handerNav = row => {
      console.log(row)
    }

    const getDept = async () => {
      try {
        const { data: { status, message: messageText, data } } = await deptList({})
        if (status === 200) {
          leftItems.value = data
          defaultExpandKeys.value.push(data[0].id)
        } else {
          toastInfo(messageText)
        }
      } catch (err) {
        modalFail(err.response.data.message)
      }
    }

    const changeNav = item => {
      corpIdItem.value = item
      handleSizeChange()
    }

    const handerSyncUserAndDept = async () => {
      isLoaderActive.value = true
      try {
        const { data: { status, message: messageText } } = await syncUserAndDept({})
        isLoaderActive.value = false
        if (status === 200) {
          isLoaderActive.value = false
          modalSuccess('同步成功')
          getDept()
        } else {
          toastFail(messageText)
        }
      } catch (err) {
        modalFail(err.response.data.message)
      }
    }
    const handerList = (row, key, type) => {
      // eslint-disable-next-line one-var, one-var-declaration-per-line, prefer-const
      let desLists = [], newList = {}, desList = [], des = ''
      if (row && row.length > 0) {
        row.forEach(item => {
          if (!newList[item.appName]) {
            newList[item.appName] = item[key]
          } else {
            newList[item.appName] = `${newList[item.appName]}、${item[key]}`
          }
        })
        Object.keys(newList).forEach(key => {
          desList.push(`${key}:${newList[key]}`)
        })
        desLists = desList.map((item, index) => ({ id: index, name: item }))
        des = desList.join(';')
      }
      if (type === 1) {
        return des
      // eslint-disable-next-line no-else-return
      } else {
        return desLists
      }
    }

    return {
      pageOptions,
      formData,
      tableColumns,
      tableData,
      loading,
      appList,
      formList,
      handleSizeChange,
      handleCurrentChange,
      search,
      reset,

      isLoaderActive,
      leftItems,
      treeLoading,
      itemValue,
      isAddSidebarActive,
      showToCofigure,
      isUserDetailsDialog,
      showUserDetails,
      handerShowToCofigure,
      updateAddConfigure,
      handerNav,
      handerSyncUserAndDept,
      getDept,
      changeNav,
      corpIdItem,

      defaultExpandKeys,
      handerList,

    }
  },
}
</script>

<template>
  <div class="main-content">
    <loader-dialog
      :is-loader-active.sync="isLoaderActive"
    />
    <div class="content-left">
      <vxe-table
        :data="leftItems"
        :scroll-x="{gt: 10000}"
        :show-header="false"
        :tree-config="{transform: true, accordion: true, iconOpen: '', iconClose: '', rowField: 'deptId',expandRowKeys:defaultExpandKeys}"
        show-overflow
        :empty-text="'暂无数据'"
        :loading="treeLoading"
        :row-config="{keyField:'id'}"
      >
        <vxe-column tree-node>
          <template #default="{ row }">
            <span>
              <a
                class="ml-1"
                @click="changeNav(row)"
              >{{ row.name }}</a>
            </span>
          </template>
        </vxe-column>
      </vxe-table>
    </div>
    <div class="content-right">
      <app-view>
        <template #header>
          <div class="search-boxs" style="gap: 12px">
            <div style="width: 230px">
              <v-text-field
                v-model="formData.name"
                outlined
                dense
                clearable
                label="员工姓名"
                placeholder="员工姓名"
                @keyup.enter.native="search"
                hide-details="auto"
              ></v-text-field>
            </div>
            <div style="width: 230px">
              <v-text-field
                v-model="formData.mobile"
                outlined
                dense
                clearable
                label="手机号"
                placeholder="手机号"
                @keyup.enter.native="search"
                hide-details="auto"
              ></v-text-field>
            </div>
            <div class="search-btn">
              <v-btn outlined @click="reset">
                重置
              </v-btn>
              <v-btn color="primary" @click="search">
                查询
              </v-btn>
            </div>
          </div>
        </template>
        <v-divider></v-divider>
        <div class="depart-btn">
          <v-btn color="primary" @click="handerSyncUserAndDept">
            同步部门和用户
          </v-btn>
        </div>
        <table-pro
          :columns="tableColumns"
          :data="tableData"
          :loading="loading"
        >
          <template #name="{item}">
            <div class="d-flex align-center">
              <v-avatar
                :color="item.avatar ? '' : 'primary'"
                :class="item.avatar ? '' : 'v-avatar-light-bg primary--text'"
                size="32"
              >
                <v-img
                  v-if="item.avatar"
                  :src="item.avatar"
                ></v-img>
                <span v-else>{{ item.name?item.name.slice(1,3).toUpperCase():'' }}</span>
              </v-avatar>
              <div class="d-flex flex-column ms-3">
                <span class="d-block font-weight-semibold text--primary text-truncate">{{ item.name }}</span>
              </div>
            </div>
          </template>
          <template #role="{item}">
            <div class="d-flex align-center" v-if="handerList(item.appRoleVoList,'name',1).length > 13">
              <v-tooltip
                right
              >
                <template
                  #activator="{ on, attrs }"
                >
                  <div
                    v-bind="attrs"
                    class="text--link more-des"
                    v-on="on"
                  >
                    <div v-for="(ite,index) in handerList(item.appRoleVoList,'name')" :key="index">{{ ite.name }}</div>
                  </div>
                </template>
                <div>
                  <div >
                    <div v-for="(ite,index) in handerList(item.appRoleVoList,'name')" :key="index">{{ ite.name }}</div>
                  </div>
                </div>
              </v-tooltip>
            </div>
            <span v-if="handerList(item.appRoleVoList,'name',1).length <= 13">{{ handerList(item.appRoleVoList,'name',1) }}</span>
          </template>
          <template #config="{item}">
            <div class="d-flex align-center" v-if="handerList(item.appUserDeptVoList,'name',1).length > 13">
              <v-tooltip
                right
              >
                <template
                  #activator="{ on, attrs }"
                >
                  <div
                    v-bind="attrs"
                    class="text--link more-des"
                    v-on="on"
                  >
                    <div v-for="(ite,index) in handerList(item.appUserDeptVoList,'name')" :key="index">{{ ite.name }}</div>
                  </div>
                </template>
                <div>
                  <div>
                    <div v-for="(ite,index) in handerList(item.appUserDeptVoList,'name')" :key="index">{{ ite.name }}</div>
                  </div>
                </div>
              </v-tooltip>
            </div>
            <span v-if="handerList(item.appUserDeptVoList,'name',1).length <= 13">{{ handerList(item.appUserDeptVoList,'name',1) }}</span>
          </template>
          <template #operation="{ item }">
            <span class="app-link" @click="showUserDetails(item)">查看</span>
            <span class="app-link" @click="showToCofigure(item)">配置</span>
          </template>
        </table-pro>
        <template #footer>
          <Pagination
            :page-index="pageOptions.page"
            :page-size="pageOptions.pageSize"
            :total="pageOptions.total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </template>
      </app-view>
      <to-configure
        v-if="isAddSidebarActive"
        :is-add-sidebar-active.sync="isAddSidebarActive"
        :item="itemValue"
        :left-items="leftItems"
        @refetch-data="updateAddConfigure"
      ></to-configure>
      <user-detailsDialog
        v-if="isUserDetailsDialog"
        :is-user-details-dialog.sync="isUserDetailsDialog"
        :item="itemValue"
        @handerShowToCofigure="handerShowToCofigure"
      ></user-detailsDialog>
    </div>
  </div>
</template>

<style lang='scss' scoped>
.main-content{
  display: flex;
}
.content-left{
  flex: 1;
  background: rgb(242,243,249);
  margin-right: 15px;
  height: 810px;
  overflow-y: auto;
}
.content-right{
  flex: 4;
}
.depart-btn{
  margin-bottom: 15px;
}
.search-boxs{
  position: relative;
  display: flex;
  width: 100%;
  .search-btn{
    position: absolute;
    right: 0px;
    top: 1px;
    display: flex;
    gap: 12px;
  }
}
.more-des{
  width: 170px;
  min-height: 78;
  line-height: 26px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>

<style lang="scss">
@import 'vxe-table/styles/variable.scss';

.hidden-about {
  /*强制文本在一行内显示*/
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
a{
  color: #736C7C;
}
.v-application a{
  color: #736C7C;
}
.vxe-body--row .row--current.row--hover a{
  color: black !important;
}
.vxe-body--row .row--current{
  background-color: #685fd4 !important;
}
.vxe-table--render-default .vxe-body--row.row--current.row--hover {
  background-color: #685fd4;
}

.vxe-table--render-default .vxe-body--row.row--current {
  background-color: #342A54 !important;
}

$vxe-font-color: #736C7C;
$vxe-primary-color: #28243D;
$vxe-table-border-color: transparent;
$vxe-table-font-color: $vxe-font-color;
$vxe-table-body-background-color: transparent;
$vxe-table-row-hover-background-color: #343d55;
$vxe-table-header-background-color: #161d31;
$vxe-table-header-font-color: $vxe-font-color;

@import 'vxe-table/styles/table.scss';
.app-content-container {
    background: #fff;
    margin-top: 10px;
}
</style>
