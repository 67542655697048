<template>
  <v-navigation-drawer
    :value="isAddSidebarActive"
    temporary
    touchless
    :right="!$vuetify.rtl"
    :width="$vuetify.breakpoint.smAndUp ? 850 : '100%'"
    app
    @input="(val) => $emit('update:is-add-sidebar-active', val)"
  >
    <v-card class="dialog-mains">
      <div class="dialog-top-title d-flex align-center">
        <span> 配置角色和管理范围({{ currentItem.name }})</span>
        <v-btn
          icon
          small
          @click="$emit('update:is-add-sidebar-active',false)"
        >
          <v-icon size="22">
            mdi-close
          </v-icon>
        </v-btn>
      </div>
      <v-card-text>
        <div class="configure-main">
          <div class="configure-left">
            <div
              v-for=" ( item, index) in systemList"
              class="configure-item"
              :class="{'left-actived':index ==activeIndex}"
              :key = "index"
              @click="handerLeft(item,index)"
            >{{ item.name }}</div>
          </div>
          <div class="configure-right">
            <div class="manage-title">
              角色
            </div>
            <div
              v-for="(item,index) in systemList"
              :key="index+'22'"
              style="max-height: calc(100% - 150px);overflow-y: auto;"
            >
              <div  v-for=" (ite,inde) in item.appRoleList" v-show="index === activeIndex" :key="inde" class="checks-box">
                <v-checkbox
                  v-model="ite.has"
                  :label="ite.name"
                ></v-checkbox>
              </div>
            </div>
            <v-divider></v-divider>
            <div class="manage-title">
              管理范围
            </div>
            <div class="managemenet">
              <v-text-field
                v-model="managementScope"
                :disabled="!managementScopeIs"
                outlined
                dense
                clearable
                :label="!managementScopeIs?'请先选择角色,再进行管理范围操作，':'管理范围'"
                placeholder="请选择"
                hide-details="auto"
                class="mb-6"
                @click="handerManage()"
              ></v-text-field>
            </div>
          </div>
        </div>

        <div class="flex-right dialog-bottom">
          <v-btn
            class="btn-item mx-2"
            outlined
            @click="handerClose()"
          >
            取消
          </v-btn>
          <v-btn
            color="primary"
            class="me-3"
            @click="addToConfigure()"
          >
            提交
          </v-btn>
        </div>
      </v-card-text>
    </v-card>

    <v-dialog
      v-if="dialogIs"
      v-model="dialogIs"
      width="860"
    >
      <v-card>
        <div class="dialog-top-title d-flex align-center">
          <span>管理范围</span>
          <v-btn
            icon
            small
            @click="dialogIs = false"
          >
            <v-icon size="22">
              mdi-close
            </v-icon>
          </v-btn>
        </div>
        <div class="div-divider"></div>

        <div class="dialog-range">
          <div class="range-left">
            <div class="manage-dialog-title">
              请选择部门
            </div>
            <vxe-table
              ref="tableRef"
              :data="scopeItems"
              :scroll-x="{gt: 10000}"
              :show-header="false"
              :tree-config="{transform: true, accordion: true, iconOpen: '', iconClose: '', rowField: 'deptId',expandRowKeys:defaultExpandKeys}"
              show-overflow
              :empty-text="'暂无数据'"
              :row-config="{keyField:'id'}"
              row-id="id"
              :checkbox-config="{labelField: 'name', checkStrictly: true, checkRowKeys: selectOptions}"
              @checkbox-change="selectChangeEvent"
            >
              <vxe-column type="checkbox" tree-node>
                <template #default="{ row }">
                  <span>
                    <a
                      class="ml-1 treefont15"
                    >{{ row.name }}</a>
                  </span>
                </template>
              </vxe-column>
              <vxe-column field="size" title="Size" width="26%" >
                <template #default="{ row }">
                  <div v-show="row.children && row.children.length > 0" class="select-check ">
                    <v-checkbox
                      v-model="row.has"
                      label="含下级"
                      @change='downClick(row)'
                    ></v-checkbox>
                  </div>
                </template>
              </vxe-column>
            </vxe-table>
          </div>

          <div class="range-right">
            <div class="manage-dialog-title">
              已选部门
            </div>
            <template v-if="!selection.length">
              <div>暂无</div>
            </template>
            <template v-else>
              <div
                v-for="(node,index) in selection"
                :key="index+node.id+node.name"
                class="range-select"
              >
                {{ node.name }}
                <span class="range-select-close" @click="handerDeleteRange(node)">x</span>
              </div>
            </template>
          </div>
        </div>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            outlined
            @click="dialogIs = false"
          >
            取消
          </v-btn>
          <v-btn
            color="primary"
            dark
            class="mr-3"
            @click="operateFieldDialog"
          >
            确定
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-navigation-drawer>
</template>

<script>

import {
  onUnmounted, ref, watch, onMounted, nextTick, computed,
} from '@vue/composition-api/dist/vue-composition-api'
import { emailValidator, required } from '@core/utils/validation'
import { modalFail, modalSuccess, toastInfo } from '@core/utils/prompt'
import { getAppRoleDeptList, updateAppRoleDept } from '@/api/groupAuth'

export default {
  model: {
    prop: 'isAddSidebarActive',
    event: 'update:is-add-sidebar-active',
  },
  props: {
    isAddSidebarActive: {
      type: Boolean,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    leftItems: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const dialogIs = ref(false)
    const currentItem = ref(props.item)
    const tableIs = ref(true)
    const tableRef = ref()
    const systemList = ref([])
    const roleList = ref([])
    const activeIndex = ref(0)
    const managementScope = ref('')
    const managementScopeList = ref([])
    const selectionType = ref('independent')
    const selection = ref([])
    const defaultExpandKeys = ref(props.leftItems && props.leftItems[0] && props.leftItems[0].id ? [props.leftItems[0].id] : [])
    const selectOptions = ref([])
    const scopeItems = ref(JSON.parse(JSON.stringify(props.leftItems)))

    const addToConfigure = async () => {
      try {
        const parms = {
          userId: currentItem.value.userId,
        }
        const appUserDeptVoList = []
        const appRoleList = []
        systemList.value.forEach(item => {
          if (item.appUserDeptVoList.length > 0) {
            item.appUserDeptVoList.forEach(ite => {
              const newItem = {
                corpId: ite.corpId,
                deptId: ite.deptId,
                userId: currentItem.value.userId,
                appId: item.appId,
                appName: item.name,
                name: ite.name,
              }
              appUserDeptVoList.push(newItem)
            })
          }
          if (item.appRoleList.length > 0) {
            item.appRoleList.forEach(ite => {
              if (ite.has) {
                const newite = {
                  roleId: ite.id,
                  userId: currentItem.value.userId,
                  appId: ite.appId,
                }
                appRoleList.push(newite)
              }
            })
          }
        })
        parms.appUserDeptVoList = appUserDeptVoList
        parms.appUserRoleVoList = appRoleList
        const { data: { status, message: messageText } } = await updateAppRoleDept(parms)
        if (status === 200) {
          modalSuccess('配置提交成功')
          emit('refetch-data', '')
          emit('update:is-add-sidebar-active', false)
        } else {
          toastInfo(messageText)
        }
      } catch (err) {
        modalFail(err.response.data.message)
      }
    }
    const handerClose = () => {
      emit('update:is-add-sidebar-active', false)
    }

    const startDetail = async () => {
      try {
        const parms = {
          userId: props.item.userId,
        }
        const { data: { status, message: messageText, data } } = await getAppRoleDeptList(parms)
        if (status === 200) {
          systemList.value = data.appVoList
          selection.value = []
          managementScopeList.value = JSON.parse(JSON.stringify(data.appVoList[0].appUserDeptVoList))
        } else {
          toastInfo(messageText)
        }
      } catch (err) {
        modalFail(err.response.data.message)
      }
    }

    const operateFieldDialog = () => {
      dialogIs.value = false
      managementScopeList.value = selection.value
      systemList.value[activeIndex.value].appUserDeptVoList = JSON.parse(JSON.stringify(managementScopeList.value))
    }

    const handerLeft = (item, index) => {
      activeIndex.value = index
      selection.value = []
      managementScopeList.value = JSON.parse(JSON.stringify(item.appUserDeptVoList))
    }
    const handerManage = () => {
      dialogIs.value = true
      selection.value = JSON.parse(JSON.stringify(managementScopeList.value))
    }

    const handerManagementScope = () => {
      dialogIs.value = true
    }

    const handerDeleteRange = row => {
      // selection.value = selection.value.filter(item => item.id !== row.id)
      selection.value = selection.value.filter(item => item.corpId + item.deptId !== row.corpId + row.deptId)
    }

    const resetTreeView = () => {
      selection.value = []
      scopeItems.value = []
      scopeItems.value = JSON.parse(JSON.stringify(props.leftItems))
    }
    const traverseTree = (nodes, callback) => { // 数组遍历处理
      nodes.forEach(node => {
        callback({ corpId: node.corpId, deptId: node.deptId, parentId: node.parentId, id: node.id, name: node.name })
        if (node.children && node.children.length) {
          traverseTree(node.children, callback)
        }
      })
    }
    const filterData = row => { // 过滤添加
      if (!selection.value.some(obj => obj.corpId + obj.deptId === row.corpId + row.deptId)) {
        selection.value.unshift(row)
      }
    }
    const downClick = row => {
      row.children.forEach(i => {
        i.has = row.has
      })
      if (row.has) {
        filterData(row)
        traverseTree(row.children, item => {
          filterData(item)
        })
      }
      if (!row.has) {
        const newList = []
        const checkList = []
        checkList.unshift(row)
        traverseTree(row.children, item => {
          checkList.unshift(item)
        })

        if (selection.value.length === 0) {
          selection.value = []
        } else {
          selection.value.forEach(item => {
            const exists = checkList.some(ite => ite.corpId + ite.deptId === item.corpId + item.deptId) // true 存在，false 不存在
            if (!exists) {
              newList.push(item)
            }
          })
        }
        selection.value = newList
      }
    }

    const selectChangeEvent = select => {
      selection.value = []
      const selectionList = []
      select.records.forEach(i => {
        if (i.corpId) {
          selectionList.unshift(i)
        }
      })
      selection.value = selectionList
    }
    const managementScopeIs = computed(() => {
      if (systemList.value.length > 0) {
        return systemList.value[activeIndex.value].appRoleList.some(item => item.has)
      }
    })

    watch(dialogIs, value => {
      if (!value) {
        resetTreeView()
      }
    })
    watch(selection, value => {
      const rows = []
      if (value.length > 0) {
        value.forEach(item => {
          scopeItems.value.forEach(ite => {
            if (ite.corpId + ite.deptId === item.corpId + item.deptId) {
              rows.push(ite)
            }
          })
        })
      }

      nextTick(() => {
        if (rows.length > 0 && tableRef.value) {
          tableRef.value.clearCheckboxRow()
          tableRef.value.setCheckboxRow(rows, true)
        } else if (tableRef.value) {
          tableRef.value.clearCheckboxRow()
        }
      })

      // xTables.setAllCheckboxRow(true)
    })

    watch(managementScopeList, value => {
      if (value && value.length > 0) {
        let texts = ''
        value.forEach(item => {
          // eslint-disable-next-line prefer-template
          texts = texts + ',' + item.name
        })
        managementScope.value = texts.slice(1, texts.length)
      } else {
        managementScope.value = ''
      }
    })
    watch(managementScope, value => {
      if (!value) {
        selection.value = []
        systemList.value[activeIndex.value].appUserDeptVoList = []
        managementScopeList.value = []
      }
    })

    onMounted(() => {
      startDetail()
    })
    onUnmounted(() => {
    })

    return {

      addToConfigure,
      handerClose,
      required,
      emailValidator,

      currentItem,
      dialogIs,
      operateFieldDialog,
      systemList,
      tableIs,
      roleList,
      activeIndex,
      handerLeft,
      handerManage,

      managementScope,
      managementScopeList,
      handerManagementScope,
      managementScopeIs,

      selectionType,
      selection,
      defaultExpandKeys,
      scopeItems,
      selectOptions,
      handerDeleteRange,
      resetTreeView,

      traverseTree,
      startDetail,

      selectChangeEvent,
      tableRef,
      downClick,
      filterData,
    }
  },
}
</script>

<style scoped>
.dialog-mains{
  background:rgb(245,246,247) ;
}
.dialog-top-title{
  line-height: 60px;
  background: #fff;
  position: relative;
  padding-left: 30px;
  padding-right: 20px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 600;
  font-size: 20px;
  color: #000000;
  text-align: left;
  font-style: normal;
  justify-content: space-between;
}
.configure-main{
  display: flex;
  height: calc(100vh - 170px);
  margin: 20px 0px;
  /* background: #fff; */
  border-radius: 3px;
  justify-content: space-between;
  .configure-left{
    flex:0 0 270px;
    border: 1px solid #E7ECF0;
    padding: 20px;
    background: #fff;
  }
  .configure-right{
    flex:0 0 520px;
    border: 1px solid #E7ECF0;
    padding: 20px;
    background: #fff;
  }
}

.checks-box{
    /* display: flex;
    margin-top: 4px; */
    .v-input .v-label {
        top: 4px;
        padding-right: 5px;
        margin-left: -4px;
    }
    .v-input--selection-controls{
      margin-top: 2px;
      padding-top: 2px;
      height: 40px;
    }
  }

.configure-item{
  font-size: 16px;
  font-weight: 500;
  line-height: 32px;
  cursor: pointer;
}
.left-actived{
  color: #9155fd;
}
.manage-title{
  font-size: 16px;
  color: #666;
  font-weight: 600;
  margin-bottom: 10px;
}
.manage-dialog-title{
  font-size: 16px;
  color: #666;
  font-weight: 600;
  margin-bottom: 10px;
  border-bottom:   1px solid rgba(94, 86, 105, 0.14);
  padding-left: 20px;
  line-height: 42px;
}

.dialog-range{
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  .row{
    margin: 0px;
  }
}
.range-select{
  line-height: 32px;
  padding-left: 7px;
  background: #f9f9f9;
  position: relative;
  margin-bottom: 8px;
  border-radius: 3px;
  .range-select-close{
    position: absolute;
    right: 5px;
    top: 5px;
    width: 20px;
    height: 20px;
    line-height: 20px;
    cursor: pointer;
    text-align: center;
  }
}
.range-left{
  position: relative;
  padding: 0px;
  border: 1px solid rgba(94, 86, 105, 0.14);
  width: 400px;
  margin-right: 20px;
  min-height: 300px;
}
.range-right{
  position: relative;
  padding: 0px;
  border: 1px solid rgba(94, 86, 105, 0.14);
  flex: 1;
  div{
    padding-left: 20px;
  }
}

.select-check{
  margin-top: -12px;
  .v-input .v-label {
    height: 20px;
    line-height: 20px;
    letter-spacing: normal;
    margin-top: 8px;
  }
  .v-messages{
    min-height: 0px;
  }
}
.select-check1{
  position: absolute;
  right: 9px;
  top: 35px;
}
.select-check2{
  position: absolute;
  right: 9px;
  top: 85px;
}
.select-check3{
  position: absolute;
  right: 9px;
  top: 131px;
}
.flex-right{
  text-align: right;
  flex-direction: row-reverse;
  background: #fff;
  padding-top: 10px;
  padding-bottom: 10px;
}
.div-divider{
  height: 0px;
  width: 100%;
  border-bottom:1px solid #E7ECF0;
  margin-bottom: 20px;
}
.v-application .treefont15{
  font-size: 15px;
  vertical-align: -1px;
}
.dialog-bottom{
  position: absolute;
  left: 0px;
  bottom: -40px;
  width: 100%;
  padding-top: 20px;
}
</style>

<style lang="scss">
.range-left .vxe-table--render-default  .is--checked.vxe-cell--checkbox  .vxe-checkbox--icon:before, .range-left .vxe-table--render-default .is--indeterminate.vxe-cell--checkbox .vxe-checkbox--icon:before {
    border-color: #409eff;
    background-color: #409eff;
}

</style>
