<template>
  <v-navigation-drawer
    :value="isAddSidebarActive"
    temporary
    touchless
    :right="!$vuetify.rtl"
    :width="$vuetify.breakpoint.smAndUp ? 450 : '100%'"
    app
    @input="(val) => $emit('update:is-edit-sidebar-active', val)"
  >
    <v-card height="100%">
      <div class="drawer-header d-flex align-center mb-4">
        <span class="font-weight-semibold text-base text--primary">新增菜单</span>
        <v-spacer></v-spacer>
        <v-btn
          icon
          small
          @click="$emit('update:is-edit-sidebar-active',false)"
        >
          <v-icon size="22">
            mdi-close
          </v-icon>
        </v-btn>
      </div>

      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
          @submit.prevent="onSubmit"
        >
          <v-menu
            bottom
            max-height="500"
          >
            <template #activator="{ on, attrs }">
              <v-text-field
                :value="getParentName(menuData.parentId)"
                :rules="[validators.required]"
                readonly
                outlined
                dense
                clearable
                label="请选择上级菜单"
                placeholder="请选择上级菜单"
                hide-details="auto"
                class="mb-6"
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>

            <v-sheet>
              <v-treeview
                :items="menuOptions"
                :active.sync="active"
                :open.sync="open"
                hoverable
                activatable
              ></v-treeview>
            </v-sheet>
          </v-menu>

          <v-text-field
            v-model="menuData.name"
            :rules="[validators.required]"
            outlined
            dense
            clearable
            label="请输入菜单标题"
            placeholder="请输入菜单标题"
            hide-details="auto"
            class="mb-6"
          ></v-text-field>

          <v-autocomplete
            v-model="menuData.icon"
            :items="iconOptions"
            no-data-text="暂无数据"
            outlined
            dense
            clearable
            hide-details="auto"
            item-text="title"
            item-value="channelId"
            class="mb-6"
            label="请选择图标"
            placeholder="请选择图标"
            @input="val => consoleTest(menuData, val)"
          >
            <template #selection="{item}">
              <v-icon>
                {{ resolveIconName(item || 'mdiCircleSmall') }}
              </v-icon>
              {{ item }}
            </template>

            <template #item="{item}">
              <v-icon>
                {{ resolveIconName(item) }}
              </v-icon>
              {{ item }}
            </template>
          </v-autocomplete>

          <v-text-field
            v-model="menuData.component"
            outlined
            dense
            clearable
            label="请输入前端路径"
            placeholder="请输入前端路径"
            hide-details="auto"
            class="mb-6"
          ></v-text-field>

          <v-text-field
            v-model="menuData.path"
            outlined
            dense
            clearable
            label="请输入后端路径"
            placeholder="请输入后端路径"
            hide-details="auto"
            class="mb-6"
          ></v-text-field>

          <v-text-field
            v-model="menuData.route"
            outlined
            dense
            clearable
            label="请输入路由"
            placeholder="请输入路由"
            hide-details="auto"
            class="mb-6"
          ></v-text-field>

          <v-select
            v-model="menuData.groupName"
            :items="groupOptions"
            :rules="[validators.required]"
            item-text="title"
            item-value="value"
            outlined
            dense
            label="请选择一个分组"
            placeholder="请选择一个分组"
            hide-details="auto"
            class="mb-6"
          ></v-select>

          <v-text-field
            v-model="menuData.sort"
            :rules="[validators.required]"
            type="number"
            outlined
            dense
            label="请输入排序"
            placeholder="请输入排序"
            hide-details="auto"
            class="mb-6"
          ></v-text-field>

          <v-textarea
            v-model="menuData.meta"
            :rules="[validators.required]"
            outlined
            dense
            clearable
            label="请输入元信息"
            placeholder="请输入元信息"
            hide-details="auto"
            class="mb-2"
          ></v-textarea>

          <div class="d-flex justify-space-around mb-6">
            <v-switch
              v-model="menuData.isView"
              :rules="[validators.required]"
              label="是否纯页面"
              color="primary"
              :true-value="1"
              :false-value="0"
              inset
              hide-details
            ></v-switch>

            <v-switch
              v-model="menuData.isHidden"
              :rules="[validators.required]"
              label="是否隐藏"
              color="success"
              :true-value="1"
              :false-value="0"
              inset
              hide-details
            ></v-switch>

            <v-switch
              v-model="menuData.isBreadcrumb"
              label="是否导航"
              color="info"
              :true-value="1"
              :false-value="0"
              inset
              hide-details
            ></v-switch>
          </div>

          <div class="d-flex">
            <v-btn
              color="primary"
              class="me-3"
              type="submit"
            >
              新增
            </v-btn>
            <v-btn
              color="secondary"
              outlined
              type="reset"
              @click="resetForm"
            >
              取消
            </v-btn>
          </div>
        </v-form>
      </v-card-text>
    </v-card>
  </v-navigation-drawer>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import store from '@/store'
import { emailValidator, required } from '@core/utils/validation'
import { modalEnquire, modalFail, toastSuccess } from '@core/utils/prompt'
import { getIcons, resolveIconName } from '@core/utils/icon'
import menuStoreModule from './menuStoreModule'

export default {
  name: 'MenuEdit',
  model: {
    prop: 'isAddSidebarActive',
    event: 'update:is-edit-sidebar-active',
  },
  props: {
    isAddSidebarActive: {
      type: Boolean,
      required: true,
    },
    menuOptions: {
      type: Array,
      required: true,
    },
    flattedMenu: {
      type: Array,
      required: true,
    },
  },

  setup(props, { emit }) {
    const POINT_LIST_STORE_MODULE_NAME = 'menu-list'

    // Register module
    if (!store.hasModule(POINT_LIST_STORE_MODULE_NAME)) store.registerModule(POINT_LIST_STORE_MODULE_NAME, menuStoreModule)

    const blankData = {
      id: '',
      groupName: '',
      icon: '',
      component: '',
      path: '',
      route: '',
      isBreadcrumb: 1,
      isHidden: 0,
      isView: 0,
      meta: '{"layout": "content"}',
      name: '',
      parentId: '',
      sort: 1,
    }
    const valid = ref(false)
    const form = ref(null)
    const active = ref([])
    const open = ref([])
    const iconOptions = getIcons()

    const groupOptions = [
      { title: 'system', value: 'system' },
      { title: 'application', value: 'application' },
    ]

    const validate = () => {
      form.value.validate()
    }

    const menuData = ref(JSON.parse(JSON.stringify(blankData)))
    const resetForm = () => {
      menuData.value = JSON.parse(JSON.stringify(blankData))
      form.value.reset()
      emit('update:is-edit-sidebar-active', false)
    }

    const getParentName = parentId => {
      const parentItem = props.flattedMenu.find(item => item.id === parentId)

      return parentItem?.name
    }

    const onSubmit = () => {
      if (valid.value) {
        modalEnquire('点击确认后会新增对应的菜单').then(answer => {
          if (answer.isConfirmed === true) {
            store.dispatch('menu-list/addItem', menuData.value).then(() => {
              emit('refetch-data')
              emit('update:is-edit-sidebar-active', false)
              toastSuccess('成功新增菜单')
            }).catch(error => {
              modalFail(error.response.data.message)
            })
          }
        })
      } else {
        validate()
      }
    }

    function consoleTest(...message) {
      console.log(...message)
    }

    watch(active, () => {
      if (active.value.length) {
        // eslint-disable-next-line prefer-destructuring
        menuData.value.parentId = active.value[0]
      }
    })

    return {
      menuData,
      form,
      valid,
      open,
      active,
      iconOptions,
      groupOptions,
      validate,
      resetForm,
      getParentName,
      consoleTest,
      onSubmit,
      resolveIconName,

      // validation
      validators: { required, emailValidator },
    }
  },
}
</script>
