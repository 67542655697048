<template>
  <v-card>
    <v-card-title>
      <span class="headline">{{ query.id?'编辑角色':'新增角色' }}</span>
      <v-spacer></v-spacer>
      <v-btn
        class="mt-n1"
        fab
        dark
        small
        color="primary"
        :to="{name:'roleManagement-list'}"
      >
        <v-icon>mdi-arrow-u-left-top</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-form
        ref="form"
        v-model="valid"
        @submit.prevent="editPartTime"
      >
        <v-row>
          <v-col>
            <v-text-field
              v-model="submitData.name"
              outlined
              dense
              clearable
              label="角色名"
              placeholder="请输入角色姓名"
              hide-details="auto"
              class="mb-6"
              :rules="[required]"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-select
              v-model="submitData.appId"
              :items="sysData"
              label="选择系统"
              placeholder="请选择系统"
              item-text="name"
              item-value="appId"
              dense
              outlined
              hide-details
              :disabled="!!query.id"
              class="mb-6"
            ></v-select>
          </v-col>
        </v-row>
      </v-form>
      <div class="ml-6 mt" v-if="menuData.length===0">暂无菜单</div>
      <v-list v-if="menuData.length>0" dense>
        <v-list-item
          v-for="item in menuData"
          :key="item.id"
          dense
          style="height: 40px"
        >
          <div
            v-if="item.level !== 0"
            class="vxe-tree--line"
            :class="`spearadun-line-left-${item.level} spearadun-line-height-${item.sort}`"
          />

          <v-checkbox
            v-if="item.type === 'nav' || item.type === 'page'"
            v-model="checkedData"
            :value="item.id"
            :label="item.name"
            color="primary"
            :class="`spearadun-level-${item.level}`"
            @change="(val) => checkHandle(val, item)"
          />
          <div
            v-if="item.children.length>0"
            class="spearadun-space-x"
            style="margin-left: 52px"
          >
            <v-checkbox
              v-for="children in item.children"
              :key="children.id"
              v-model="checkedData"
              :label="children.name"
              :value="children.id"
              @change="(val) => checkHandle(val, children)"
            />
            <!--            <v-divider-->
            <!--              class="divider"-->
            <!--            />-->
          </div>
        </v-list-item>
      </v-list>
      <div class="d-flex mt-4">
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          class="me-3"
          @click="editPartTime()"
        >
          提交
        </v-btn>
        <v-btn
          class="mx-2"
          type="reset"
          outlined
          :to="{name:'roleManagement-list'}"
        >
          取消
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>

import store from '@/store'
import {
  onUnmounted, ref, watch,
} from '@vue/composition-api/dist/vue-composition-api'
import { emailValidator, required } from '@core/utils/validation'
import { modalFail, toastSuccess } from '@core/utils/prompt'
import { useRouter } from '@core/utils'
import storeModule from './queueStoreModule'

export default {
  props: {
  },
  setup(props, { emit }) {
    const WORK_STORE_MODULE_NAME = 'roleManage-list'

    // Register module
    if (!store.hasModule(WORK_STORE_MODULE_NAME)) store.registerModule(WORK_STORE_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(WORK_STORE_MODULE_NAME)) store.unregisterModule(WORK_STORE_MODULE_NAME)
    })

    const { route } = useRouter()
    const { router } = useRouter()
    const { query } = route.value
    const form = ref(null)
    const valid = ref(false)
    const active = ref([])
    const open = ref([])
    const sysData = ref([])
    const menuData = ref([])
    const checkedData = ref([])
    const blankData = {
      appId: 'silverdawn-video-cqf29vjm',
      name: '',
      id: '',
    }

    const submitData = ref(JSON.parse(JSON.stringify(blankData)))

    const validate = () => {
      form.value.validate()
    }

    const resetData = () => {
      emit('update:is-edit-dialog-active', false)
    }

    const editPartTime = () => {
      if (valid.value) {
        if (query.id) {
          // 路由中有id则为编辑
          store.dispatch('roleManage-list/editRole', {
            id: query.id, appId: query.appId, name: submitData.value.name, menuIds: checkedData.value,
          }).then(() => {
            toastSuccess('成功编辑角色信息')
            router.push({ name: 'roleManagement-list' })
          }).catch(error => {
            modalFail(error.response.data.message)
          })
        } else { // 没有id则为新增
          store.dispatch('roleManage-list/addRole', { appId: submitData.value.appId, name: submitData.value.name, menuIds: checkedData.value }).then(() => {
            router.push({ name: 'roleManagement-list' })
            toastSuccess('成功新增角色信息')
          }).catch(error => {
            modalFail(error.response.data.message)
          })
        }
      } else {
        validate()
      }
    }

    const fetchSysLists = () => {
      submitData.value.appId = query.appId
      submitData.value.name = query.name
      store.dispatch('roleManage-list/fetchSysLists')
        .then(response => {
          sysData.value = response.data.data
        })
    }
    fetchSysLists()

    const Fn = data => {
      data.forEach(i => {
        if (i.children) {
          Fn(i.children)
        }
        if (i.visible === 1 && checkedData.value.findIndex(n => n === i.id) === -1) {
          checkedData.value.push(i.id)
        }
      })
    }
    const showDetails = () => {
      store
        .dispatch('roleManage-list/getAuthById', { roleId: query.id ? query.id : null, appId: submitData.value.appId })
        .then(response => {
          const { data } = response.data
          menuData.value = data
          Fn(menuData.value)
        })
        .catch(error => {
          console.log(error)
        })
    }

    // 是否全选
    const itemIndeterminate = id => {
      let indeterminate = false // true 包含，有子节点数据

      for (let i = 0; i < menuData.value.length; i += 1) {
        const permission = menuData.value[i]
        if (permission.parentId !== id) {
          if (permission.type !== 'childrens') {
            if (checkedData.value.includes(permission.id) || itemIndeterminate(permission.id)) {
              indeterminate = true
              break
            }
          } else if (permission.children.some(v => checkedData.value.includes(v.id))) {
            indeterminate = true
            break
          }
        }
      }

      return indeterminate
    }

    // 查找父子节点
    const findChildNode = id => {
      let nodes = []
      menuData.value.forEach(item => {
        if (item.parentId === id) {
          if (item.type !== 'childrens') {
            nodes.push(item.id)
            nodes = nodes.concat(findChildNode(item.id))
          } else {
            item.children.forEach(v => nodes.push(v.id))
          }
        }
      })

      return nodes
    }
    const findParentNode = node => {
      let nodes = []
      menuData.value.forEach(item => {
        if (node.parentId === item.id) {
          nodes.push(item.id)
          nodes = nodes.concat(findParentNode(item))
        }
      })

      return nodes
    }

    // 分别处理子节点和父节点的选中状态
    const checkHandle = (event, item) => {
      if (event.includes(item.id)) {
        checkedData.value = checkedData.value.concat(findChildNode(item.id))
        checkedData.value = checkedData.value.concat(findParentNode(item))
      } else {
        const nodes = findChildNode(item.id)
        checkedData.value = checkedData.value.filter(v => !nodes.includes(v))
        const parentNodes = findParentNode(item)
        parentNodes.forEach(v => {
          if (!itemIndeterminate(v)) checkedData.value.splice(checkedData.value.indexOf(v), 1)
        })
      }
      checkedData.value = Array.from(new Set(checkedData.value))

      // emit('roleData', props.childRef)
      emit('permissionData', checkedData.value)
    }

    watch(
      () => submitData.value.appId,
      () => {
        console.log(submitData.value.appId)
        showDetails()

        // fetchMenuList(submitData.value.appId)
      }, { immediate: true },
    )

    return {
      menuData,
      valid,
      validate,
      editPartTime,
      submitData,
      resetData,
      sysData,
      form,
      active,
      open,
      query,
      showDetails,
      checkedData,
      checkHandle,

      // validation
      required,
      emailValidator,
    }
  },
}
</script>

<style scoped>
.v-list-item {
  padding: 0;
  min-height: 20px !important;
}
.spearadun-space-x {
  display: flex;
  flex-wrap: wrap;
}
.spearadun-space-x .v-input--checkbox {
  width: 112px;
}

.divider{
}

.vxe-tree--line {
  content: "";
  position: absolute;
  bottom: 1.4em;
  width: 0.8em;
  border-width: 0 0 1px 1px;
  border-style: dotted;
  border-color: #909399;
  pointer-events: none;
}

.spearadun-level-0 { margin-left: 0; }
.spearadun-level-1 { margin-left: 24px; }
.spearadun-level-2 { margin-left: 25px; }
.spearadun-line-left-1 { height: 36px; left: 12px;}
.spearadun-line-left-2 { height: 28px; left: 36px; top: -10px }
.spearadun-line-height-0 { height: 30px; }
.spearadun-line-height-1 { height: 75px; }
.spearadun-line-height-2 { height: 108px; }
.spearadun-line-height-3 { height: 128px; }
</style>
