<template>
  <div>
    <v-card
      v-bind="$attrs"
      v-on="$listeners"
    >
      <v-card-title>
        <slot name="title">
          离职人员
        </slot>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="userName"
          placeholder="员工名"
          single-line
          dense
          outlined
          hide-details
          class="invoice-list-search me-3"
        />

        <v-text-field
          v-model="userPhone"
          placeholder="手机号"
          single-line
          dense
          outlined
          hide-details
          class="invoice-list-search me-3"
        />
      </v-card-title>
      <v-card-text class="app-card-body">
        <slot>
          <template>
            <v-data-table
              ref="refListTable"
              :headers="tableColumns"
              :items="queueList"
              :options.sync="options"
              :page.sync="currentPage"
              :items-per-page="15"
              hide-default-footer
              :loading="loading"
              no-data-text="暂无数据"
              no-results-text="未找到相关数据"
              loading-text="加载中，请稍等..."
            >
              <!-- name -->
              <template #[`item.name`]="{item}">
                <div class="d-flex align-center">
                  <span class="d-block font-weight-semibold text-truncate">{{ item.name?item.name:'未知' }}</span>
                  <small>{{ item.jobNumber }}</small>
                </div>
              </template>
            </v-data-table>
          </template>
        </slot>
        <v-row class="mt-2">
          <v-col
            lg="2"
            cols="3"
          >
            <v-select
              v-model="perPage"
              class="ml-4"
              label="每页条数:"
              :items="lengthOption"
              style="width: 100px"
            ></v-select>
          </v-col>

          <v-col
            lg="10"
            cols="9"
            class="d-flex justify-end"
          >
            <v-pagination
              v-model="currentPage"
              total-visible="6"
              :length="pageLength"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { onUnmounted } from '@vue/composition-api'
import store from '@/store'
import storeModule from './queueStoreModule'
import resignedQueueList from './resignedQueueList'

export default {
  components: {
  },
  inheritAttrs: false,
  props: {
  },
  setup() {
    const WORK_STORE_MODULE_NAME = 'resigned-list'

    // Register module
    if (!store.hasModule(WORK_STORE_MODULE_NAME)) store.registerModule(WORK_STORE_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(WORK_STORE_MODULE_NAME)) store.unregisterModule(WORK_STORE_MODULE_NAME)
    })

    const {
      refListTable,
      fetchStaff,
      queueList,
      userName,
      userPhone,
      tableColumns,
      options,
      totalLists,
      deptId,
      perPage,
      currentPage,
      pageLength,
      loading,
    } = resignedQueueList()

    return {
      fetchStaff,
      refListTable,
      queueList,
      tableColumns,
      userName,
      userPhone,
      options,
      totalLists,
      deptId,
      perPage,
      currentPage,
      pageLength,
      loading,
    }
  },
  data() {
    return {
      lengthOption: [
        { value: 5, text: 5 },
        { value: 10, text: 10 },
        { value: 15, text: 15 },
      ],
    }
  },
}
</script>

<style scoped>
.v-card__text{
  padding: 0;
}
</style>
