<template>
  <v-card>
    <v-card-title>
      <span class="headline">编辑员工详情</span>
      <v-spacer></v-spacer>
      <v-btn
        class="mt-n1"
        fab
        dark
        small
        color="primary"
        :to="{name:'businessFrame-list'}"
      >
        <v-icon>mdi-arrow-u-left-top</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-container>
        <div>
          <h3>选择系统:</h3>
          <v-row>
            <div
              v-for="n in rolesData"
              :key="n.id"
              class="pl-10 mt-1"
            >
              <v-checkbox
                v-model="choseSys"
                :label="n.name"
                :value="n.appId"
              ></v-checkbox>
            </div>
          </v-row>
        </div>
        <div>
          <h3>账号角色</h3>
          <div
            v-for="i in rolesData"
            :key="i.id"
            class="mt-1"
          >
            <div>
              <h3>
                <v-icon
                  x-large
                  color="primary"
                >
                  mdi-circle-small
                </v-icon>{{ i.name }}:
              </h3>
              <v-row>
                <div
                  v-for="n in i.appRoles"
                  :key="n.id"
                  class="pl-10 mt-1"
                >
                  <v-checkbox
                    v-model="selectedRole"
                    :label="n.name"
                    :value="n.id"
                  ></v-checkbox>
                </div>
              </v-row>
            </div>
            <v-divider class="mt-4"></v-divider>
          </div>
        </div>
        <div>
          <h3>业务架构归属</h3>
          <div
            class="mt-1"
          >
            <v-treeview
              :items="frameWork"
            >
              <template #label="{ item }">
                <v-checkbox
                  v-if="item.level!==0"
                  v-model="item.architecture"
                  :label="item.name"
                  :true-value="1"
                  :false-value="0"
                  class="ml-3"
                  @click="cancelCheck(item)"
                ></v-checkbox><span v-if="item.level===0">{{ item.name }}</span>
              </template>
              <template #append="{ item }">
                <v-row>
                  <v-checkbox
                    v-if="item.level!==0"
                    v-model="item.leader"
                    :true-value="1"
                    :false-value="0"
                    :disabled="item.architecture===0"
                    label="设为负责人"
                    @click="ifChange(item)"
                  ></v-checkbox>
                </v-row>
              </template>
            </v-treeview>
          </div>
        </div>
        <v-switch
          v-model="staffRole.independentAllocation"
          color="primary"
          :true-value="1"
          :false-value="0"
          hide-details
          class="mb-3"
        >
          <template #label>
            是否独立分配频道
            <v-tooltip
              top
            >
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  small
                  v-bind="attrs"
                  class="ml-2"
                  v-on="on"
                >
                  <v-icon color="grey lighten-1">
                    mdi-help-circle
                  </v-icon>
                </v-btn>
              </template>
              <span>该账号可在业务架构规则之外单独配置可见频道</span>
            </v-tooltip>
          </template>
        </v-switch>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <div class="d-flex mt-4 mr-4">
        <v-btn
          color="primary"
          class="me-3"
          @click="editPartTime()"
        >
          提交
        </v-btn>
        <v-btn
          class="mx-2"
          type="reset"
          outlined
          :to="{name:'businessFrame-list'}"
        >
          取消
        </v-btn>
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>

import store from '@/store'
import {
  onUnmounted, ref, watch,
} from '@vue/composition-api/dist/vue-composition-api'
import { emailValidator, required } from '@core/utils/validation'
import { modalEnquireTitle, modalFail, toastSuccess } from '@core/utils/prompt'
import { getIcons, resolveIconName } from '@core/utils/icon'
import { pathOptions } from '@core/utils/options'
import { useRouter } from '@core/utils'
import storeModule from './queueStoreModule'

export default {
  props: {
  },
  setup(props, { emit }) {
    const WORK_STORE_MODULE_NAME = 'business-list'

    // Register module
    if (!store.hasModule(WORK_STORE_MODULE_NAME)) store.registerModule(WORK_STORE_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(WORK_STORE_MODULE_NAME)) store.unregisterModule(WORK_STORE_MODULE_NAME)
    })

    const { route } = useRouter()
    const { router } = useRouter()
    const { query } = route.value
    const form = ref(null)
    const valid = ref(false)
    const rolesData = ref([])
    const frameWork = ref([])
    const staffRole = ref([])
    const choseSys = ref([])
    const selectedRole = ref([])
    const open = ref([])
    const blankData = {
      id: '',
      hashMap: [],
      userRole: [],
      independentAllocation: 0,
    }

    const submitData = ref(JSON.parse(JSON.stringify(blankData)))
    const validate = () => {
      form.value.validate()
    }

    const resetData = () => {
      emit('update:is-edit-dialog-active', false)
    }

    // 遍历树形结构，搭建出提交的数据
    const arr = []
    const Fn = data => {
      data.forEach(item => {
        if (item.children) {
          Fn(item.children)
        }
        const obj = { appDepartmentId: item.architecture === 1 ? item.id : null, leader: item.leader }
        if (obj.appDepartmentId !== null) arr.push(obj)
      })

      return arr
    }

    // 应用角色的数据结构重建
    const handlingData = () => {
      let allRoles = []
      rolesData.value.forEach(i => {
        allRoles = allRoles.concat(i.appRoles)
      })
      const arrR = []
      selectedRole.value.forEach(i => {
        arrR.push(allRoles.find(n => n.id === i))
      })
      const obj = staffRole.value
      delete obj.independentAllocation
      Object.keys(obj).forEach(key => {
        if (obj[key].length > 0) {
          obj[key].length = 0
        }
      })
      arrR.forEach(e => {
        Object.keys(obj).forEach(key => {
          if (key === e.appId && obj[key].findIndex(f => f === e.id) === -1) {
            obj[key].push(e.id)
          }
        })
      })

      return obj
    }

    const cancelCheck = item => {
      // eslint-disable-next-line no-param-reassign
      if (item.architecture === 0) item.leader = 0
    }
    const ifChange = item => {
      // eslint-disable-next-line no-param-reassign
      if (item.leader === 1 && item.dingtalkUserId) {
        modalEnquireTitle(`「${item.name}」架构下已有负责人，确认是否替换？`).then(isConfirm => {
          if (!isConfirm.value) {
            // eslint-disable-next-line no-param-reassign
            item.leader = 0
          }
        })
      }
    }

    const editPartTime = () => {
      submitData.value.id = query.id
      submitData.value.hashMap = handlingData()
      submitData.value.userRole = Fn(frameWork.value)
      store.dispatch('business-list/updateStaff', submitData.value).then(response => {
        if (response.data.status === 200) {
          toastSuccess('成功更改员工信息')
          router.push({ name: 'businessFrame-list' })
        }
      }).catch(error => {
        modalFail(error.response.data.message)
      })
    }

    const showDetails = () => {
      store.dispatch('business-list/fetchRolesLists')
        .then(response => {
          rolesData.value = response.data.data
        })
      store.dispatch('business-list/getFrameById', { appUserId: query.id })
        .then(response => {
          frameWork.value = response.data.data
        })
        .catch(error => {
          console.log(error)
        })
      store.dispatch('business-list/getRolesById', { appUserId: query.id })
        .then(response => {
          staffRole.value = response.data.data
          const obj = response.data.data
          Object.keys(obj).forEach(key => {
            if (obj[key].length > 0) {
              choseSys.value.push(key)
              selectedRole.value = selectedRole.value.concat(obj[key])
            }
          })
        })
        .catch(error => {
          console.log(error)
        })
    }
    showDetails()

    return {
      valid,
      validate,
      editPartTime,
      resetData,
      submitData,
      form,
      open,
      pathOptions,
      frameWork,
      rolesData,
      staffRole,
      choseSys,
      selectedRole,
      resolveIconName,
      cancelCheck,
      ifChange,

      // validation
      required,
      emailValidator,
    }
  },
  methods: {
  },
}
</script>

<style scoped>

</style>
