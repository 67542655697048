import store from '@/store'
import {
  mdiAccountCheckOutline,
  mdiAccountOutline,
  mdiAccountPlusOutline,
  mdiAccountRemoveOutline,
} from '@mdi/js'
import { ref, watch } from '@vue/composition-api'
import Swal from 'sweetalert2'
import { toastSuccess } from '@core/utils/prompt'

export default function useTemplatesList() {
  const tableColumns = [
    { text: 'ID', value: 'id' },
    { text: '名称', value: 'name' },
    {
      text: '操作',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]

  const options = ref({
    sortBy: ['id'],
    sortDesc: [true],
  })

  const listTable = ref([])
  const listTableCount = ref(0)
  const loading = ref(false)

  // fetch data
  const fetchItems = () => {
    store
      .dispatch('role-list/fetchItems', {
        page: options.value.page,
        pageSize: options.value.itemsPerPage,
        sortBy: options.value.sortBy[0],
        sortDesc: options.value.sortDesc[0],
      })
      .then(response => {
        const { data } = response
        listTable.value = data.data
        listTableCount.value = data.total || listTable.value.length

        // remove loading state
        loading.value = false
      })
      .catch(error => {
        console.log(error)
      })
  }

  const updateAuthCache = () => {
    store.dispatch('role-list/updateCache').then(() => {
      toastSuccess('成功更新权限缓存！')
    })
      .catch(error => {
        Swal.fire(
          '更新权限缓存失败!',
          error.response.data.message || '请联系管理员',
          'error',
        )
        if (error.response.status === 404) {
          console.log(error)
        }
      })
  }

  // 删除数据方法
  const deleteItemPrompt = deleteItem => {
    console.log(deleteItem)
    Swal.fire({
      title: `确定删除 ${deleteItem.name} 角色组？`,
      text: '删除后该角色组将被丢进宇宙空间再也找回不来了！',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '是的，我意已决!',
      cancelButtonText: '不，让我再想想',
    }).then(result => {
      if (result.isConfirmed) {
        store
          .dispatch('role-list/deleteItem', { id: deleteItem.id })
          .then(() => {
            Swal.fire(
              '成功打开宇宙空间通道!',
              '该角色组已被成功丢进宇宙空间删除.',
              'success',
            )
            fetchItems()
          })
          .catch(error => {
            Swal.fire(
              '打开宇宙空间通道失败!',
              error.response.data.message || '请联系管理员',
              'error',
            )
            if (error.response.status === 404) {
              console.log(error)
            }
          })
      }
    })
  }

  watch(options, () => {
    loading.value = true
    fetchItems()
  })

  const resolveUserTotalIcon = total => {
    if (total === 'Total Users') return { icon: mdiAccountOutline, color: 'primary' }
    if (total === 'Paid Users') return { icon: mdiAccountPlusOutline, color: 'error' }
    if (total === 'Active Users') return { icon: mdiAccountCheckOutline, color: 'success' }
    if (total === 'Pending Users') return { icon: mdiAccountRemoveOutline, color: 'warning' }

    return { icon: mdiAccountOutline, color: 'primary' }
  }

  return {
    listTable,
    tableColumns,
    listTableCount,
    loading,
    options,
    fetchItems,
    updateAuthCache,
    deleteItemPrompt,
    resolveUserTotalIcon,
  }
}
