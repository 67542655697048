<template>
  <div>
    <v-dialog
      :value="isDialogOpen"
      max-width="800px"
      scrollable
      @click:outside="$emit('update:is-dialog-open',false)"
    >
      <v-form
        ref="form"
        v-model="valid"
        @submit.prevent="onSubmit"
      >
        <v-card>
          <v-card-title class="justify-center text-h5">
            编辑权限
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text
            style="height: 400px;"
          >
            <v-treeview
              v-model="menuTreeChosen"
              selectable
              selected-color="primary"
              :items="menuTreeData"
              selection-type="leaf"
              class="authTree"
            ></v-treeview>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn
              color="error"
              text
              @click="resetForm"
            >
              关闭
            </v-btn>
            <v-btn
              color="success"
              text
              type="submit"
            >
              保存
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import {
  ref, watch, computed,
} from '@vue/composition-api'
import store from '@/store'
import { modalEnquire, modalFail, toastSuccess } from '@core/utils/prompt'

export default {
  name: 'AuthEdit',
  props: {
    isDialogOpen: {
      type: Boolean,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
  },
  setup(props, { emit }) {
    const menuTreeData = ref([])
    const menuTreeChosen = ref([])

    const valid = ref(false)
    const form = ref(null)
    const validate = () => {
      form.value.validate()
    }
    const resetForm = () => {
      form.value.reset()
      emit('update:is-dialog-open', false)
    }

    // 在treeView的leaf选择格式中加入parentNode
    // 由原有的items构造新的items并拍平，最后转为object(索引为其id)，便于查找parent节点信息
    const flattedItems = computed(() => {
      const constructNewItem = (obj, parentId) => ({
        id: obj.id,
        name: obj.name,
        parentId,
        isChildren: !obj.children,
      })

      const addItems = (arr, parentId) => {
        const items = arr.reduce((acc, cur) => {
          acc.push(constructNewItem(cur, parentId))

          // 如果有孩子节点，递归构造孩子节点数组(会形成多维数组，所以最后要拍平)
          if (cur.children) {
            acc.push(addItems(cur.children, cur.id))
          }

          return acc
        }, [])

        return items.flat()
      }

      return addItems(menuTreeData.value).reduce((acc, cur) => {
        acc[cur.id] = cur

        return acc
      }, {})
    })

    // 构造包含父节点的最终提交的数组
    const selectedProxy = computed(() => {
      const proxy = {}
      const addParents = x => {
        // 获取对应的父节点Id，如果存在，则递归添加对应父节点Id(用对象形式以避免重复，最后获取对应value的数组)
        const { parentId } = flattedItems.value[x]
        if (parentId) {
          addParents(flattedItems.value[parentId].id)
          proxy[parentId] = flattedItems.value[parentId].id
        }
      }
      menuTreeChosen.value.forEach(x => {
        addParents(x)
        proxy[x] = x
      })

      return Object.values(proxy)
    })

    // 获取已有的权限
    const fetchMenuTreeAuthData = () => {
      store
        .dispatch('role-list/fetchMenuTreeAuth', { id: props.id })
        .then(response => {
          const { data } = response.data

          // 获取被选中的Id时，要过滤掉所有父节点的节点Id，否则父节点Id下的所有孩子节点都会被选中
          menuTreeChosen.value = data.filter(value => flattedItems.value[value]?.isChildren)
        })
        .catch(error => {
          console.log(error)
        })
    }

    // 获取所有权限（树状结构）
    const fetchMenuTreeData = () => {
      store
        .dispatch('role-list/fetchMenuTree', { id: props.id })
        .then(response => {
          const { data } = response.data
          menuTreeData.value = data
          fetchMenuTreeAuthData()
        })
        .catch(error => {
          if (error.response.status === 404) {
            console.log(error)
          }
        })
    }

    // on form submit
    const onSubmit = () => {
      if (valid.value) {
        modalEnquire('请确认数据！').then(result => {
          if (result.isConfirmed) {
            store.dispatch('role-list/editMenuTreeAuth', {
              id: props.id,
              menuTreeChoseData: selectedProxy.value,
            }).then(() => {
              emit('refetch-data')
              toastSuccess('成功修改角色组权限')
              resetForm()
            }).catch(error => {
              modalFail(error.response.data.message)
            })
          }
        })
      } else {
        validate()
      }
    }

    watch(
      () => props.isDialogOpen,
      () => {
        if (props.isDialogOpen === false) return
        fetchMenuTreeData()
      },
    )

    return {
      menuTreeData,
      menuTreeChosen,
      form,
      valid,
      validate,
      resetForm,
      onSubmit,
    }
  },
}
</script>

<style>
.authTree .v-treeview-node__children .v-treeview-node__children {
  display: flex;
  flex-wrap: wrap;
}
.authTree .v-treeview-node__children .v-treeview-node__children .v-treeview-node__level {
  width: 0;
}
.authTree .v-treeview-node__children .v-treeview-node__children {
  margin-left: 48px;
}
</style>
