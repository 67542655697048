import store from '@/store'
import { ref } from '@vue/composition-api'
import { modalFail, toastSuccess } from '@core/utils/prompt'
import Swal from 'sweetalert2'

export default function useMenuList() {
  const menuList = ref([])
  const menuOptions = ref([])
  const flattedMenu = ref([])

  function genMenuOptions() {
    menuOptions.value = [{
      name: '顶级菜单',
      id: 0,
    }]
    flattedMenu.value = [{
      name: '顶级菜单',
      id: 0,
    }]
    menuList.value.forEach(item => {
      const children = []
      if (item.children) {
        item.children.forEach(childItem => {
          // 去除二层以下的所有子节点的children
          const child = {
            id: childItem.id,
            name: childItem.name,
          }
          children.push(child)
          flattedMenu.value.push(child)
        })
      }
      flattedMenu.value.push({
        id: item.id,
        name: item.name,
      })
      menuOptions.value.push({
        children,
        id: item.id,
        name: item.name,
      })
    })
  }
  const fetchMenuList = () => {
    store
      .dispatch('menu-list/fetchLists')
      .then(response => {
        const { data } = response.data
        menuList.value = data
        genMenuOptions()
      })
      .catch(error => {
        console.log(error)
      })
  }

  const updateStatus = item => {
    store.dispatch('menu-list/hide', item)
      .then(() => {
        toastSuccess('修改成功')
        // eslint-disable-next-line no-param-reassign
        item.isHidden = !item.isHidden
      })
      .catch(error => {
        console.log(error)
      })
  }

  // 删除菜单方法
  const deletePrompt = deleteItem => {
    Swal.fire({
      title: `确定删除 ${deleteItem.name} 菜单？`,
      text: '删除后该菜单将被丢进宇宙空间再也找回不来了！',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '是的，我意已决!',
      cancelButtonText: '不，让我再想想',
    }).then(result => {
      if (result.isConfirmed) {
        store
          .dispatch('menu-list/delete', { id: deleteItem.id })
          .then(() => {
            Swal.fire(
              '成功打开宇宙空间通道!',
              '该菜单已被成功丢进宇宙空间删除.',
              'success',
            )
            fetchMenuList()
          })
          .catch(error => {
            modalFail(error.response.data.message)
            if (error.response.status === 404) {
              console.log(error)
            }
          })
      }
    })
  }

  return {
    menuList,
    menuOptions,
    flattedMenu,
    fetchMenuList,
    updateStatus,
    deletePrompt,
  }
}
