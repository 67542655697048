import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMenu,{attrs:{"close-on-content-click":false,"min-width":"auto","nudge-top":"-8","offset-y":"","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(!_vm.isMultiple)?_c(VTextField,_vm._g(_vm._b({style:(_vm.isRange ? { flexShrink: 0 } : {}),attrs:{"clearable":_vm.clearable,"label":_vm.label,"placeholder":_vm.placeholder,"dense":"","hide-details":"","outlined":"","readonly":""},model:{value:(_vm.text),callback:function ($$v) {_vm.text=$$v},expression:"text"}},'v-text-field',attrs,false),on)):_c(VCombobox,_vm._g(_vm._b({attrs:{"clearable":_vm.clearable,"chips":"","label":"Multiple picker in menu","multiple":"","prepend-icon":"mdi-calendar","readonly":"","small-chips":""},model:{value:(_vm.newValue),callback:function ($$v) {_vm.newValue=$$v},expression:"newValue"}},'v-combobox',attrs,false),on))]}}]),model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c(VDatePicker,_vm._b({attrs:{"multiple":_vm.multiple,"range":_vm.range,"locale":"zh-cn"},on:{"change":_vm.close},model:{value:(_vm.pickers),callback:function ($$v) {_vm.pickers=$$v},expression:"pickers"}},'v-date-picker',_vm.$attrs,false))],1)}
var staticRenderFns = []

export { render, staticRenderFns }