import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VTreeview } from 'vuetify/lib/components/VTreeview';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VDialog,{attrs:{"value":_vm.isDialogOpen,"max-width":"800px","scrollable":""},on:{"click:outside":function($event){return _vm.$emit('update:is-dialog-open',false)}}},[_c(VForm,{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(VCard,[_c(VCardTitle,{staticClass:"justify-center text-h5"},[_vm._v(" 编辑权限 ")]),_c(VDivider),_c(VCardText,{staticStyle:{"height":"400px"}},[_c(VTreeview,{staticClass:"authTree",attrs:{"selectable":"","selected-color":"primary","items":_vm.menuTreeData,"selection-type":"leaf"},model:{value:(_vm.menuTreeChosen),callback:function ($$v) {_vm.menuTreeChosen=$$v},expression:"menuTreeChosen"}})],1),_c(VDivider),_c(VCardActions,[_c(VBtn,{attrs:{"color":"error","text":""},on:{"click":_vm.resetForm}},[_vm._v(" 关闭 ")]),_c(VBtn,{attrs:{"color":"success","text":"","type":"submit"}},[_vm._v(" 保存 ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }