<template>
  <div class="container">
    <!-- card-->
    <div class="auth-wrapper auth-v1 px-2">
      <v-card
        no-body
        style="box-shadow: 0px 19px 62px 0px rgba(0,0,0,0.41);"
        class="overflow-hidden"
      >
        <v-row
          no-gutters
          class="d-flex flex-nowrap"
        >
          <v-col
            xl="6"
            class="d-none d-md-block"
            style="min-height: 610px;min-width: 670px"
          >
            <v-img
              :src="require('@/assets/images/pages/login/picture.png')"
              alt="Image"
              class="rounded-0 d-inline-flex cardLeft"
            />
          </v-col>
          <v-col
            md="5"
            cols="12"
            style="height: 610px;width: 530px"
          >
            <div style="padding: 60px 50px 30px  10px">
              <!-- Login 1 -->
              <SSOPwdLogin
                v-show="actived===1"
                :actived.sync="actived"
              />
              <!-- Login 2 -->
              <SSOLogged
                v-show="actived===2"
                :actived.sync="actived"
              />
              <!-- Login 3 -->
              <SSODingLogin
                v-show="actived===3"
                :actived.sync="actived"
              />
              <!-- Login 4 -->
              <SSOResetPwd
                v-show="actived===4"
                :actived.sync="actived"
              />
            </div>
          </v-col>
        </v-row>
      </v-card>
    </div>
  </div>
  <!-- background triangle shape  -->
<!--    <img-->
<!--      class="auth-mask-bg"-->
<!--      height="100%"-->
<!--      :src="require(`@/assets/images/pages/login/bg.gif`)"-->
<!--    >-->
</template>

<script>
import { onUnmounted, ref } from '@vue/composition-api/dist/vue-composition-api'
import axios from 'axios'
import themeConfig from '@themeConfig'
import store from '@/store'
import SSOResetPwd from './sso/SSOResetPwd.vue'
import SSOLogged from './sso/SSOLogged.vue'
import SSODingLogin from './sso/SSODingLogin.vue'
import SSOPwdLogin from './sso/SSOPwdLogin.vue'
import config from '../../config'
import storeModule from './storeModule'

export default {
  components: {
    SSOLogged,
    SSODingLogin,
    SSOPwdLogin,
    SSOResetPwd,
  },
  setup() {
    const SSO_LOGIN_MODULE_NAME = 'sso-login'

    // Register module
    if (!store.hasModule(SSO_LOGIN_MODULE_NAME)) store.registerModule(SSO_LOGIN_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SSO_LOGIN_MODULE_NAME)) store.unregisterModule(SSO_LOGIN_MODULE_NAME)
    })
    const ssoToken = localStorage.getItem('ssoToken')
    // eslint-disable-next-line no-param-reassign
    if (ssoToken) {
      axios.defaults.headers.Authorization = `${ssoToken}`
    }

    // App Name
    const actived = ref(2)

    function change() {
      switch (actived.value) {
        case 1:
          actived.value = 3
          break
        case 2:
          actived.value = 1
          break
        case 3:
          actived.value = 1
          break
        case 4:
          actived.value = 1
          break
        default:
          break
      }
    }

    return {
      // App Config
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,
      change,
      actived,
    }
  },
  data() {
    return {
      isLogin: false,
    }
  },
  mounted() {
    // 没有code执行校验
    if (!this.$route.query.code) {
      this.getRedirectUrl()
    }
  },
  methods: {
    getRedirectUrl() {
      axios.get(`${config.serviceUrl}/sso/redirectUrl?redirect=${this.$route.query.redirect ? this.$route.query.redirect : ''}`)
        .then(response => {
          if (response.data.code === 200) {
            this.isLogin = true
            store.commit('sso-login/getSuccess', '恭喜您，登录成功！')
            this.actived = 2
            if (this.$route.query.redirect) {
              window.location.href = response.data.data
            }
          } else {
            localStorage.removeItem('ssoToken')
            this.actived = 1
          }
        }).catch(() => {
          this.actived = 1
        })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/pages/auth.scss';
.container{
  background-image: url("~@/assets/images/pages/login/bg.png");
  background-position: center center;
  /* 背景图不平铺 */
  background-repeat: no-repeat;
  /* 当内容高度大于图片高度时，背景图像的位置相对于viewport固定 */
  background-attachment: fixed;
  /* 让背景图基于容器大小伸缩 */
  background-size: cover;
  max-width: 100%;
}
.brand-text{
  font-size: 24px;
  font-family: Microsoft YaHei,sans-serif;
  font-weight: bold;
  background-image: linear-gradient(-90deg, #7367F0 0%, #75BCFF 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.brand-logo{
  position: absolute;
  left: 39px;
  top: 44px;
  display: flex;
  height: 48px;
  width: 203px;
}
.v-card__title{
  position: absolute;
  width: 408px;
  height: 144px;
  top: 100px;
  left: 83px;
  font-size: 60px;
  color: white;
  line-height: 100px;
  font-weight: 500;
}
.cardLeft{
  position: absolute;
  top: 80px;
  left: 101px;
}
.v-card__text{
 padding: 20px;
}
</style>
