<script>
import { computed, ref, watch } from '@vue/composition-api'
import { isNull } from 'xe-utils'

export default {
  name: 'DatePickers',
  model: {
    event: 'input',
    prop: 'value',
  },
  props: {
    multiple: Boolean,
    value: {
      type: [String, Array],
      default: null,
    },
    range: Boolean,
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    rangeSeparator: {
      type: String,
      default: '-',
    },
    clearable: Boolean,
  },
  setup(props, { emit }) {
    const newValue = computed({
      get() {
        return props.value
      },
      set() {},
    })
    const show = ref(false)
    const pickers = ref()
    const isRange = computed(() => props.range)
    const isMultiple = computed(() => props.multiple)
    const text = computed({
      get() {
        return props.multiple || props.range ? props.value.join(`   ${props.rangeSeparator}   `) : props.value
      },
      set(val) {
        if (isNull(val) && (isMultiple.value || isRange.value)) return emit('input', [])
        if (isNull(val)) return emit('input', undefined)
      },
    })
    if ((isRange.value || isMultiple.value) && !Array.isArray(props.value)) throw new Error('期望是Array类型')
    if (!isRange.value && !isMultiple.value && Array.isArray(props.value)) throw new Error('期望是String类型')

    function close(val) {
      if (props.range) val.sort((a, b) => new Date(a) - new Date(b))
      emit('input', val)
      show.value = false
    }

    // if (props.value) {
    //   pickers.value = props.value
    // }
    watch(() => props.value, val => { pickers.value = val })

    return {
      show,
      isMultiple,
      text,
      isRange,
      newValue,
      pickers,
      close,
    }
  },
}
</script>

<template>
  <v-menu
    v-model="show"
    :close-on-content-click="false"
    min-width="auto"
    nudge-top="-8"
    offset-y
    transition="scale-transition"
  >
    <template #activator="{ on, attrs }">
      <v-text-field
        v-if="!isMultiple"
        v-model="text"
        :clearable="clearable"
        :label="label"
        :placeholder="placeholder"
        :style="isRange ? { flexShrink: 0 } : {}"
        dense
        hide-details
        outlined
        readonly
        v-bind="attrs"
        v-on="on"
      />
      <v-combobox
        v-else
        v-model="newValue"
        :clearable="clearable"
        chips
        label="Multiple picker in menu"
        multiple
        prepend-icon="mdi-calendar"
        readonly
        small-chips
        v-bind="attrs"
        v-on="on"
      />
    </template>
    <v-date-picker
      v-model="pickers"
      :multiple="multiple"
      :range="range"
      locale="zh-cn"
      v-bind="$attrs"
      @change="close"
    />
  </v-menu>
</template>

<style lang='scss' scoped>

</style>
