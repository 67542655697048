import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Modal',{attrs:{"id":_vm.itemId,"item":_vm.itemValue,"is-details-modal-active":_vm.isDetailsModalActive},on:{"update:isDetailsModalActive":function($event){_vm.isDetailsModalActive=$event},"update:is-details-modal-active":function($event){_vm.isDetailsModalActive=$event}}}),_c(VCard,_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(!_vm.isRemoved),expression:"!isRemoved"}]},'v-card',_vm.$attrs,false),_vm.$listeners),[_c(VCardTitle,[_vm._t("title",function(){return [_vm._v(" 消息列表 ")]}),_c(VSpacer),_c(VTextField,{staticClass:"invoice-list-search me-3",attrs:{"single-line":"","dense":"","outlined":"","hide-details":"","placeholder":"搜索消息标签"},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}})],2),_c(VCardText,{staticClass:"app-card-body"},[_vm._t("default",function(){return [[_c(VDataTable,{attrs:{"headers":_vm.tableColumns,"items":_vm.queueList,"options":_vm.options,"server-items-length":_vm.totalLists,"items-per-page":10,"loading":_vm.loading,"no-data-text":"暂无数据","no-results-text":"未找到相关数据","loading-text":"加载中，请稍等..."},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c(VChip,{staticClass:"v-chip-light-bg info--text",attrs:{"small":"","color":"info"},on:{"click":function($event){return _vm.showDetails(item)}}},[_vm._v(" 详情 ")])]}}],null,true)})]]})],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }