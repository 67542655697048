<script>
import mapEnum from '@/views/workFlow/mapEnum'

export default {
  name: 'StatusIcon',
  props: {
    active: {
      type: Number,
      default: 0,
    },
  },

  setup(props) {
    const colorMap = {
      1: '#FFA80F',
      2: '#F24545',
      3: '#F24545',
      4: '#00B09B',
    }
    function filterMap(key, val) {
      const arr = mapEnum[key] || []
      const data = arr.find(item => item.value === val)

      return (data && data.text) || ''
    }

    return {
      filterMap,
      colorMap,
    }
  },
}
</script>

<template>
  <div class="status-icon">
    <div :style="{ backgroundColor: colorMap[active] }" class="status-icon-icon" />
    <div>
      {{ filterMap('businessStatus', active) }}
    </div>
  </div>
</template>

<style lang='scss' scoped>
.status-icon {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;

  &-icon {
    width: 6px;
    height: 6px;
    border-radius: 50%;
  }
}
</style>
