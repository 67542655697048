<template>
  <v-dialog
    :value="isShowUserDetailsModalActive"
    width="550px"
    @input="(val) => $emit('update:is-show-user-details-modal-active', val)"
  >
    <v-card>
      <v-card-title>
        用户详情
      </v-card-title>
      <v-form style="width: 500px">
        <v-row cols="12" class="ml-2">
          <v-col cols="3">
            员工名：
          </v-col>
          <v-col cols="9">
            {{ itemDetails.name?itemDetails.name:'' }}
          </v-col>
        </v-row>
        <v-row
          cols="12"
          class="mt-2 ml-2"
        >
          <v-col cols="3">
            性别：
          </v-col>
          <v-col
            cols="9"
          >
            {{ resolveSexText(itemDetails.sex) }}
          </v-col>
        </v-row>
        <v-row
          cols="12"
          class="mt-2 ml-2"
        >
          <v-col cols="3">
            ID：
          </v-col>
          <v-col
            cols="9"
          >
            {{ itemDetails.id?itemDetails.id:'空' }}
          </v-col>
        </v-row>
        <v-row
          cols="12"
          class="mt-2 ml-2"
        >
          <v-col cols="3">
            手机号：
          </v-col>
          <v-col
            cols="9"
          >
            {{ itemDetails.mobile?itemDetails.mobile:'空' }}
          </v-col>
        </v-row>
        <v-row
          cols="12"
          class="mt-2 ml-2"
        >
          <v-col cols="3">
            职位：
          </v-col>
          <v-col cols="9">
            {{ itemDetails.title?itemDetails.title:'空' }}
          </v-col>
        </v-row>
        <v-row
          cols="12"
          class="mt-2 ml-2"
        >
          <v-col cols="3">
            邮箱：
          </v-col>
          <v-col cols="9">
            {{ itemDetails.email?itemDetails.email:'空' }}
          </v-col>
        </v-row>
        <v-row
          cols="12"
          class="mt-2 ml-2"
        >
          <v-col cols="3">
            账号状态：
          </v-col>
          <v-col cols="9">
            {{ itemDetails.active=== 1?'激活':'未激活' }}
          </v-col>
        </v-row>
        <v-row
          cols="12"
          class="mt-2 ml-2"
        >
          <v-col cols="3">
            公司名：
          </v-col>
          <v-col cols="9">
            {{ comName }}
          </v-col>
        </v-row>
        <v-row
          cols="12"
          class="mt-2 ml-2"
        >
          <v-col cols="3">
            是否主管：
          </v-col>
          <v-col cols="9">
            {{ itemDetails.leader=== 1?'是':'否' }}
          </v-col>
        </v-row>
        <v-row
          cols="12"
          class="mt-2 ml-2"
        >
          <v-col cols="3">
            是否管理员：
          </v-col>
          <v-col cols="9">
            {{ itemDetails.admin=== 1?'是':'否' }}
          </v-col>
        </v-row>
        <v-row
          cols="12"
          class="mt-2 ml-2"
        >
          <v-col cols="3">
            入职时间：
          </v-col>
          <v-col cols="9">
            {{ itemDetails.joinTime?itemDetails.joinTime:'空' }}
          </v-col>
        </v-row>
        <v-row
          cols="12"
          class="mt-2 ml-2"
        >
          <v-col cols="3">
            创建时间：
          </v-col>
          <v-col cols="9">
            {{ itemDetails.createdAt?itemDetails.createdAt:'空' }}
          </v-col>
        </v-row>
        <v-row
          cols="12"
          class="mt-2 ml-2"
        >
          <v-col cols="3">
            更新时间：
          </v-col>
          <v-col cols="9">
            {{ itemDetails.updatedAt?itemDetails.updatedAt:'空' }}
          </v-col>
        </v-row>
      </v-form>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          @click="$emit('update:is-show-user-details-modal-active',false)"
        >
          关闭
        </v-btn>
      </v-card-actions>
</v-card>
  </v-dialog>
</template>

<script>
import { onUnmounted, ref, watch } from '@vue/composition-api'
import store from '@/store'
import storeModule from './storeModule'
import useList from './userList'

export default {
  components: {
  },
  model: {
    prop: 'isShowUserDetailsModalActive',
    event: 'update:is-show-user-details-modal-active',
  },
  props: {
    isShowUserDetailsModalActive: {
      type: Boolean,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const APP_STORE_MODULE_NAME = 'app-user'

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
    })

    const itemDetails = ref({})
    const comName = ref('')

    const { resolveStatusText, resolveSexText } = useList()

    // 获取员工详情
    const showDetails = () => {
      store.dispatch('app-user/fetchDetailsById', props.item.id)
        .then(response => {
          itemDetails.value = response.data.data.userDetail
          comName.value = response.data.data.comName
        })
    }

    // 关闭员工详情模态框
    watch(
      () => props.isShowUserDetailsModalActive,
      () => {
        if (props.isShowUserDetailsModalActive === true) {
          showDetails()
        }
      },
    )

    return {
      resolveStatusText,
      resolveSexText,
      itemDetails,
      showDetails,
      comName,
    }
  },
  data() {
    return {
    }
  },
}
</script>

<style scoped>
[dir] .modal-body{
   padding: 0;
}
</style>
