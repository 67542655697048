<template>
  <div>
    <Modal
      :id="itemId"
      :item="itemValue"
      :is-details-modal-active.sync="isDetailsModalActive"
    ></Modal>
    <add-dialog
      :is-add-sidebar-active.sync="isAddSidebarActive"
      @refetch-data="fetchStaff"
    ></add-dialog>
    <edit-dialog
      :item="itemValue"
      :is-edit-dialog-active.sync="isEditDialogActive"
      @refetch-data="fetchStaff"
    ></edit-dialog>
    <v-card
      v-bind="$attrs"
      v-on="$listeners"
    >
      <v-card-title>
        <slot name="title">
          兼职人员
        </slot>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="userName"
          placeholder="员工名"
          single-line
          dense
          outlined
          hide-details
          class="invoice-list-search me-3"
        />

        <v-text-field
          v-model="userPhone"
          placeholder="手机号"
          single-line
          dense
          outlined
          hide-details
          class="invoice-list-search me-3"
        />
        <v-btn
          value="left"
          color="success"
          outlined
          @click="add()"
        >
          新增
        </v-btn>
      </v-card-title>
      <v-card-text class="app-card-body">
        <slot>
          <template>
            <v-data-table
              ref="refListTable"
              :headers="tableColumns"
              :items="queueList"
              :options.sync="options"
              :server-items-length="totalLists"
              :items-per-page="10"
              :loading="loading"
              no-data-text="暂无数据"
              no-results-text="未找到相关数据"
              loading-text="加载中，请稍等..."
            >
              <!-- action -->
              <template #[`item.actions`]="{ item }">
                <a @click="showDetails(item)">查看</a>
                <a
                  class="ml-1"
                  @click="editDetails(item)"
                >编辑</a>
              </template>

              <!-- deptIdList -->
              <template #[`item.deptIdList`]="{ item }">
                {{ resolveDeptText(item.deptIdList) }}
              </template>

              <!-- name -->
              <template #[`item.name`]="{item}">
                <div class="d-flex align-center">
                  <v-avatar
                    :color="item.avatar ? '' : 'primary'"
                    :class="item.avatar ? '' : 'v-avatar-light-bg primary--text'"
                    size="32"
                  >
                    <v-img
                      v-if="item.avatar"
                      :src="item.avatar"
                    ></v-img>
                    <span v-else>{{ item.name?item.name.slice(1,3).toUpperCase():'' }}</span>
                  </v-avatar>
                  <div class="d-flex flex-column ms-3">
                    <span class="d-block font-weight-semibold text--primary text-truncate">{{ item.name }}</span>
                    <small>{{ item.jobNumber }}</small>
                  </div>
                </div>
              </template>
            </v-data-table>
          </template>
        </slot>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { ref, onUnmounted } from '@vue/composition-api'
import store from '@/store'
import storeModule from './queueStoreModule'
import parttimeQueueList from './parttimeQueueList'
import Modal from './Detail.vue'
import addDialog from './addDialog.vue'
import editDialog from './editDialog.vue'

export default {
  components: {
    Modal,
    addDialog,
    editDialog,
  },
  inheritAttrs: false,
  props: {
  },
  setup() {
    const WORK_STORE_MODULE_NAME = 'parttime-list'

    // Register module
    if (!store.hasModule(WORK_STORE_MODULE_NAME)) store.registerModule(WORK_STORE_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(WORK_STORE_MODULE_NAME)) store.unregisterModule(WORK_STORE_MODULE_NAME)
    })
    const itemValue = ref({})
    const isDetailsModalActive = ref(false)
    const isAddSidebarActive = ref(false)
    const isEditDialogActive = ref(false)
    const itemId = ref(0)

    const {
      refListTable,
      fetchStaff,
      queueList,
      userName,
      userPhone,
      tableColumns,
      options,
      totalLists,
      deptId,
      loading,

      // UI
      resolveStatusVariant,
      resolveStatusText,
      resolveDeptText,
    } = parttimeQueueList()

    const showDetails = item => {
      itemId.value = item.id
      itemValue.value = item
      isDetailsModalActive.value = !isDetailsModalActive.value
    }
    const editDetails = item => {
      itemValue.value = item
      isEditDialogActive.value = !isEditDialogActive.value
    }

    const add = () => {
      isAddSidebarActive.value = !isAddSidebarActive.value
    }

    return {
      fetchStaff,
      refListTable,
      queueList,
      tableColumns,
      userName,
      userPhone,
      options,
      totalLists,
      deptId,
      loading,
      showDetails,
      editDetails,
      add,
      itemId,
      itemValue,
      isDetailsModalActive,
      isAddSidebarActive,
      isEditDialogActive,

      // searchQ,

      resolveStatusVariant,
      resolveStatusText,
      resolveDeptText,
    }
  },
}
</script>

<style scoped>
.v-card__text{
  padding: 0;
}
</style>
