import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VForm } from 'vuetify/lib/components/VForm';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"cardCont",attrs:{"no-body":"","flat":true}},[_c(VCardTitle,{staticClass:"brand-text text-primary"},[_vm._v(" 欢迎登录综合管理平台 ")]),_c(VCardText,[_c(VForm,{ref:"form",staticClass:"auth-login-form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(VTextField,{staticClass:"mt-10 mb-6",attrs:{"placeholder":"请输入您的用户名","prepend-icon":"mdi-account-outline","rules":[_vm.required],"dense":"","outlined":"","hide-details":""},model:{value:(_vm.account),callback:function ($$v) {_vm.account=$$v},expression:"account"}}),_c('div',{staticClass:"d-flex justify-content-between"},[_c(VTextField,{staticClass:"mt-6 mb-4",attrs:{"placeholder":"请输入您的密码","rules":[_vm.required],"type":_vm.isPasswordVisible ? 'text' : 'password',"prepend-icon":"mdi-lock-outline","append-icon":_vm.isPasswordVisible ? 'mdi-eye-off-outline':'mdi-eye-outline',"dense":"","outlined":"","hide-details":""},on:{"click:append":function($event){_vm.isPasswordVisible = !_vm.isPasswordVisible}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1),_c('small',{staticClass:"error--text"},[_vm._v(_vm._s(_vm.errorMsg))]),_c(VBtn,{staticClass:"mt-4 mb-1 text-white font-medium-1",attrs:{"color":"primary","block":""},on:{"click":function($event){return _vm.login()}}},[_vm._v(" 登录 ")]),_c('a',{staticClass:"d-flex justify-end",on:{"click":function($event){return _vm.change(4)}}},[_c('small',[_vm._v("忘记密码")])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }