import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"body"},[_c('div',{staticClass:"header"},[_c('div',{staticClass:"d-inline-flex ml-6 pt-6 cursor-pointer",on:{"click":_vm.comeBack}},[_c(VIcon,{staticClass:"d-inline-flex",attrs:{"color":"white","large":""}},[_vm._v(" mdi-arrow-left ")]),_c('span',{staticStyle:{"color":"white","font-size":"20px","margin-top":"2px"}},[_vm._v("返回登录页")])],1)]),_c('div',{staticClass:"pt-12 d-flex align-center justify-center flex-column"},[_c(VRow,{staticStyle:{"margin-top":"100px"}},[_c('h1',{staticClass:"ma-10"},[_vm._v(" 请选择要访问的系统 ")])]),_c(VRow,_vm._l((_vm.sysList),function(i){return _c(VCol,{key:i.id},[_c('div',{staticClass:"d-flex justify-center"},[_c(VCard,{staticClass:"sysCard d-flex align-center flex-column",on:{"click":function($event){return _vm.logSystem(i)}}},[_c(VAvatar,{staticClass:"sysImg ma-3",attrs:{"size":"200"}},[_c(VImg,{attrs:{"src":_vm.imgMap[i.appId],"cover":""}})],1),_c(VCardTitle,[_vm._v(" "+_vm._s(i.name)+" ")]),_c(VCardSubtitle,[_vm._v(" "+_vm._s(i.roleList.toString())+" ")])],1)],1)])}),1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }