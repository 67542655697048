<script>
import AppView from '@/components/AppView.vue'
import { onMounted, ref, reactive } from '@vue/composition-api'
import { workFlowLogin } from '@/views/workFlow/service'
import { getAppList, getFlowList } from '@/views/processManagement/service'
import Pagination from '@/components/Pagination.vue'
import TablePro from '@/components/TablePro.vue'

export default {
  name: 'List',
  components: { TablePro, Pagination, AppView },
  setup() {
    const tableColumns = ref([
      { text: '流程类型', value: 'processType' },
      { text: '流程名称', value: 'processName' },
      { text: '流程管理员', value: 'admin' },
      { text: '最后更新时间', value: 'lastUpdateTime' },
      { text: '操作', value: 'operation', width: 200 },
    ])
    const tableData = ref([])
    const formData = reactive({
      processStyle: '',
      processName: '',
      formId: '', // 父节点id
    })
    const pageOptions = reactive({
      page: 1,
      size: 10,
      total: 0,
    })
    const loading = ref(false)
    function init() {
      loading.value = true
      const data = {
        ...pageOptions,
        total: undefined,
        ...formData,
      }

      getFlowList(data).then(({ data, status, total }) => {
        loading.value = false
        if (status === 0) {
          tableData.value = data
          pageOptions.total = total
        }
      })
    }
    const appList = ref([])
    const formList = ref([])
    function getListApp() {
      getAppList().then(res => {
        if (res.status === 200) {
          appList.value = (res.data || []).map(item => {
            const List = (item.items || []).map(value => ({ ...value, text: `${item.name}-${value.formName}`, value: value.formName }))
            formList.value.push(...List)

            return {
              ...item,
              text: item.name,
              value: item.id,
            }
          })
        }
      })
    }
    function handleSizeChange(val) {
      pageOptions.page = 1
      formData.size = val
      init()
    }
    function handleCurrentChange(val) {
      pageOptions.page = val
      init()
    }
    function appChange(val) {
      if (val !== formData.formId) {
        formData.processName = ''
      }
      const da = appList.value.find(item => item.id === val)
      if (da) formData.processStyle = da.name
      else formData.processStyle = ''
    }

    getListApp()

    onMounted(() => {
      workFlowLogin().then(({ data }) => {
        localStorage.setItem('flowToken', data.accessToken)
        const time = setTimeout(() => {
          init()
          getListApp()
          clearTimeout(time)
        }, 1000)
      })
    })

    // 查询
    function search() {
      pageOptions.page = 1
      init()
    }

    // 重置
    function reset() {
      formData.processStyle = ''
      formData.processName = ''
      formData.formId = ''
      pageOptions.page = 1
      init()
    }

    return {
      pageOptions,
      formData,
      tableColumns,
      tableData,
      loading,
      appList,
      formList,
      handleSizeChange,
      handleCurrentChange,
      appChange,
      search,
      reset,
    }
  },
}
</script>

<template>
  <app-view>
    <template #header>
      <div class="f-r" style="gap: 12px">
        <div style="width: 230px">
          <v-select
            v-model="formData.formId"
            :items="appList"
            clearable
            dense
            hide-details
            outlined
            placeholder="流程类型"
            @change="appChange"
          />
        </div>
        <div style="width: 230px">
          <v-select
            v-model="formData.processName"
            :items="formList"
            clearable
            dense
            hide-details
            outlined
            placeholder="流程名称"
          />
        </div>
        <v-btn color="primary" @click="search">
          查询
        </v-btn>
        <v-btn color="primary" outlined @click="reset">
          重置
        </v-btn>
      </div>
    </template>
    <table-pro
      :columns="tableColumns"
      :data="tableData"
      :loading="loading"
    >
      <template #operation="{ item }">
        <span class="app-link" @click="$router.push({ path: '/processManagement/Add', query: { id: item.templateId, type: 'edit' } })">编辑</span>
        <span class="app-link" @click="$router.push({ path: '/processManagement/Add', query: { id: item.templateId, type: 'copy' } })">复制</span>
      </template>
    </table-pro>
    <template #footer>
      <Pagination
        :page-index="pageOptions.page"
        :page-size="pageOptions.size"
        :total="pageOptions.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </template>
  </app-view>
</template>

<style lang='scss' scoped>

</style>
