<template>
  <div>
    <Modal
      :id="itemId"
      :item="itemValue"
      :is-details-modal-active.sync="isDetailsModalActive"
    ></Modal>
    <v-card
      v-show="!isRemoved"
      v-bind="$attrs"
      v-on="$listeners"
    >
      <v-card-title>
        <slot name="title">
          消息列表
        </slot>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="searchQuery"
          single-line
          dense
          outlined
          hide-details
          placeholder="搜索消息标签"
          class="invoice-list-search me-3"
        ></v-text-field>
      </v-card-title>
      <v-card-text class="app-card-body">
        <slot>
          <template>
            <v-data-table
              :headers="tableColumns"
              :items="queueList"
              :options.sync="options"
              :server-items-length="totalLists"
              :items-per-page="10"
              :loading="loading"
              no-data-text="暂无数据"
              no-results-text="未找到相关数据"
              loading-text="加载中，请稍等..."
            >
              <!-- action -->
              <template #[`item.action`]="{item}">
                <v-chip
                  small
                  class="v-chip-light-bg info--text"
                  color="info"
                  @click="showDetails(item)"
                >
                  详情
                </v-chip>
              </template>
            </v-data-table>
          </template>
        </slot>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { ref, onUnmounted } from '@vue/composition-api'
import store from '@/store'
import storeModule from '@/views/application/message/queueStoreModule'
import { getIcon } from '@core/utils/icon'
import messageQueueList from '@/views/application/message/messageQueueList'
import Modal from './Detail.vue'

export default {
  components: {
    Modal,
  },
  inheritAttrs: false,
  props: {
  },
  setup() {
    const WORK_STORE_MODULE_NAME = 'message-list'

    // Register module
    if (!store.hasModule(WORK_STORE_MODULE_NAME)) store.registerModule(WORK_STORE_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(WORK_STORE_MODULE_NAME)) store.unregisterModule(WORK_STORE_MODULE_NAME)
    })
    const isDetailsModalActive = ref(false)
    const itemId = ref(0)
    const itemValue = ref({ })

    const {
      queueList,
      tableColumns,
      fetchQueueList,
      searchQuery,
      options,
      totalLists,
      loading,
    } = messageQueueList()

    const showDetails = item => {
      itemId.value = item.id
      itemValue.value = item
      isDetailsModalActive.value = !isDetailsModalActive.value
    }

    // 导入通过相关字符串获取数据的函数
    const open = ref([1, 2])
    const isRemoved = ref(false)

    return {
      queueList,
      totalLists,
      fetchQueueList,
      tableColumns,
      open,
      isRemoved,
      showDetails,
      itemId,
      itemValue,
      isDetailsModalActive,
      options,
      searchQuery,
      loading,

      // Icons
      getIcon,
    }
  },
}
</script>

<style scoped>
.v-card__text{
  padding: 0;
}
</style>
