<template>
  <v-card
    no-body
    :flat="true"
    class="cardCont"
  >
    <v-card-title class="brand-text text-primary">
      欢迎登录综合管理平台
    </v-card-title>

    <!-- form -->
    <v-card-text>
      <v-form
        ref="form"
        v-model="valid"
        class="auth-login-form"
      >
        <!-- email -->
        <v-text-field
          v-model="account"
          placeholder="请输入您的用户名"
          prepend-icon="mdi-account-outline"
          :rules="[required]"
          dense
          outlined
          hide-details
          class="mt-10 mb-6"
        />

        <!-- password -->
        <div class="d-flex justify-content-between">
          <v-text-field
            v-model="password"
            placeholder="请输入您的密码"
            :rules="[required]"
            :type="isPasswordVisible ? 'text' : 'password'"
            prepend-icon="mdi-lock-outline"
            :append-icon="isPasswordVisible ? 'mdi-eye-off-outline':'mdi-eye-outline'"
            dense
            outlined
            hide-details
            class="mt-6 mb-4"
            @click:append="isPasswordVisible = !isPasswordVisible"
          >
          </v-text-field>
        </div>
        <small class="error--text">{{ errorMsg }}</small>

        <!-- submit button -->
        <v-btn
          color="primary"
          block
          class="mt-4 mb-1 text-white font-medium-1"
          @click="login()"
        >
          登录
        </v-btn>
        <!--        @click="change(4)"-->
        <a
          class="d-flex justify-end"
          @click="change(4)"
        >
          <small>忘记密码</small>
        </a>
      </v-form>
    </v-card-text>

<!--    <div-->
<!--      class="my-7 ml-5 pr-5"-->
<!--      style="overflow: hidden"-->
<!--    >-->
<!--      <div style="overflow: hidden;width: 100%">-->
<!--        <hr-->
<!--          class="hr-solid-content"-->
<!--          data-content="钉钉快捷登录"-->
<!--        >-->
<!--      </div>-->
<!--    </div>-->

<!--    &lt;!&ndash; social button &ndash;&gt;-->
<!--    <div class="auth-footer-btn d-flex justify-center align-center mt-1">-->
<!--      <a @click="change(3)">-->
<!--        <v-img-->
<!--          class="dingImg"-->
<!--          :src="require('@/assets/images/pages/login/dingding.png')"-->
<!--        />-->
<!--      </a>-->
<!--    </div>-->
  </v-card>
</template>

<script>
import { required, passwordValidator } from '@core/utils/validation'
import useJwt from '@/auth/jwt/useJwt'
import { ref } from '@vue/composition-api'
import qs from 'qs'
import { modalFail } from '@core/utils/prompt'

export default {
  components: {
  },
  props: {
    actived: {
      type: Number,
      required: true,
    },
  },
  setup() {
    const form = ref(null)
    const valid = ref(false)
    const account = ref('')
    const password = ref('')
    const status = ref('')
    const errorMsg = ref('')
    const isPasswordVisible = ref(false)
    function change(num) {
      this.$emit('update:actived', num)
    }
    const validate = () => {
      form.value.validate()
    }

    const login = () => {
      console.log(useJwt)
      if (valid.value) {
        useJwt.ssoLogin(qs.stringify({
          name: account.value,
          pwd: password.value,
        }))
          .then(response => {
            console.log(response)
            if (response.data.code === 200) {
              localStorage.removeItem('accessToken')
              localStorage.setItem('ssoToken', response.data.data.token)
              localStorage.setItem('ssoUser', JSON.stringify(response.data.data.user))
              localStorage.setItem('ssoAPP', JSON.stringify(response.data.data.user.appList))
              window.location.href = '/choseSys'

              // window.location.reload()
            } else {
              errorMsg.value = response.data.msg
            }
          })
          .catch(error => {
            modalFail(error.response.data.message)
            const errorArr = error.response.data.errors
            for (let i = 0; i < errorArr.length; i += 1) {
              const errKey = errorArr[i].key
              const errMessage = errorArr[i].message
              const errArr = {
                [errKey]: errMessage,
              }
              this.$refs.loginForm.setErrors(errArr)
            }
          })
      } else {
        validate()
      }
    }

    return {
      form,
      valid,
      account,
      password,
      status,
      errorMsg,
      change,
      login,
      isPasswordVisible,

      required,
      passwordValidator,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
}
</script>

<style scoped>
.cardCont{
  font-family: Microsoft YaHei,sans-serif;
}
.brand-text{
  font-size: 24px;
  font-family: Microsoft YaHei,sans-serif;
  font-weight: bold;
  background-image: linear-gradient(-90deg, #7367F0 0%, #75BCFF 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.auth-login-form{
  font-size: 14px;
}
.dingImg{
  width: 42px;
  height: 42px;
}
.hr-solid-content {
  color: #a2a9b6;
  border: 0;
  font-size: 12px;
  padding: 1em 10px;
  position: relative;
}
.hr-solid-content::before {
  content: attr(data-content);
  position: absolute;
  padding: 0 1ch;
  line-height: 1px;
  border: solid #d0d0d5;
  border-width: 0 99vw;
  /* for 不支持fit-content浏览器 */
  white-space: nowrap;
  left: 50.1%;
  transform: translateX(-50%);
}
</style>
