<template>
  <v-navigation-drawer
    :value="isAddSidebarActive"
    temporary
    touchless
    :right="!$vuetify.rtl"
    :width="$vuetify.breakpoint.smAndUp ? 450 : '100%'"
    app
    @input="(val) => $emit('update:is-add-sidebar-active', val)"
  >
    <v-card>
      <div class="drawer-header d-flex align-center mb-4">
        <span class="font-weight-semibold text-base text--primary">新增菜单</span>
        <v-spacer></v-spacer>
        <v-btn
          icon
          small
          @click="$emit('update:is-add-sidebar-active',false)"
        >
          <v-icon size="22">
            mdi-close
          </v-icon>
        </v-btn>
      </div>
      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
          @submit.prevent="addMenu"
        >
          <v-autocomplete
            v-model="submitData.appId"
            :items="sysOptions"
            :rules="[required]"
            no-data-text="暂无数据"
            outlined
            dense
            clearable
            hide-details="auto"
            item-text="name"
            item-value="appId"
            class="mb-3"
            label="子系统"
            placeholder="请选择子系统"
          >
          </v-autocomplete>

          <v-menu
            bottom
            max-height="500"
          >
            <template #activator="{ on, attrs }">
              <v-text-field
                :value="getParentName(submitData.parentId)"
                :rules="[required]"
                readonly
                outlined
                dense
                clearable
                label="请选择上级菜单"
                placeholder="请选择上级菜单"
                hide-details="auto"
                class="mb-3"
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>

            <v-sheet>
              <v-treeview
                :items="menuOptions"
                :active.sync="active"
                :open.sync="open"
                hoverable
                activatable
              ></v-treeview>
            </v-sheet>
          </v-menu>

          <v-text-field
            v-model="submitData.name"
            outlined
            dense
            clearable
            label="菜单标题"
            placeholder="请输入菜单标题"
            hide-details="auto"
            class="mb-3"
            :rules="[required]"
          ></v-text-field>

          <v-text-field
            v-model="submitData.route"
            :rules="[required]"
            outlined
            dense
            clearable
            label="路由名称"
            placeholder="请输入路由名称"
            hide-details="auto"
            class="mb-3 mt-3"
          ></v-text-field>

          <div class="mb-n3">
            类型
          </div>
          <v-radio-group
            v-model="submitData.type"
            row
            hide-details
          >
            <v-radio
              label="导航"
              :value="'nav'"
            ></v-radio>
            <v-radio
              label="页面"
              :value="'page'"
            ></v-radio>
            <v-radio
              label="组件"
              :value="'component'"
            ></v-radio>
            <v-radio
              label="数据"
              :value="'data'"
            ></v-radio>
          </v-radio-group>

          <v-divider style="margin: 20px 0"></v-divider>

          <v-autocomplete
            v-if="['nav'].includes(submitData.type)"
            v-model="submitData.icon"
            :items="iconOptions"
            no-data-text="暂无数据"
            outlined
            dense
            clearable
            hide-details="auto"
            item-text="title"
            item-value="channelId"
            class="mb-3"
            label="请选择图标"
            placeholder="请选择图标"
          >
            <template #selection="{item}">
              <v-icon>
                {{ resolveIconName(item || 'mdiCircleSmall') }}
              </v-icon>
              {{ item }}
            </template>

            <template #item="{item}">
              <v-icon>
                {{ resolveIconName(item) }}
              </v-icon>
              {{ item }}
            </template>
          </v-autocomplete>

          <v-text-field
            v-model="submitData.path"
            outlined
            dense
            clearable
            placeholder="请输入路径"
            hide-details="auto"
            class="mb-3"
          >
            <template #prepend-inner>
              <v-select
                v-if="['component','data'].includes(submitData.type)"
                v-model="submitData.method"
                :items="pathOptions"
                item-value="value"
                item-text="title"
                dense
                outlined
                hide-details
                :menu-props="{ auto: true, offsetY: true }"
                class="mt-n2 ml-n3"
                style="width: 122px"
              >
              </v-select>
            </template>
          </v-text-field>

          <v-select
            v-if="['nav','page'].includes(submitData.type)"
            v-model="submitData.groupName"
            :items="groupOptions"
            :rules="[required]"
            item-text="title"
            item-value="value"
            outlined
            dense
            label="请选择一个分组"
            placeholder="请选择一个分组"
            hide-details="auto"
            class="mb-3"
          ></v-select>

          <!--          <v-text-field-->
          <!--            v-model="submitData.sort"-->
          <!--            outlined-->
          <!--            dense-->
          <!--            clearable-->
          <!--            label="排序"-->
          <!--            placeholder="请输入排序"-->
          <!--            hide-details="auto"-->
          <!--            class="mb-3"-->
          <!--          ></v-text-field>-->

          <v-textarea
            v-if="['nav','page', 'component'].includes(submitData.type)"
            v-model="submitData.meta"
            :rules="[required]"
            outlined
            dense
            clearable
            rows="4"
            label="附带信息"
            placeholder="请输入附带信息"
            hide-details="auto"
            class="mb-3"
          ></v-textarea>

          <v-switch
            v-if="['nav','page'].includes(submitData.type)"
            v-model="submitData.isHidden"
            :rules="[required]"
            label="是否隐藏"
            color="success"
            :true-value="1"
            :false-value="0"
            inset
            hide-details
            class="mb-3"
          ></v-switch>
        </v-form>
        <div class="d-flex mt-6">
          <v-btn
            color="primary"
            class="me-3"
            @click="addMenu()"
          >
            提交
          </v-btn>
          <v-btn
            class="mx-2"
            type="reset"
            outlined
            @click="resetData"
          >
            取消
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-navigation-drawer>
</template>

<script>

import store from '@/store'
import {
  onUnmounted, ref, watch, computed,
} from '@vue/composition-api/dist/vue-composition-api'
import { emailValidator, required } from '@core/utils/validation'
import { modalFail, toastSuccess } from '@core/utils/prompt'
import { pathOptions } from '@core/utils/options'
import { getIcons, resolveIconName } from '@core/utils/icon'
import storeModule from './queueStoreModule'

export default {
  model: {
    prop: 'isAddSidebarActive',
    event: 'update:is-add-sidebar-active',
  },
  props: {
    isAddSidebarActive: {
      type: Boolean,
      required: true,
    },
    menuOptions: {
      type: Array,
      required: true,
    },
    sysOptions: {
      type: Array,
      required: true,
    },
    flattedMenu: {
      type: Array,
      required: true,
    },
    appId: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const WORK_STORE_MODULE_NAME = 'permission-list'

    // Register module
    if (!store.hasModule(WORK_STORE_MODULE_NAME)) store.registerModule(WORK_STORE_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(WORK_STORE_MODULE_NAME)) store.unregisterModule(WORK_STORE_MODULE_NAME)
    })

    const groupOptions = [
      { title: 'system', value: 'system' },
      { title: 'application', value: 'application' },
    ]
    const active = ref([])
    const open = ref([])
    const iconOptions = getIcons()

    const getParentName = parentId => {
      const parentItem = props.flattedMenu.find(item => item.id === parentId)

      return parentItem?.name
    }

    const form = ref(null)
    const valid = ref(false)
    const blankData = {
      id: '',
      appId: '',
      groupName: '',
      icon: '',
      component: '',
      path: '',
      method: 'any',
      route: '',
      type: 'nav',
      isHidden: 0,
      isView: 0,
      meta: '{"layout": "content"}',
      name: '',
      parentId: '',
      sort: 1,
    }

    const submitData = ref(JSON.parse(JSON.stringify(blankData)))

    const validate = () => {
      form.value.validate()
    }
    const resetForm = () => {
      form.value.reset()
    }

    const resetData = () => {
      submitData.value = JSON.parse(JSON.stringify(blankData))
      resetForm()
      emit('update:is-add-sidebar-active', false)
    }

    const addMenu = () => {
      if (valid.value) {
        if (!['component', 'data'].includes(submitData.value.type)) {
          delete submitData.value.method
        }
        store.dispatch('permission-list/addMenu', submitData.value).then(() => {
          emit('refetch-data', props.appId)
          emit('update:is-add-sidebar-active', false)
          toastSuccess('成功新增菜单')
        }).catch(error => {
          modalFail(error.response.data.message)
        })
      } else {
        validate()
      }
    }

    watch(active, () => {
      if (active.value.length) {
        // eslint-disable-next-line prefer-destructuring
        submitData.value.parentId = active.value[0]
      }
    })

    watch(
      () => props.isAddSidebarActive,
      () => {
        if (props.isAddSidebarActive === true) {
          if (props.appId) {
            submitData.value.appId = props.appId
          }
        }
      },
    )

    return {
      valid,
      validate,
      addMenu,
      submitData,
      resetData,
      form,
      active,
      open,
      pathOptions,
      groupOptions,
      iconOptions,
      getParentName,
      resolveIconName,

      // validation
      required,
      emailValidator,
    }
  },
}
</script>

<style scoped>

</style>
