import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VPagination } from 'vuetify/lib/components/VPagination';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VCard,_vm._g(_vm._b({},'v-card',_vm.$attrs,false),_vm.$listeners),[_c(VCardTitle,[_vm._t("title",function(){return [_vm._v(" 离职人员 ")]}),_c(VSpacer),_c(VTextField,{staticClass:"invoice-list-search me-3",attrs:{"placeholder":"员工名","single-line":"","dense":"","outlined":"","hide-details":""},model:{value:(_vm.userName),callback:function ($$v) {_vm.userName=$$v},expression:"userName"}}),_c(VTextField,{staticClass:"invoice-list-search me-3",attrs:{"placeholder":"手机号","single-line":"","dense":"","outlined":"","hide-details":""},model:{value:(_vm.userPhone),callback:function ($$v) {_vm.userPhone=$$v},expression:"userPhone"}})],2),_c(VCardText,{staticClass:"app-card-body"},[_vm._t("default",function(){return [[_c(VDataTable,{ref:"refListTable",attrs:{"headers":_vm.tableColumns,"items":_vm.queueList,"options":_vm.options,"page":_vm.currentPage,"items-per-page":15,"hide-default-footer":"","loading":_vm.loading,"no-data-text":"暂无数据","no-results-text":"未找到相关数据","loading-text":"加载中，请稍等..."},on:{"update:options":function($event){_vm.options=$event},"update:page":function($event){_vm.currentPage=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"d-block font-weight-semibold text-truncate"},[_vm._v(_vm._s(item.name?item.name:'未知'))]),_c('small',[_vm._v(_vm._s(item.jobNumber))])])]}}],null,true)})]]}),_c(VRow,{staticClass:"mt-2"},[_c(VCol,{attrs:{"lg":"2","cols":"3"}},[_c(VSelect,{staticClass:"ml-4",staticStyle:{"width":"100px"},attrs:{"label":"每页条数:","items":_vm.lengthOption},model:{value:(_vm.perPage),callback:function ($$v) {_vm.perPage=$$v},expression:"perPage"}})],1),_c(VCol,{staticClass:"d-flex justify-end",attrs:{"lg":"10","cols":"9"}},[_c(VPagination,{attrs:{"total-visible":"6","length":_vm.pageLength},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1)],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }