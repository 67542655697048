<template>
  <div>
    <add-dialog
      :aframe-work-filter="aFrameWorkFilter"
      :frame-id="frameId"
      :is-add-sidebar-active.sync="isAddSidebarActive"
      @refetch-data="fetchMenuList"
      @refetch-level-data="fetchSysLists"
    ></add-dialog>
    <edit-dialog
      :aframe-work-filter="aFrameWorkFilter"
      :frame-value="frameValue"
      :is-edit-sidebar-active.sync="isEditSidebarActive"
      @refetch-data="fetchMenuList"
      @refetch-level-data="fetchSysLists"
    ></edit-dialog>
    <v-card
      v-bind="$attrs"
      v-on="$listeners"
    >
      <v-card-title>
        <slot name="title">
          业务架构配置
        </slot>
        <v-spacer></v-spacer>

        <v-select
          v-model="aFrameWorkFilter"
          :items="AFrameworkOptions"
          item-text="name"
          item-value="id"
          single-line
          outlined
          dense
          hide-details
          placeholder="选择一级架构"
          class="invoice-list-status me-3"
          style="width: 1px"
        ></v-select>
        <v-btn
          color="primary"
          :loading="btnLoading"
          @click="syncEmployees"
        >
          钉钉同步
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-treeview
          hoverable
          open-all
          :items="queueList"
        >
          <template #label="{ item }">
            <span>{{ item.name?item.name:item.appUser.nickname }}</span><span v-if="!!(!item.name&&item.appUser.jobName)">-{{ item.appUser.jobName }}</span>
          </template>
          <template #append="{ item }">
            <v-row>
              <v-btn
                v-if="ifShowAdd(item)"
                class="mr-2"
                text
                color="primary"
                @click="addNextMenu(item)"
              >
                新增下级
              </v-btn>
              <v-btn
                v-if="ifShowEdit(item)"
                class="mr-2"
                text
                color="primary"
                @click="editMenu(item)"
              >
                编辑
              </v-btn>
              <v-btn
                v-if="item.level>0&&item.level<=5"
                class="mr-2"
                text
                color="primary"
                @click="deleteMenu(item)"
              >
                删除
              </v-btn>
            </v-row>
          </template>
        </v-treeview>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { ref, onUnmounted, watch } from '@vue/composition-api'
import store from '@/store'
import { resolveIconName } from '@core/utils/icon'
import { modalEnquireTitle, modalFail, toastSuccess } from '@core/utils/prompt'
import { useRouter } from '@core/utils'
import storeModule from './queueStoreModule'
import addDialog from './AddFirst.vue'
import editDialog from './EditFirst.vue'
import businessFrameQueueList from './businessFrameQueueList'

export default {
  components: {
    addDialog,
    editDialog,
  },
  inheritAttrs: false,
  props: {
  },
  setup() {
    const WORK_STORE_MODULE_NAME = 'business-list'

    // Register module
    if (!store.hasModule(WORK_STORE_MODULE_NAME)) store.registerModule(WORK_STORE_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(WORK_STORE_MODULE_NAME)) store.unregisterModule(WORK_STORE_MODULE_NAME)
    })
    const itemValue = ref({})
    const isAddSidebarActive = ref(false)
    const isEditSidebarActive = ref(false)
    const btnLoading = ref(false)
    const btnDeptsLoading = ref(false)
    const itemId = ref(0)
    const activeId = ref(null)
    const appId = ref('')
    const frameId = ref(0)
    const frameValue = ref({})
    const { router } = useRouter()
    const userData = JSON.parse(localStorage.getItem('userData'))

    const {
      refListTable,
      fetchSysLists,
      fetchMenuList,
      queueList,
      menuList,
      searchQuery,
      aFrameWorkFilter,
      AFrameworkOptions,
      tableColumns,
      options,
      totalLists,
      loading,
    } = businessFrameQueueList()

    // 判断是否展示编辑按钮
    const ifShowEdit = item => {
      if (userData.role === '超级管理员') {
        return true
      }

      if (userData.role === '子管理员') {
        return item.level !== 0
      }

      return false
    }

    // 判断是否展示新增下级按钮
    const ifShowAdd = item => {
      if (userData.role === '超级管理员' && item.level < 5) {
        return true
      }

      return userData.role === '子管理员' && item.level < 5 && item.level !== 0
    }

    const addNextMenu = item => {
      console.log(item)
      if (item.id === 1) {
        frameId.value = item.id
        isAddSidebarActive.value = !isAddSidebarActive.value
      } else {
        router.push({ name: 'businessFrame-editNextLevel', query: { item: JSON.stringify({ id: item.id, level: item.level, name: item.name }), type: 'add' } })
      }
    }

    const editMenu = item => {
      if (item.level > 5) {
        router.push({ name: 'businessFrame-editStaff', query: { id: item.appUserId, name: item.appUser.nickname, type: 'staff' } })
      } else if (item.level === 0 && userData.role === '超级管理员') {
        frameValue.value = item
        isEditSidebarActive.value = !isEditSidebarActive.value
      } else {
        router.push({
          name: 'businessFrame-editNextLevel',
          query: {
            id: item.id, item: JSON.stringify({ id: item.id, level: item.level, name: item.name }), name: item.name, type: 'edit',
          },
        })
      }
    }

    const syncEmployees = () => {
      btnLoading.value = true
      store.dispatch('business-list/syncEmployees')
        .then(response => {
          if (response.data.status === 200) {
            toastSuccess('同步用户成功！')
            btnLoading.value = false
          }
        }).catch(error => {
          btnLoading.value = false
          modalFail(error.response.data.message)
        })
    }

    const syncDepts = () => {
      btnDeptsLoading.value = true
      store.dispatch('business-list/syncDepts')
        .then(response => {
          if (response.data.status === 200) {
            toastSuccess('同步部门成功！')
            btnDeptsLoading.value = false
          }
        }).catch(error => {
          btnDeptsLoading.value = false
          modalFail(error.response.data.message)
        })
    }

    const deleteMenu = item => {
      modalEnquireTitle('架构删除后无法恢复，确定要删除吗？').then(isConfirm => {
        if (isConfirm.value) {
          store.dispatch('business-list/deleteDetails', item.id)
            .then(response => {
              console.log(response.data)
              if (response.data.status === 200) {
                toastSuccess('删除成功！')
                if (item.level === 1) {
                  fetchSysLists()
                }
                fetchMenuList(aFrameWorkFilter.value)
              }
            }).catch(error => {
              modalFail(error.response.data.message)
            })
        }
      })
    }

    watch(() => aFrameWorkFilter.value,
      () => {
        fetchMenuList(aFrameWorkFilter.value)
      })

    return {
      fetchMenuList,
      refListTable,
      menuList,
      queueList,
      aFrameWorkFilter,
      AFrameworkOptions,
      tableColumns,
      options,
      totalLists,
      searchQuery,
      loading,
      activeId,
      userData,
      editMenu,
      deleteMenu,
      addNextMenu,
      ifShowEdit,
      ifShowAdd,
      syncDepts,
      syncEmployees,
      fetchSysLists,
      appId,
      frameId,
      frameValue,
      itemId,
      itemValue,
      btnLoading,
      btnDeptsLoading,
      isAddSidebarActive,
      isEditSidebarActive,

      // searchQ,
      resolveIconName,
    }
  },
}
</script>

<style scoped>
.containerRig{
  padding: 0 50px 0 110px;
}
</style>
