import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('add-dialog',{attrs:{"is-add-sidebar-active":_vm.isAddSidebarActive},on:{"update:isAddSidebarActive":function($event){_vm.isAddSidebarActive=$event},"update:is-add-sidebar-active":function($event){_vm.isAddSidebarActive=$event},"refetch-data":_vm.fetchStaff}}),_c('edit-dialog',{attrs:{"item":_vm.itemValue,"is-edit-dialog-active":_vm.isEditDialogActive},on:{"update:isEditDialogActive":function($event){_vm.isEditDialogActive=$event},"update:is-edit-dialog-active":function($event){_vm.isEditDialogActive=$event},"refetch-data":_vm.fetchStaff}}),_c(VCard,_vm._g(_vm._b({},'v-card',_vm.$attrs,false),_vm.$listeners),[_c(VCardTitle,[_vm._t("title",function(){return [_vm._v(" 管理员账号配置 ")]}),_c(VSpacer),_c(VBtn,{attrs:{"value":"left","color":"success","outlined":""},on:{"click":function($event){return _vm.add()}}},[_vm._v(" 新增管理员账号 ")])],2),_c(VCardText,{staticClass:"app-card-body"},[_vm._t("default",function(){return [[_c(VDataTable,{ref:"refListTable",attrs:{"headers":_vm.tableColumns,"items":_vm.queueList,"options":_vm.options,"server-items-length":_vm.totalLists,"items-per-page":10,"loading":_vm.loading,"no-data-text":"暂无数据","no-results-text":"未找到相关数据","loading-text":"加载中，请稍等..."},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c(VBtn,{staticClass:"ml-1",attrs:{"text":"","small":"","color":"primary"},on:{"click":function($event){return _vm.editDetails(item)}}},[_vm._v(" 编辑 ")])]}}],null,true)})]]})],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }