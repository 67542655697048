<template>
  <v-card
    :flat="true"
    class="cardCont"
  >
    <h2 class="brand-text text-primary mb-5">
      欢迎登录用户认证中心
    </h2>

    <div class="text-center d-flex justify-content-center">
      <v-img
        class="denglu"
        :src="state === 1 ? require('@/assets/images/pages/login/denglu_jpg.png') : require('@/assets/images/pages/login/lost.png')"
      />
    </div>
    <h2 class="text-error">
      {{ errorMsg }}
    </h2>

    <v-btn
      color="primary"
      type="submit"
      block
      class="mb-1 text-white font-medium-1"
      @click="change()"
    >
      重新登录
    </v-btn>
  </v-card>
</template>

<script>
import store from '@/store'
import { ref, watch, computed } from '@vue/composition-api/dist/vue-composition-api'
import router from '@/router'

export default {
  components: {
  },
  props: {
    actived: {
      type: Number,
      required: true,
    },
  },
  setup() {
    // const msg = ref('')
    // const reset = ref('')
    const errorMsg = ref('登录中，请稍等！')
    const state = ref(1)

    const computedErrMsg = computed({
      get: () => store.state['sso-login'].errMsg,
    })
    const computedResetMsg = computed({
      get: () => store.state['sso-login'].resetMsg,
    })
    const computedSuccessMsg = computed({
      get: () => store.state['sso-login'].successMsg,
    })

    // eslint-disable-next-line no-shadow
    watch([computedErrMsg, computedResetMsg, computedSuccessMsg], () => {
      if (computedErrMsg.value) {
        state.value = 0
        errorMsg.value = `${computedErrMsg.value},登录失败！`
      } else if (computedResetMsg.value) {
        state.value = 1
        errorMsg.value = computedResetMsg.value
      } else if (computedSuccessMsg.value) {
        state.value = 1
        errorMsg.value = '恭喜您，登录成功！'
      } else {
        state.value = 1
        errorMsg.value = '恭喜您，登录成功！'
      }
    })
    function change() {
      if (this.$route.query.code) {
        const newQuery = JSON.parse(JSON.stringify(this.$route.query)) // 深拷贝
        delete newQuery.code
        this.$router.replace({ query: newQuery })

        router.push({ name: 'sso-login', query: newQuery || null })
      }
      this.$emit('update:actived', 1)
    }

    return {
      change,
      errorMsg,
      state,
    }
  },
}
</script>

<style scoped>
.cardCont{
  margin-left: 20px;
  margin-top: 20px;
  font-family: Microsoft YaHei,sans-serif;
}
.brand-text{
  font-size: 24px;
  font-family: Microsoft YaHei,sans-serif;
  font-weight: bold;
  background-image: linear-gradient(-90deg, #7367F0 0%, #75BCFF 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.text-error{
  text-align: center;
  margin-top: 10px;
  margin-bottom: 58px;
  height: 16px;
  font-size: 16px;
  font-family: Microsoft YaHei,sans-serif;
  font-weight: 400;
  font-style: italic;
  color: #FF3B3B;
  line-height: 42px;
}
.denglu{
  width: 100px;
  height: 274px;
}
</style>
