<script>
import AppView from '@/components/AppView.vue'
import TabsPro from '@/components/tabs/TabsPro.vue'
import { ref, reactive, onMounted } from '@vue/composition-api'
import TabPanePro from '@/components/tabs/TabPanePro.vue'
import DatePickers from '@/components/DatePickers.vue'
import Pagination from '@/components/Pagination.vue'
import mapEnum from '@/views/workFlow/mapEnum'
import { toastFail } from '@core/utils/prompt'
import moment from 'moment'
import FormItem from '@/views/workFlow/component/FormItem.vue'
import { workFlowLogin, getProcessName } from './service'
import ApplyList from './component/ApplyList.vue'
import ToDoList from './component/ToDoList.vue'
import ReceiveList from './component/ReceiveList.vue'
import AllApprovals from './component/AllApprovals.vue'

export default {
  name: 'WorkFlow',
  components: {
    FormItem,
    AllApprovals,
    ApplyList,
    DatePickers,
    TabPanePro,
    TabsPro,
    AppView,
    Pagination,
    ToDoList,
    ReceiveList,
  },
  setup() {
    const workList = [
      { label: '我发起的', value: 'IInitiated' },
      { label: '我审批的', value: 'IJoined' },
      { label: '我收到的', value: 'IInvolved' },
    ]
    const activeTab = ref('IInitiated')
    const formData = reactive({
      time: [
        moment().startOf('year').format('YYYY-MM-DD'),
        moment().endOf('year').format('YYYY-MM-DD'),
      ],
      name: undefined,
      userId: undefined, // 发起人账号id
      businessStatus: undefined, // 状态
      pageNo: 1, // 分页
      pageSize: 10, // 条数
      total: 0, // 总条数
    })
    const status = ref(false) // 是否获取了令牌
    const IInitiated = ref()
    const IJoined = ref()
    const IInvolved = ref()
    const all = ref()
    const initiatorList = ref([])

    function getInitiatorList() {
      getProcessName().then(({ data }) => {
        const list = data.map(item => item.items.map(value => value)).reduce((acc, cur) => acc.concat(cur), [])
        initiatorList.value = list.map(item => ({ text: item.formName, value: item.formName }))
      }).catch(error => {
        toastFail(error.response.data.message)
      })
    }

    onMounted(() => {
      // 获取令牌
      workFlowLogin().then(({ data }) => {
        localStorage.setItem('flowToken', data.accessToken)

        const time = setTimeout(() => {
          getInitiatorList()
          status.value = true
          if (sessionStorage.getItem('activeTab')) {
            activeTab.value = sessionStorage.getItem('activeTab')
          }

          // 移除记录
          sessionStorage.removeItem('activeTab')
          clearTimeout(time)
        }, 1000)
      })
    })
    function init() {
      if (activeTab.value === 'IInitiated' && IInitiated.value) IInitiated.value.initApply()
      if (activeTab.value === 'IJoined' && IJoined.value) IJoined.value.initApply()
      if (activeTab.value === 'IInvolved' && IInvolved.value) IInvolved.value.initApply()
      if (activeTab.value === 'all' && all.value) all.value.init()
    }

    function handleSizeChange(val) {
      formData.pageNo = 1
      formData.pageSize = val
      init()
    }

    function handleCurrentChange(val) {
      formData.pageNo = val

      init()
    }

    // 更新total
    function totalChange(val) {
      formData.total = val
    }
    const time = ref(null)

    // 切换tab触发方法
    function tabClick() {
      if (time.value) clearTimeout(time.value)
      time.value = setTimeout(() => {
        formData.pageNo = 1
        formData.pageSize = 10
      }, 500)
    }

    function rest() {
      formData.time = []
      formData.name = undefined
      formData.userId = undefined
      formData.businessStatus = undefined
      handleCurrentChange(1)
    }

    return {
      activeTab,
      workList,
      formData,
      mapEnum,
      rest,
      status,
      all,
      IInitiated,
      IJoined,
      IInvolved,
      initiatorList,
      handleSizeChange,
      handleCurrentChange,
      totalChange,
      tabClick,
    }
  },
}
</script>

<template>
  <app-view>
    <tabs-pro v-model="activeTab" @tabClick="tabClick">
      <tab-pane-pro v-for="item in workList" :key="item.value" :label="item.label" :name="item.value" />
    </tabs-pro>
    <div class="my12 button-body px8">
      <form-item label="起止时间">
        <DatePickers v-model="formData.time" :range="true" clearable placeholder="起止时间" range-separator="~" style="flex-shrink: 0" />
      </form-item>
      <form-item label="审批名称">
        <v-select
          v-model="formData.name"
          :items="initiatorList"
          clearable
          dense
          hide-details
          outlined
          placeholder="审批名称"
        />
      </form-item>
      <form-item label="发起人">
        <v-text-field
          v-model="formData.userId"
          clearable
          dense
          hide-details
          outlined
          placeholder="发起人"
        />
      </form-item>
      <form-item label="状态">
        <v-select
          v-model="formData.businessStatus"
          :items="mapEnum.businessStatus"
          clearable
          dense
          hide-details
          outlined
          placeholder="状态"
        />
      </form-item>
      <div>
        <v-btn class="mr8" color="primary" @click="handleCurrentChange(1)">
          查询
        </v-btn>
        <v-btn color="primary" outlined @click="rest">
          重置
        </v-btn>
      </div>
    </div>
    <!-- 我发起的 -->
    <apply-list v-if="status && activeTab === 'IInitiated'" ref="IInitiated" :form-data="formData" @totalChange="totalChange" />
    <!-- 我审批的 -->
    <to-do-list v-if="status && activeTab === 'IJoined'" ref="IJoined" :form-data="formData" @totalChange="totalChange" />
    <!-- 我收到的 -->
    <receive-list v-if="status && activeTab === 'IInvolved'" ref="IInvolved" :form-data="formData" @totalChange="totalChange" />
    <!-- 所有审批 -->
    <all-approvals v-if="status && activeTab === 'all'" ref="all" :form-data="formData" @totalChange="totalChange" />

    <v-divider class="mt0" />
    <Pagination
      v-if="status && formData.total && formData.total > formData.pageSize"
      :page-index="formData.pageNo"
      :page-size="formData.pageSize"
      :total="formData.total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </app-view>
</template>

<style lang='scss' scoped>
.button-body {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  gap: 20px;
}
.tab-pane-pro {
  display: none;
}
</style>
