import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VCard,_vm._g(_vm._b({},'v-card',_vm.$attrs,false),_vm.$listeners),[_c(VCardTitle,[_vm._t("title",function(){return [_vm._v(" 角色管理 ")]}),_c(VSpacer),_c(VBtn,{attrs:{"value":"left","color":"success","outlined":""},on:{"click":function($event){return _vm.editDetails()}}},[_vm._v(" 新增 ")])],2),_c(VCardText,{staticClass:"app-card-body"},[_vm._t("default",function(){return [[_c(VCard,{attrs:{"flat":""}},[_c(VTabs,{attrs:{"vertical":""},model:{value:(_vm.activeId),callback:function ($$v) {_vm.activeId=$$v},expression:"activeId"}},[_vm._l((_vm.sysData),function(i){return _c(VTab,{key:i.appId,attrs:{"href":("#" + (i.appId))}},[_vm._v(" "+_vm._s(i.name)+" ")])}),_vm._l((_vm.sysData),function(i){return _c(VTabItem,{key:i.id,attrs:{"value":i.appId}},[_c(VCard,{attrs:{"flat":""}},[_c(VCardText,{staticClass:"containerRig"},[_c(VDataTable,{ref:"refListTable",refInFor:true,attrs:{"headers":_vm.tableColumns,"items":_vm.queueList,"options":_vm.options,"server-items-length":_vm.totalLists,"items-per-page":10,"loading":_vm.loading,"no-data-text":"暂无数据","no-results-text":"未找到相关数据","loading-text":"加载中，请稍等..."},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c(VBtn,{staticClass:"mr-1",attrs:{"text":"","small":"","color":"primary"},on:{"click":function($event){return _vm.editDetails(item)}}},[_vm._v(" 编辑 ")]),_c(VBtn,{attrs:{"text":"","small":"","color":"primary"},on:{"click":function($event){return _vm.deleteDetails(item)}}},[_vm._v(" 删除 ")])]}}],null,true)})],1)],1)],1)})],2)],1)]]})],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }