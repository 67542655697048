import axios from '@axios'
import config from '../../../../config'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchLists(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          // .get('/system/menus/menuTree', { params: queryParams })
          .get(`${config.serviceUrl}/system/menus/menuTree`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addItem(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${config.serviceUrl}/system/menus`, {
            id: data.id,
            groupName: data.groupName,
            icon: data.icon,
            component: data.component,
            path: data.path,
            route: data.route,
            isBreadcrumb: data.isBreadcrumb,
            isHidden: data.isHidden,
            isView: data.isView,
            meta: data.meta,
            name: data.name,
            parentId: data.parentId,
            sort: data.sort,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    update(ctx, data) {
      return new Promise((resolve, reject) => {
        console.log(data)
        axios
          .put(`${config.serviceUrl}/system/menus/${data.id}`, {
            id: data.id,
            groupName: data.groupName,
            icon: data.icon,
            component: data.component,
            path: data.path,
            route: data.route,
            isBreadcrumb: data.isBreadcrumb,
            isHidden: data.isHidden,
            isView: data.isView,
            meta: data.meta,
            name: data.name,
            parentId: data.parentId,
            sort: data.sort,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    hide(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${config.serviceUrl}/system/menus/isHidden/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    delete(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`${config.serviceUrl}/system/menus/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
