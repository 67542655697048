import { ref, watch } from '@vue/composition-api'

import store from '@/store'
import { modalFail } from '@core/utils/prompt'

export default function parttimeQueueList() {
  const tableColumns = [
    { value: 'name', text: '员工名', sortable: false },
    { value: 'mobile', text: '手机号', sortable: false },
    { value: 'deptIdList', text: '部门', sortable: false },
    { value: 'email', text: '邮箱', sortable: false },
    { value: 'joinTime', text: '入职时间', sortable: false },
    { value: 'actions', text: '操作', sortable: false },
  ]
  const totalLists = ref(0)
  const userName = ref('')
  const userPhone = ref('')
  const queueList = ref([])
  const options = ref({
    sortBy: ['createdAt'],
    sortDesc: [true],
  })
  const loading = ref(false)

  const fetchStaff = () => {
    store
      .dispatch('parttime-list/fetchLists', {
        name: userName.value,
        mobile: userPhone.value,
        pageSize: options.value.itemsPerPage,
        page: options.value.page,
        // sortBy: options.value.sortBy[0],
        // sortDesc: options.value.sortDesc[0],
      })
      .then(response => {
        const { data, total } = response.data
        queueList.value = data
        totalLists.value = total
        loading.value = false
      })
      .catch(error => {
        modalFail(error.response.data.message)
      })
  }

  watch(options, () => {
    loading.value = true
    fetchStaff()
  })

  watch([userName, userPhone], () => {
    options.value.page = 1
    loading.value = true
    fetchStaff()
  })

  const resolveStatusVariant = active => {
    if (active === 1) return 'success'
    if (active === 0) return 'warning'

    return 'primary'
  }

  const resolveStatusText = active => {
    if (active === 1) return '启动'
    if (active === 0) return '禁用'

    return '默认'
  }

  const resolveDeptText = deptIdList => {
    if (deptIdList === '[11]') return '配音'
    if (deptIdList === '[12]') return '文案'
    if (deptIdList === '[13]') return '后期'

    return '空'
  }

  return {
    fetchStaff,
    tableColumns,
    totalLists,
    userName,
    userPhone,
    queueList,
    options,
    loading,

    resolveStatusVariant,
    resolveStatusText,
    resolveDeptText,
  }
}
