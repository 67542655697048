import axios from '@axios'
import config from '../../../../config'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    addMenu(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${config.serviceUrl}/app/menus/save`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editMenu(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`${config.serviceUrl}/app/menus/update`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteMenu(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`${config.serviceUrl}/app/menus/delete/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    hideMenu(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${config.serviceUrl}/app/menus/isHidden`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchSysLists() {
      return new Promise((resolve, reject) => {
        axios
          .get(`${config.serviceUrl}/app/user/roles`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getMenuDataById(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${config.serviceUrl}/app/menus/list?appId=${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
