import { ref, watch } from '@vue/composition-api'

import store from '@/store'
import { modalFail } from '@core/utils/prompt'

export default function resignedQueueList() {
  const tableColumns = [
    { value: 'name', text: '员工名', sortable: false },
    { value: 'userId', text: '用户ID', sortable: false },
    { value: 'mobile', text: '手机号', sortable: false },
    { value: 'deptName', text: '部门名', sortable: false },
    { value: 'title', text: '职位', sortable: false },
  ]
  const totalLists = ref(0)
  const currentPage = ref(1)
  const perPage = ref(10)
  const pageLength = ref(15)
  const userName = ref('')
  const userPhone = ref('')
  const queueList = ref([])
  const options = ref({
    sortBy: ['createdAt'],
    sortDesc: [true],
  })
  const loading = ref(false)

  const fetchStaff = () => {
    store
      .dispatch('resigned-list/fetchLists', {
        name: userName.value,
        mobile: userPhone.value,
        pageSize: perPage.value,
        page: currentPage.value,
        sortBy: options.value.sortBy[0],
        sortDesc: options.value.sortDesc[0],
      })
      .then(response => {
        const { data, total } = response.data
        queueList.value = data
        totalLists.value = total
        pageLength.value = Math.ceil(totalLists.value / perPage.value)
        loading.value = false
      })
      .catch(error => {
        modalFail(error.response.data.message)
      })
  }

  watch([options, perPage, currentPage], () => {
    loading.value = true
    fetchStaff()
  })
  watch([userName, userPhone], () => {
    loading.value = true
    currentPage.value = 1
    fetchStaff()
  })

  return {
    fetchStaff,
    tableColumns,
    totalLists,
    userName,
    userPhone,
    queueList,
    options,
    perPage,
    currentPage,
    pageLength,
    loading,
  }
}
