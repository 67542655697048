<script>
export default {
  name: 'FormItem',
  props: {
    label: {
      type: String,
      default: '',
    },
  },
}
</script>

<template>
  <div class="form-item">
    <div class="form-item-label">
      {{ label }}
    </div>
    <div class="w250">
      <slot />
    </div>
  </div>
</template>

<style lang='scss' scoped>
.form-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}
</style>
