<template>
  <div class="body">
    <!-- card-->
    <div class="header">
      <div
        class="d-inline-flex ml-6 pt-6 cursor-pointer"
        @click="comeBack"
      >
        <v-icon
          class="d-inline-flex"
          color="white"
          large
        >
          mdi-arrow-left
        </v-icon>
        <span style="color: white;font-size: 20px;margin-top: 2px">返回登录页</span>
      </div>
    </div>
    <div class="pt-12 d-flex align-center justify-center flex-column">
      <v-row style="margin-top: 100px">
        <h1 class="ma-10">
          请选择要访问的系统
        </h1>
      </v-row>
      <v-row>
        <v-col
          v-for="i in sysList"
          :key="i.id"
        >
          <div class="d-flex justify-center">
            <v-card
              class="sysCard d-flex align-center flex-column"
              @click="logSystem(i)"
            >
              <v-avatar
                class="sysImg ma-3"
                size="200"
              >
                <v-img
                  :src="imgMap[i.appId]"
                  cover
                ></v-img>
              </v-avatar>

              <v-card-title>
                {{ i.name }}
              </v-card-title>

              <v-card-subtitle>
                {{ i.roleList.toString() }}
              </v-card-subtitle>
            </v-card>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { onUnmounted, ref } from '@vue/composition-api/dist/vue-composition-api'
import themeConfig from '@themeConfig'
import store from '@/store'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import useJwt from '@/auth/jwt/useJwt'
import qs from 'qs'
import { modalFail } from '@core/utils/prompt'
import storeModule from './storeModule'

export default {
  components: {
  },
  setup() {
    const SSO_LOGIN_MODULE_NAME = 'sso-login'

    // Register module
    if (!store.hasModule(SSO_LOGIN_MODULE_NAME)) store.registerModule(SSO_LOGIN_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SSO_LOGIN_MODULE_NAME)) store.unregisterModule(SSO_LOGIN_MODULE_NAME)
    })

    const sysList = JSON.parse(localStorage.getItem('ssoAPP'))

    const imgMap = {
      'silverdawn-studio-d5d2n5jt': require('@/assets/images/pages/login/studio.png'),
      'silverdawn-staff-server': require('@/assets/images/pages/login/staff.png'),
      'silverdawn-operation-server': require('@/assets/images/pages/login/CMS.png'),
      'silverdawn-auth-server': require('@/assets/images/pages/login/auth.png'),
      'silverdawn-recruit-server': require('@/assets/images/pages/login/recruit.png'),
    }

    sysList.forEach(n => {
      // eslint-disable-next-line no-param-reassign
      n.roleList = []
      if (n.appUserRoles.length > 0) {
        n.appUserRoles.forEach(i => n.roleList.push(i.name))
      }
    })

    console.log(JSON.parse(localStorage.getItem('ssoAPP')))

    const logSystem = item => {
      console.log(item)
      useJwt.ssoLogSys(qs.stringify({
        id: item.id,
        redirect: item.serverUrl,
      }))
        .then(response => {
          if (response.data.code === 200) {
            window.open(response.data.data, '_blank')
          } else {
            modalFail(response.data.msg)
            this.errorMsg = response.data.msg
          }
        })
        .catch(error => {
          const errorArr = error.response.data.errors
          for (let i = 0; i < errorArr.length; i += 1) {
            const errKey = errorArr[i].key
            const errMessage = errorArr[i].message
            const errArr = {
              [errKey]: errMessage,
            }
            this.$refs.loginForm.setErrors(errArr)
          }
        })
    }

    const comeBack = () => {
      router.push({ name: 'sso-login' })
      localStorage.removeItem('ssoToken')
      localStorage.removeItem('ssoUser')
      localStorage.removeItem('ssoAPP')
    }

    return {
      imgMap,
      sysList,
      comeBack,
      logSystem,

      // App Config
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,
    }
  },
}
</script>

<style lang="scss" scoped>
.header{
  position: absolute;
  width: 100%;
  height: 80px;
  background: linear-gradient(90deg, #A7CDFE 0%, #764FFD 78%);
}
.sysCard{
  max-width: 224px;
  margin-right: 25px;
  box-shadow: 0px 0px 16px 0px #DADDE9;
  border-radius: 26px;
  cursor:pointer;
  transition: all .4s;
}
.sysCard:hover{
  transform: scale(1.2);
}
.sysImg{
  border-radius: 20px;
}
</style>
