import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('AddNew',{attrs:{"is-add-new-sidebar-active":_vm.isAddNewSidebarActive},on:{"update:isAddNewSidebarActive":function($event){_vm.isAddNewSidebarActive=$event},"update:is-add-new-sidebar-active":function($event){_vm.isAddNewSidebarActive=$event},"refetch-data":_vm.fetchItems}}),_c('edit',{attrs:{"id":_vm.itemId,"is-edit-sidebar-active":_vm.isEditSidebarActive},on:{"update:isEditSidebarActive":function($event){_vm.isEditSidebarActive=$event},"update:is-edit-sidebar-active":function($event){_vm.isEditSidebarActive=$event},"refetch-data":_vm.fetchItems}}),_c('auth-edit',{attrs:{"id":_vm.itemId,"is-dialog-open":_vm.isDialogOpen},on:{"update:isDialogOpen":function($event){_vm.isDialogOpen=$event},"update:is-dialog-open":function($event){_vm.isDialogOpen=$event}}}),_c(VCard,[_c(VCardTitle,[_vm._v(" "+_vm._s(_vm.curRouteTitle)+" "),_c(VSpacer),_c(VBtn,{staticClass:"mx-4",attrs:{"color":"primary"},on:{"click":function($event){_vm.isAddNewSidebarActive = !_vm.isAddNewSidebarActive}}},[_vm._v(" 新增角色 ")]),_c(VBtn,{attrs:{"color":"primary"},on:{"click":_vm.updateAuthCache}},[_vm._v(" 更新缓存 ")])],1),_c(VDataTable,{attrs:{"headers":_vm.tableColumns,"items":_vm.listTable,"options":_vm.options,"server-items-length":_vm.listTableCount,"loading":_vm.loading,"no-data-text":"暂无数据","no-results-text":"未找到相关数据","loading-text":"加载中，请稍等..."},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.id)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c(VBtn,{attrs:{"text":"","color":"error"},on:{"click":function($event){return _vm.clickAuthEditOpen(item.id)}}},[_vm._v(" 权限 ")]),_c(VMenu,{attrs:{"bottom":"","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v(_vm._s(_vm.icons.mdiDotsVertical))])],1)]}}],null,true)},[_c(VList,[_c(VListItem,{on:{"click":function($event){return _vm.clickEditOpen(item.id)}}},[_c(VListItemTitle,[_c(VIcon,{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiPencilOutline)+" ")]),_c('span',[_vm._v("编辑")])],1)],1),_c(VListItem,{on:{"click":function($event){return _vm.deleteItemPrompt(item)}}},[_c(VListItemTitle,[_c(VIcon,{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiDeleteOutline)+" ")]),_c('span',[_vm._v("删除")])],1)],1)],1)],1)]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }