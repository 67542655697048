<template>
  <v-card
    :flat="true"
    class="cardCont"
  >
    <v-row
      style="padding-bottom: 40px"
    >
      <div
        class="d-flex"
        style="cursor:pointer;"
        @click="change(1)"
      >
        <v-img
          class="dingImg2 d-inline-flex"
          :src="require('@/assets/images/pages/login/arrow.png')"
        />
        <span class="brand-textDing">返回密码登录</span>
      </div>
    </v-row>

    <!-- form -->
    <v-form
      class="auth-login-form"
      @submit.prevent
    >
      <!-- phone -->
      <v-text-field
        v-model="phone"
        placeholder="请输入您的手机号"
        label="手机号"
        single-line
        dense
        outlined
        hide-details
        class="mt-12 mb-6"
      />

      <!-- password -->
      <v-text-field
        v-model="code"
        placeholder="请输入您的验证码"
        label="验证码"
        single-line
        dense
        outlined
        hide-details
        class="mt-6 mb-6"
      >
        <template #append>
          <v-btn
            ref="bt"
            color="primary"
            outlined
            x-small
            :disabled="isShowTime"
            @click="getCode(phone)"
          >
            {{ isShowTime?`发送验证码(${ cd })`:'发送验证码' }}
          </v-btn>
        </template>
      </v-text-field>
      <small class="error--text">{{ errorMsg }}</small>

      <!-- submit button -->
      <v-btn
        color="primary"
        type="submit"
        block
        class="mt-12 mb-12"
        @click="submit()"
      >
        重置密码
      </v-btn>
    </v-form>

<!--    <div-->
<!--      class="my-7 ml-5 pr-5"-->
<!--      style="overflow: hidden"-->
<!--    >-->
<!--      <div style="overflow: hidden;width: 100%">-->
<!--        <hr-->
<!--          class="hr-solid-content"-->
<!--          data-content="钉钉快捷登录"-->
<!--        >-->
<!--      </div>-->
<!--    </div>-->

<!--    &lt;!&ndash; social button &ndash;&gt;-->
<!--    <div class="auth-footer-btn d-flex justify-center align-center mt-1">-->
<!--      <a @click="change(3)">-->
<!--        <v-img-->
<!--          class="dingImg"-->
<!--          :src="require('@/assets/images/pages/login/dingding.png')"-->
<!--        />-->
<!--      </a>-->
<!--    </div>-->
  </v-card>
</template>

<script>
import { ref } from '@vue/composition-api/dist/vue-composition-api'
import store from '@/store'
import axios from '@axios'
import { modalFail } from '@core/utils/prompt'
import config from '../../../config'

export default {
  components: {
  },
  props: {
    actived: {
      type: Number,
      required: true,
    },
  },
  setup() {
    const phone = ref('')
    const code = ref('')
    const errorMsg = ref('')

    // 设置一个发送验证码的冷却时间
    const cd = ref(0)

    // 用来保存当前计时器编号
    let timerId = 0

    // 发送验证码的按钮
    const bt = ref(null)
    const isShowTime = ref(false)

    // 保存css设定的颜色
    function setTime() {
      // 冷却时间未结束
      if (cd.value > 0) {
        // 冷却时间减一秒
        cd.value -= 1

        // 将按钮的文字修改（在后面附带剩余冷却时间秒数）
        isShowTime.value = true

        // bt.value.innerText = `发送验证码(${cd})`
      } else {
        // 冷却时间为0，清除当前计时器
        clearTimeout(timerId)

        // 恢复按钮显示文字
        isShowTime.value = false

        // bt.value.innerText = '发送验证码'

        // 恢复按钮背景色
        // bt.value.className = 'btn btn-outline-primary'
      }
    }
    // eslint-disable-next-line no-shadow
    function getCode(phone) {
      if (phone) {
        this.errorMsg = ''
        if (cd.value === 0) {
          // cd为0，向服务器发一送请求
          axios.post(`${config.serviceUrl}/base/sendVerifyCode?mobile=${phone}`)
            .then(response => {
              console.log(response)
              if (response.data.status === 200) {
                // 然后将cd改为60（一分钟内不能再次发送）
                cd.value = 60

                // 设置一个计时器，每1000毫秒执行一次设置冷却时间的函数
                timerId = setInterval(setTime, 1000)

                // 设置计时器后弹出一下它的编号（不必要）
                // alert(timerId)
                // 修改按钮背景色
                isShowTime.value = true

                // bt.value.className = 'btn btn-outline-secondary'
              }
            })
            .catch(error => {
              modalFail(error.response.data.message)
            })
        } else {
          this.errorMsg = `请在${cd.value}秒后重试！`
        }
      } else this.errorMsg = '请输入手机号！'
    }

    function submit() {
      axios.post(`${config.serviceUrl}/sso/resetPassword`, {
        mobile: phone.value,
        code: code.value,
      })
        .then(response => {
          if (response.data.code === 200) {
            this.$emit('update:actived', 2)
            store.commit('sso-login/getReset', '重置密码成功！')
          } else {
            this.errorMsg = response.data.msg
          }
        })
        .catch(error => {
          this.$refs.loginForm.setErrors({ phone: error.response.data.message })
        })
    }

    function change(num) {
      this.$emit('update:actived', num)
    }

    return {
      bt,
      cd,
      change,
      getCode,
      isShowTime,
      phone,
      code,
      submit,
      errorMsg,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
}
</script>

<style scoped>
.cardCont {
  margin-left: 20px;
  margin-top: 20px;
  font-family: Microsoft YaHei, sans-serif;
}

.brand-textDing {
  color: #6577FA;
  margin-left: 14px;
  margin-top: 2px;
  width: 200px;
  height: 20px;
  font-size: 20px;
  font-family: Microsoft YaHei, sans-serif;
  font-weight: 400;
}

.auth-login-form {
  font-size: 14px;
}

.hr-solid-content {
  color: #a2a9b6;
  border: 0;
  font-size: 12px;
  padding: 1em 10px;
  position: relative;
}
.hr-solid-content::before {
  content: attr(data-content);
  position: absolute;
  padding: 0 1ch;
  line-height: 1px;
  border: solid #d0d0d5;
  border-width: 0 99vw;
  /* for 不支持fit-content浏览器 */
  white-space: nowrap;
  left: 50.1%;
  transform: translateX(-50%);
}

.dingImg {
  width: 42px;
  height: 42px;
}

.dingImg2 {
  margin-left: 15px;
  margin-top: 6px;
  width: 23px;
  height: 21px;
}
</style>
