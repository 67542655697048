<template>
  <v-navigation-drawer
    :value="isEditSidebarActive"
    temporary
    touchless
    :right="!$vuetify.rtl"
    :width="$vuetify.breakpoint.smAndUp ? 350 : '100%'"
    app
    @input="(val) => $emit('update:is-edit-sidebar-active', val)"
  >
    <v-card height="100%">
      <div class="drawer-header d-flex align-center mb-4">
        <span class="font-weight-semibold text-base text--primary">编辑角色组</span>
        <v-spacer></v-spacer>
        <v-btn
          icon
          small
          @click="$emit('update:is-edit-sidebar-active',false)"
        >
          <v-icon size="22">
            {{ icons.mdiClose }}
          </v-icon>
        </v-btn>
      </div>

      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
          @submit.prevent="onSubmit"
        >
          <v-text-field
            v-model="itemDetails.name"
            :rules="[validators.required]"
            outlined
            dense
            clearable
            label="请输入名称"
            placeholder="请输入名称"
            hide-details="auto"
            class="mb-6"
          ></v-text-field>

          <!--          <v-text-field
            v-model="itemDetails.createUser"
            outlined
            dense
            label="请输入创建人"
            placeholder="请输入创建人"
            hide-details="auto"
            class="mb-5"
          ></v-text-field>-->

          <div class="d-flex">
            <v-btn
              color="primary"
              class="me-3"
              type="submit"
            >
              修改
            </v-btn>
            <v-btn
              color="secondary"
              outlined
              type="reset"
              @click="resetForm"
            >
              取消
            </v-btn>
          </div>
        </v-form>
      </v-card-text>
    </v-card>
  </v-navigation-drawer>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import store from '@/store'
import { emailValidator, required } from '@core/utils/validation'
import { mdiClose } from '@mdi/js'
import { modalFail, toastSuccess } from '@core/utils/prompt'
import roleStoreModule from './roleStoreModule'

export default {
  model: {
    prop: 'isEditSidebarActive',
    event: 'update:is-edit-sidebar-active',
  },
  props: {
    isEditSidebarActive: {
      type: Boolean,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
  },

  setup(props, { emit }) {
    const STORE_MODULE_NAME = 'role-list'

    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, roleStoreModule)

    // // UnRegister on leave
    // onUnmounted(() => {
    //   if (store.hasModule(POINT_ATTR_STORE_MODULE_NAME)) store.unregisterModule(POINT_ATTR_STORE_MODULE_NAME)
    // })

    const valid = ref(false)
    const form = ref(null)
    const validate = () => {
      form.value.validate()
    }
    const resetForm = () => {
      form.value.reset()
      emit('update:is-edit-sidebar-active', false)
    }

    // TODO:可以不需要发ajax请求获取详情（用table中的数据）
    const itemDetails = ref({})
    const fetchItemDetails = () => {
      store
        .dispatch('role-list/fetchItemDetails', { id: props.id })
        .then(response => {
          const { data } = response.data
          itemDetails.value = data
        })
        .catch(error => {
          if (error.response.status === 404) {
            console.log(error)
          }
        })
    }

    const resetItemDetails = () => {
      fetchItemDetails()
      emit('update:is-edit-sidebar-active', false)
    }

    const onSubmit = () => {
      if (valid.value) {
        store.dispatch('role-list/editItem', {
          id: props.id,
          item: itemDetails.value,
        }).then(() => {
          emit('refetch-data')
          emit('update:is-edit-sidebar-active', false)
          toastSuccess('成功修改角色组')
          resetItemDetails()
        }).catch(error => {
          modalFail(error.response.data.message)
        })
      } else {
        validate()
      }
    }

    watch(
      () => props.isEditSidebarActive,
      () => {
        if (props.isEditSidebarActive === true) {
          fetchItemDetails()
        }
      },
    )

    return {
      itemDetails,
      onSubmit,
      form,
      valid,
      validate,
      resetForm,

      // validation
      validators: { required, emailValidator },
      icons: {
        mdiClose,
      },
    }
  },
}
</script>
