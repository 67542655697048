<template>
  <div>
    <add-dialog
      :is-add-sidebar-active.sync="isAddSidebarActive"
      @refetch-data="fetchStaff"
    ></add-dialog>
    <edit-dialog
      :item="itemValue"
      :is-edit-dialog-active.sync="isEditDialogActive"
      @refetch-data="fetchStaff"
    ></edit-dialog>
    <v-card
      v-bind="$attrs"
      v-on="$listeners"
    >
      <v-card-title>
        <slot name="title">
          管理员账号配置
        </slot>
        <v-spacer></v-spacer>
        <v-btn
          value="left"
          color="success"
          outlined
          @click="add()"
        >
          新增管理员账号
        </v-btn>
      </v-card-title>
      <v-card-text class="app-card-body">
        <slot>
          <template>
            <v-data-table
              ref="refListTable"
              :headers="tableColumns"
              :items="queueList"
              :options.sync="options"
              :server-items-length="totalLists"
              :items-per-page="10"
              :loading="loading"
              no-data-text="暂无数据"
              no-results-text="未找到相关数据"
              loading-text="加载中，请稍等..."
            >
              <!-- action -->
              <template #[`item.actions`]="{ item }">
                <v-btn
                  text
                  small
                  class="ml-1"
                  color="primary"
                  @click="editDetails(item)"
                >
                  编辑
                </v-btn>
              </template>
            </v-data-table>
          </template>
        </slot>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { ref, onUnmounted } from '@vue/composition-api'
import store from '@/store'
import storeModule from './queueStoreModule'
import adminQueueList from './adminQueueList'
import addDialog from './AddDialog.vue'
import editDialog from './EditDialog.vue'

export default {
  components: {
    addDialog,
    editDialog,
  },
  inheritAttrs: false,
  props: {
  },
  setup() {
    const WORK_STORE_MODULE_NAME = 'admin-list'

    // Register module
    if (!store.hasModule(WORK_STORE_MODULE_NAME)) store.registerModule(WORK_STORE_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(WORK_STORE_MODULE_NAME)) store.unregisterModule(WORK_STORE_MODULE_NAME)
    })
    const itemValue = ref({})
    const isDetailsModalActive = ref(false)
    const isAddSidebarActive = ref(false)
    const isEditDialogActive = ref(false)
    const itemId = ref(0)

    const {
      refListTable,
      fetchStaff,
      queueList,
      userName,
      userPhone,
      tableColumns,
      options,
      totalLists,
      deptId,
      loading,

      // UI
      resolveStatusVariant,
      resolveStatusText,
      resolveDeptText,
    } = adminQueueList()

    // 编辑管理员
    const editDetails = item => {
      itemValue.value = item
      isEditDialogActive.value = !isEditDialogActive.value
    }

    // 新增管理员
    const add = () => {
      isAddSidebarActive.value = !isAddSidebarActive.value
    }

    return {
      fetchStaff,
      refListTable,
      queueList,
      tableColumns,
      userName,
      userPhone,
      options,
      totalLists,
      deptId,
      loading,
      editDetails,
      add,
      itemId,
      itemValue,
      isDetailsModalActive,
      isAddSidebarActive,
      isEditDialogActive,

      // searchQ,

      resolveStatusVariant,
      resolveStatusText,
      resolveDeptText,
    }
  },
}
</script>

<style scoped>
.v-card__text{
  padding: 0;
}
</style>
