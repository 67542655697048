<script>
import TablePro from '@/components/TablePro.vue'
import { ref, onMounted, computed } from '@vue/composition-api'
import mapEnum from '@/views/workFlow/mapEnum'
import moment from 'moment'
import { removeObjectEmpty, useRouter } from '@core/utils'
import { toastFail } from '@core/utils/prompt'
import StatusIcon from '@/views/workFlow/component/StatusIcon.vue'
import { getApprovalList } from '../service'

export default {
  name: 'ToDoList',
  components: { StatusIcon, TablePro },
  props: {
    formData: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, { emit }) {
    const tableData = ref([])
    const tableColumns = ref([
      { text: '审批名称', value: 'processDefinitionName' },
      { text: '发起时间', value: 'startTime' },
      { text: '当前审批人', value: 'currentName' },
      { text: '当前状态', value: 'businessStatus', enum: mapEnum.businessStatus },
      { text: '操作', value: 'operation', width: 200 },
    ])
    const ssoUser = localStorage.getItem('ssoUser') ? JSON.parse(localStorage.ssoUser) : {}
    const userId = computed(() => ssoUser?.userId) // 用户id
    const name = computed(() => ssoUser?.nickname) // 用户名
    const loading = ref(true) // 加载中
    const { router } = useRouter()

    function initApply() {
      const [startDate, endDate] = props.formData?.time || []
      const data = {
        ...props.formData,
        startDate: startDate ? `${startDate} 00:00:00` : '',
        endDate: endDate ? `${endDate} 23:59:59` : '',
        total: undefined,
        currentUserInfo: {
          id: userId.value,
          name: name.value,
        },
      }
      loading.value = true

      getApprovalList(removeObjectEmpty(data)).then(({ result, status }) => {
        if (status === 200) {
          tableData.value = (result.records || []).map(item => ({
            ...item,
            currentName: item?.auditUser.name,
            businessStatus: Number(item.businessStatus),
            startTime: moment(item.startTime).format('YYYY-MM-DD HH:mm:ss'), // 时间戳转化
            createUser: item?.startUser.name,
          }))
          loading.value = false

          // 更新总条数
          emit('totalChange', result.total)
        }
      }).catch(error => {
        loading.value = false
        toastFail(error.response.data.message)
      })
    }

    onMounted(() => {
      initApply()
    })

    function jumpDetails(val) {
      sessionStorage.setItem('activeTab', 'IJoined')
      router.push({
        path: '/WorkFlowDetails',
        query: {
          id: val.processInstanceId,
          taskId: val.taskId,
        },
      })
    }

    return {
      tableData,
      tableColumns,
      loading,
      initApply,
      jumpDetails,
    }
  },
}
</script>

<template>
  <table-pro
    :columns="tableColumns"
    :data="tableData"
    :loading="loading"
  >
    <template #businessStatus="{ item }">
      <StatusIcon :active="item.businessStatus" />
    </template>
    <template #operation="{ item }">
      <span class="app-link" @click="jumpDetails(item)">详情</span>
      <span class="app-link" @click="jumpDetails(item)">审核</span>
    </template>
  </table-pro>
</template>

<style lang='scss' scoped>

</style>
