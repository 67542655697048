export const pathOptions = [
  { title: 'ANY', value: 'any' },
  { title: 'GET', value: 'get' },
  { title: 'POST', value: 'post' },
  { title: 'PUT', value: 'put' },
  { title: 'PATCH', value: 'patch' },
  { title: 'DELETE', value: 'delete' },
]

export const statusOptions = [
  { title: '未开始', value: 0 },
  { title: '进行中', value: 1 },
  { title: '已完成', value: 2 },
]

export const pointTypeOptions = [
  { title: '文本输入', value: 'textField' },
  { title: '多行文本输入', value: 'textArea' },
  { title: '文本选择', value: 'select' },
  { title: '单项选择', value: 'radio' },
  { title: '多项选择', value: 'checkbox' },
  { title: '富文本编辑', value: 'richText' },
  { title: '文件上传', value: 'fileInput' },
]

export const isOvertimeOptions = [
  { title: '未超时', value: 0 },
  { title: '超时', value: 1 },
]

export const dailyDayOptions = [
  { title: '首日', value: '0' },
  { title: '次日', value: '1' },
  { title: '第三日', value: '2' },
  { title: '第四日', value: '3' },
  { title: '第五日', value: '4' },
  { title: '第六日', value: '5' },
]
